import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ModulePlan} from '../../../classes/module-plan';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {ModuleConnections} from '../../../classes/enums/module-connections.enum';
import {ModuleRotations} from '../../../classes/enums/module-rotations.enum';
import {Conveyor} from '../../../classes/conveyor';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {UserManagementService} from '../../../services/user-management.service';
import {DesignConveyor0degComponent} from '../conveyors/design-conveyor0deg/design-conveyor0deg.component';
import {DesignConveyor90degComponent} from '../conveyors/design-conveyor90deg/design-conveyor90deg.component';
import {DesignConveyor270degComponent} from '../conveyors/design-conveyor270deg/design-conveyor270deg.component';
import {ViewCode} from '../../../classes/enums/view-code.enum';
import {ConveyorLenghtType} from '../../../classes/enums/conveyor-lenght-type.enum';
import {EndPointTypes} from '../../../classes/enums/end-point-types.enum';
import {ModeSwitch} from '../../../classes/enums/mode-switch.enum';
import {NetworkDevice} from '../../../classes/messaging/network-device';
import {MatDialog} from '@angular/material/dialog';
import {ModuleDefinition} from '../../../classes/module-definition';
import {ExternalBeltPosition} from '../../../classes/enums/external-belt-position.enum';
import {MessageHandlingService} from '../../../services/v2/message-handling.service';
import {SupportModulePlan} from '../../../classes/support-module-plan';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MessageBoxComponent} from '../../dialogs/message-box/message-box.component';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {BlinkingService} from '../../../services/blinking.service';

@Component({
  selector: 'app-module-design',
  templateUrl: '../../../../themes/basic/templates/elements/module-design/module-design.component.html',
  styleUrls: ['../../../../themes/basic/templates/elements/module-design/module-design.component.css'],
  entryComponents: [DesignConveyor0degComponent, DesignConveyor90degComponent, DesignConveyor270degComponent]
})
export class ModuleDesignComponent implements OnInit, OnDestroy {

  @Output() selectedComponent = new EventEmitter<ModuleDesignComponent>();
  @Output() RecipeChanged = new EventEmitter<ModulePlan>();
  @Output() RotationChanged = new EventEmitter<ModulePlan>();
  @Output() DeleteModule = new EventEmitter<ModulePlan>();
  @Output() DeleteFollowingModules = new EventEmitter<ModuleDefinition>();
  @Output() ShowModuleOnboarding = new EventEmitter();
  @Output() ShowSupportModuleOnboarding = new EventEmitter<SupportModulePlan>();

  @Input() numericalTop = 0;
  @Input() numericalLeft = 0;

  public get correctedTop() {
    return (this.numericalTop + (this._rawHeight / 2)).toString() + 'px';
  }

  public get correctedLeft() {
    return (this.numericalLeft + (this._rawWidth / 2)).toString() + 'px';;
  }

  public get correctedTopNumber(): number {
    return (this.numericalTop + (this._rawHeight / 2));
  }

  public get correctedLeftNumber(): number {
    return (this.numericalLeft + (this._rawWidth / 2));
  }

  @ Input() top = '130px';
  @ Input() leftval = '130px';
  @ Input() width = '303px';
  @ Input() height = '122px';
  @ Input() backgroundColor = 'transparent';
  @ Input() topKP = '100px';
  @ Input() leftKP = '100px';
  @ Input() set rawWidth(value: number) {
    this._rawWidth = value;
    this._designWidth = value.toString() + 'px';

    const scf = this.rawWidth / 303;
    this.scl = 'scale(' + scf + ', ' + scf + ')';

    this.supportTop = Math.round((-1.0) * (40 * scf)).toString() + 'px';
    this.supportLeft = Math.round((-1.0) * (40 * scf)).toString() + 'px';

    this.supportHeight = Math.round((100 * scf)).toString() + 'px';
    this.supportWidth = Math.round((100 * scf)).toString() + 'px';

    this.rawSupportWidth = 100 * scf;
    this.rawSupportHeigh = 100 * scf;


  }
  get rawWidth() {
    return this._rawWidth;
  }
  @ Input() set rawHeight(value: number) {
    this._rawHeight = value;
    this._designHeight = value.toString() + 'px';
    this.Conveyorleft1 = ((4319 / ((6058 / this.rawWidth))) - 25) + 'px';
    this.Conveyorleft2 = ((5319 / ((6058 / this.rawWidth))) - 25) + 'px';
    this.Conveyorleft3 = ((2220 / ((6058 / this.rawWidth))) - 25) + 'px';
  }
  get rawHeight() {
    return this._rawHeight;
  }
  @ Input() set modulePlan(value: ModulePlan) {
    this._modulePlan = value;
    this._modulePlan.ModuleStateChanged.pipe(takeUntil(this.unsubscribe)).subscribe(this.ModuleRunningState.bind(this));

    this.moduleHandling.AddModule(this);
    this.rotation();
    if (this._modulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.center) === undefined ||
      this._modulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.center) === null) {
      if (this._modulePlan.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.center) !== null) {
        if (this._modulePlan !== null && this._modulePlan.modul !== null && this._modulePlan.modul.ConveyorBelts !== null ) {
          const convType = this._modulePlan.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.center);
          if (convType !== undefined && convType !== null) {
            if (convType.AllowedConveyorBeltTypes.length > 0) {
               let conf: Conveyor;
              this.server.GetConveyors().subscribe(convyors => {
                const longNormalBelt = convType.AllowedConveyorBeltTypes.find(ex => ex.Name.startsWith('L') && ex.Name.endsWith('0'));
                if (longNormalBelt) {
                  conf = convyors.find(ex => ex.Type === longNormalBelt?.Type);
                  if (conf !== undefined && conf !== null) {
                    const conveyor = conf.Copy();
                    conveyor.ConnectionPoint = ModuleConnections.center;
                    this.modulePlan.addConntection(conveyor, conveyor.ConnectionPoint);
                  }
                }
              });

            }
          }
        }
      }
    }

    if (this._modulePlan.customerModule !== null && this._modulePlan.customerModule !== undefined) {


      this.handler = setInterval( () => {
        this.yellowLight = !this.yellowLight;

      }, 1000);

    }
    if (this._modulePlan.customerModule && this._modulePlan.customerModule.Module && this._modulePlan.customerModule.Module.NetworkInfo) {
      this.running = this._modulePlan.customerModule.Module.NetworkInfo.statemodule;
      this.UpdateRuningState();
    } else {
      this.running = -1;
      this.UpdateRuningState();
    }
    this.SetModeSwitch();
    this.SetNetworkRevision();

    this._modulePlan.OnNetworkInfoChanged.subscribe(this.onModulePlanNetworkInfoChanged.bind(this));
    this._modulePlan.ClusterInitializedChanged.subscribe(this.onClusterInitializedUpdate.bind(this));


  }
  get modulePlan() {
    return this._modulePlan;
  }
  @Input() zindex = 10;

  @ViewChild('left', {read: ViewContainerRef, static: true}) containerLeft;
  @ViewChild('left_1', {read: ViewContainerRef, static: true}) containerLeft_1;
  @ViewChild('left_2', {read: ViewContainerRef, static: true}) containerLeft_2;
  @ViewChild('left_3', {read: ViewContainerRef, static: true}) containerLeft_3;
  @ViewChild('center', {read: ViewContainerRef, static: true}) containerCenter;
  @ViewChild('right', {read: ViewContainerRef, static: true}) containerRight;
  @ViewChild('right_1', {read: ViewContainerRef, static: true}) containerRight_1;
  @ViewChild('right_2', {read: ViewContainerRef, static: true}) containerRight_2;
  @ViewChild('right_3', {read: ViewContainerRef, static: true}) containerRight_3;

  public supportTop = '-40px';
  public supportLeft = '-40px';
  public supportHeight = '100px';
  public supportWidth = '100px';
  public rawSupportWidth = 100;
  public rawSupportHeigh = 100;


  public CurrentConnectionPoint: ModuleConnections;
  public scl = 'scale(1, 1)';
  private _msgIdRevision: string;
  defaultWidth = 303;
  defaultHeight = 122;
  public _modulePlan: ModulePlan;
  public _rawWidth = this.defaultWidth;
  public _rawHeight = this.defaultHeight;
  public _designWidth = '303px';
  public _designHeight = '122px';
  public position = 'absolute';
  public rotate = 'rotate(0deg)';
  public rotateQuest = 'rotate(0deg)';
  public rotateText = 'rotate(0deg)';
  public activeButtonSide: ModuleConnections;
  public opacity = '1';
  public ngClasses = 'sectionDesign';
  IsModuleSelected = true;
  public transform = 'scale(0.5,0.5)';
  public Conveyorleft1 = '239px';
  public Conveyorleft2 = '269px';
  public Conveyorleft3 = '139px';
  handler: any;
  RuningStateHandler: any;
  yellowLight = true;
  running = -1;
  public CurrentStateColor = 'transparent';
  private unsubscribe: Subject<void> = new Subject();
  private blinkingActive = false;

  constructor(public moduleHandling: ModuleHandlingService,
              private resolver: ComponentFactoryResolver,
              public dialog: MatDialog,
              private server: ServerCommunicationService,
              private usermanagement: UserManagementService,
              private messageHandling: MessageHandlingService,
              private translate: BluectrlTranslateService,
              private blinkingService: BlinkingService) {

    // this.messageHandling.ModuleStateChanged.pipe(takeUntil(this.unsubscribe)).subscribe(this.ModuleRunningState.bind(this));
    this.messageHandling.ClusterRevisionReceived.pipe(takeUntil(this.unsubscribe)).subscribe(this.RevisionReceived.bind(this));
    this.messageHandling.ModuleModeChanged.pipe(takeUntil(this.unsubscribe)).subscribe(this.UpdateModeSwitch.bind(this));

    this.blinkingService.BlinkOff.pipe(takeUntil(this.unsubscribe)).subscribe(this.SetBlinkingOff.bind(this));
    this.blinkingService.BlinkOn.pipe(takeUntil(this.unsubscribe)).subscribe(this.SetBlinkingOn.bind(this));
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private onModulePlanNetworkInfoChanged(data: any) {
    if (this._modulePlan.customerModule && this._modulePlan.customerModule.Module && this._modulePlan.customerModule.Module.NetworkInfo) {
      this.running = this._modulePlan.customerModule.Module.NetworkInfo.statemodule;
      this.UpdateRuningState();
    } else {
      this.running = -1;
      this.UpdateRuningState();
    }
    this.SetModeSwitch();
    this.SetNetworkRevision();

    for (const plan of this._modulePlan.connections) {
      if (plan.conveyor) {
        this.checkConveyor(plan.conveyor, plan.moduleConnection);
      }
    }
  }

  public onClusterInitializedUpdate(state: boolean) {
    for (const plan of this._modulePlan.connections) {
      if (plan.conveyor) {
        this.checkConveyor(plan.conveyor, plan.moduleConnection);
      }
    }
  }



  public ShowOnboardingView() {

    if (!this.IsModuleSelected) {
      // this.moduleHandling.SetModuleActive(this);

    } else {
      this.ShowModuleOnboarding.emit();
    }


  }

  private SetNetworkRevision() {
    if (this._modulePlan.customerModule) {
      if (this._modulePlan.modul) {
        if (this.modulePlan.modul.NetworkInfo) {
          this.modulePlan.customerModule.CurrentRevision = this.modulePlan.modul.NetworkInfo.revision;
        }
      }
    }
  }

  public ShowInfoPanel(): boolean {
    if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
      return false;
    } else {
      if (this._modulePlan.customerModule) {
        if (this._modulePlan.modul) {
          if (this._modulePlan.modul.NetworkInfo) {
            if (this._modulePlan.modul.NetworkInfo.emergencystoprequired === true) {
              return true;
            } else if (this._modulePlan.modul.NetworkInfo.levellingrequired &&
              this._modulePlan.modul.NetworkInfo.levellingrequired === true) {
              return true;
            } else if (this._modulePlan.modul.NetworkInfo?.emergencystopok === false) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  public RevisionReceived(msg: any) {
    if (this.moduleHandling.CurrentViewMode === ViewCode.live) {
      if (this._modulePlan) {
        if (this._modulePlan.customerModule) {

          if (msg.msgId === this._msgIdRevision) {
            this._msgIdRevision = null;
            this._modulePlan.customerModule.CurrentRevision = msg.revision;
            this._modulePlan.customerModule.UpdateRevision = msg.update;

          }

        }
      }
    }
  }

  public rotation() {

    this.rotate = 'rotate(' + this.modulePlan.rotation + 'deg)';
    this.rotateQuest = 'rotate(-' + this.modulePlan.rotation + 'deg)';
    const scalefact = this.rawWidth / 303;
    const scalefact2 = this.rawHeight / 122;

    if (this.modulePlan.rotation === ModuleRotations.degree_0) {


      this.transform = 'scale(' + scalefact + ',' + scalefact2 + ')';

      this.rotateText = 'rotate(0deg)';

    } else if (this.modulePlan.rotation === ModuleRotations.degree_90) {
      this.transform = 'scale(' + scalefact + ',' + scalefact2 + ')';

      this.rotateText = 'rotate(0deg)';

    } else if (this.modulePlan.rotation === ModuleRotations.degree_270) {
      this.transform = 'scale(' + scalefact + ',' + scalefact2 + ')';

      this.rotateText = 'rotate(0deg)';

    } else {

      this.transform = 'scale(' + scalefact + ',' + scalefact2 + ')';

      this.rotateText = 'rotate(180deg)';
    }

    this.RotationChanged.emit(this.modulePlan);
  }

  ngOnInit() {
    const scalefact = this.rawWidth / this.defaultWidth;
    const scalefact2 = this.rawHeight / this.defaultHeight;

    if (this.modulePlan.rotation === ModuleRotations.degree_0 || this.modulePlan.rotation === ModuleRotations.degree_90 ) {


      this.transform = 'scale(' + scalefact + ',' + scalefact2 + ')';

      this.rotateText = 'rotate(0deg)';
    } else {

      this.transform = 'scale(-' + scalefact + ',-' + scalefact2 + ') translate(-303,-122)';

      this.rotateText = 'rotate(180deg)';
    }


  }

  public conveyorSizeChange(conveyor: Conveyor) {


    if (conveyor.LenghtType === ConveyorLenghtType.long) {
      conveyor.LenghtType = ConveyorLenghtType.short;
    } else {
      conveyor.LenghtType = ConveyorLenghtType.long;
    }



    this.modulePlan.addConntection(conveyor, conveyor.ConnectionPoint);
    // this.drawElements();
    this.moduleHandling.OnConveyorBeltLengthChange.emit(conveyor);
    this.moduleHandling.ActiveProject.essentialChange = true;
    this.moduleHandling.saveTempProject(true);

  }

  private SetModeSwitch() {
    if (this._modulePlan) {
      if (this._modulePlan.customerModule) {
        if (this._modulePlan.customerModule.Module.NetworkInfo) {
          if (this._modulePlan.customerModule.Module.NetworkInfo.switchstate === 'service') {
            this._modulePlan.customerModule.Module.EnableServiceMode();
          } else {
            this._modulePlan.customerModule.Module.DisableServiceMode();
          }
        }
      }
    }
  }

  private UpdateModeSwitch(msg: any) {
    if (this._modulePlan) {
      if (this._modulePlan.customerModule) {
        if (this._modulePlan.customerModule.Module.NetworkInfo) {
          if (this._modulePlan.customerModule.SerialNumber === msg.ctxId) {
            this._modulePlan.customerModule.Module.NetworkInfo.switchstate = msg.state;
            this.SetModeSwitch();
          }
        }
      }
    }
  }

  private ModeSwitchChanged(netDevice: NetworkDevice) {
    if (this._modulePlan.customerModule) {
      if (this._modulePlan.customerModule.SerialNumber === netDevice.serialnumber) {
        if (this._modulePlan.customerModule.Module) {
          if (this._modulePlan.customerModule.Module.NetworkInfo !== null &&
            this._modulePlan.customerModule.Module.NetworkInfo !== undefined) {
            this._modulePlan.customerModule.Module.AddNetworkInfo(netDevice);
            this.SetModeSwitch();
          }
        }
      }
    }
  }

  private SetBlinkingOn() {

    if (this.running >= 0 && this.blinkingActive) {
      switch (this.running) {
        case 6:
        case 9:
        case 10:
        {
          this.CurrentStateColor = 'blue';
          break;
        }
        case 8: {
          this.CurrentStateColor = 'red';
          break;
        }
      }
    }
  }

  private SetBlinkingOff() {


    if (this.running >= 0 && this.blinkingActive) {
      switch (this.running) {
        case 8:
        case 6:
        case 9:
        case 10:{
          this.CurrentStateColor = 'transparent';
          break;
        }
      }
    }
  }

  drawElements(): void {
    if (this._modulePlan.customerModule) {
      if (this._modulePlan.modul) {
        if (this._modulePlan.modul.NetworkInfo !== null && this._modulePlan.modul.NetworkInfo !== undefined) {
          if (this.modulePlan.modul.NetworkInfo.switchstate) {
            if (this.modulePlan.modul.NetworkInfo.switchstate === 'service') {
              this.modulePlan.modul.CurrentMode = ModeSwitch.SERVICE;
            } else {
              this.modulePlan.modul.CurrentMode = ModeSwitch.AUTOMATIC;
            }
          }
        }
      }
    }
    this.activeButtonSide = null;

    if (this.modulePlan.connections.some(ex => ex.moduleConnection === ModuleConnections.left)) {
      const componentRef = this.setComponentToView270deg(this.containerLeft, DesignConveyor270degComponent, ModuleConnections.left);
      if (componentRef !== null) {
        const conveyor: Conveyor = this.modulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left).conveyor;

        if (conveyor.LenghtType === ConveyorLenghtType.short && conveyor.EndPointType === EndPointTypes.module) {
          conveyor.LenghtType = ConveyorLenghtType.long;
        }

        // componentRef.instance.left = 'calc(75% - ' +
        //  ((((<number><any>(this.height.substring(0, this.height.length - 2)) * 0.50) / 240) * (conveyor.Lenght)) / 2)
        //  + 'px)';

        componentRef.instance.left = ((5.319 * 50) / (303 / this.rawWidth) - ((45 / 2) / (303 / this.rawWidth))).toString() + 'px';
        componentRef.instance.top = 0;
        componentRef.instance.changedSize.subscribe(this.conveyorSizeChange.bind(this));
      }
    }
    if (this.modulePlan.connections.some(ex => ex.moduleConnection === ModuleConnections.left_1)) {
      const componentRef = this.setComponentToView270deg(this.containerLeft_1, DesignConveyor270degComponent, ModuleConnections.left_1);
      if (componentRef !== null) {
        const conveyor: Conveyor = this.modulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_1).conveyor;
        if (conveyor.LenghtType === ConveyorLenghtType.short && conveyor.EndPointType === EndPointTypes.module) {
          conveyor.LenghtType = ConveyorLenghtType.long;
        }
        // componentRef.instance.left = 'calc(65% - ' +
        //  ((((<number><any>(this.height.substring(0, this.height.length - 2)) * 0.50) / 240) * (conveyor.Lenght)) / 2)
        //  + 'px)';



        componentRef.instance.left = ((4.319 * 50) / (303 / this.rawWidth) - ((45 / 2) / (303 / this.rawWidth))).toString() + 'px';
        componentRef.instance.top = 0;
        componentRef.instance.changedSize.subscribe(this.conveyorSizeChange.bind(this));
      }
    }
    if (this.modulePlan.connections.some(ex => ex.moduleConnection === ModuleConnections.left_2)) {
      const componentRef = this.setComponentToView270deg(this.containerLeft_2, DesignConveyor270degComponent, ModuleConnections.left_2);
      if (componentRef !== null) {
        const conveyor: Conveyor = this.modulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_2).conveyor;
        if (conveyor.LenghtType === ConveyorLenghtType.short && conveyor.EndPointType === EndPointTypes.module) {
          conveyor.LenghtType = ConveyorLenghtType.long;
        }
        // componentRef.instance.left = 'calc(85% - ' +
        //  ((((<number><any>(this.height.substring(0, this.height.length - 2)) * 0.50) / 240) * (conveyor.Lenght)) / 2)
        //  + 'px)';
        componentRef.instance.left = ((5.319 * 50) / (303 / this.rawWidth) - ((45 / 2) / (303 / this.rawWidth))).toString() + 'px';
        componentRef.instance.top = 0;
        componentRef.instance.changedSize.subscribe(this.conveyorSizeChange.bind(this));
      }
    }
    if (this.modulePlan.connections.some(ex => ex.moduleConnection === ModuleConnections.left_3)) {
      const componentRef = this.setComponentToView270deg(this.containerLeft_3, DesignConveyor270degComponent, ModuleConnections.left_3);
      if (componentRef !== null) {
        const conveyor: Conveyor = this.modulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.left_3).conveyor;
        if (conveyor.LenghtType === ConveyorLenghtType.short && conveyor.EndPointType === EndPointTypes.module) {
          conveyor.LenghtType = ConveyorLenghtType.long;
        }
        // componentRef.instance.left = 'calc(85% - ' +
        //  ((((<number><any>(this.height.substring(0, this.height.length - 2)) * 0.50) / 240) * (conveyor.Lenght)) / 2)
        //  + 'px)';
        componentRef.instance.left = ((2.220 * 50) / (303 / this.rawWidth) - ((45 / 2) / (303 / this.rawWidth))).toString() + 'px';
        componentRef.instance.top = 0;
        componentRef.instance.changedSize.subscribe(this.conveyorSizeChange.bind(this));
      }
    }
    if (this.modulePlan.connections.some(ex => ex.moduleConnection === ModuleConnections.center)) {
      const componentRef = this.setComponentToView0deg(this.containerCenter, DesignConveyor0degComponent, ModuleConnections.center);
      if (componentRef !== null) {

        const conveyor: Conveyor = this.modulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.center).conveyor;
        if (conveyor.LenghtType === ConveyorLenghtType.short && conveyor.EndPointType === EndPointTypes.module) {
          conveyor.LenghtType = ConveyorLenghtType.long;
        }

        componentRef.instance.top = 0;
        componentRef.instance.changedSize.subscribe(this.conveyorSizeChange.bind(this));
      }
    }
    if (this.modulePlan.connections.some(ex => ex.moduleConnection === ModuleConnections.right)) {
      const componentRef = this.setComponentToView90deg(this.containerRight, DesignConveyor90degComponent, ModuleConnections.right);
      if (componentRef !== null) {
        const conveyor: Conveyor = this.modulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right).conveyor;
        if (conveyor.LenghtType === ConveyorLenghtType.short && conveyor.EndPointType === EndPointTypes.module) {
          conveyor.LenghtType = ConveyorLenghtType.long;
        }
        // componentRef.instance.left = 'calc(75% - ' +
        //  ((((<number><any>(this.height.substring(0, this.height.length - 2)) * 0.50) / 240) * (conveyor.Lenght)) / 2)
        //  + 'px)';
        componentRef.instance.left = ((5.319 * 50) / (303 / this.rawWidth) - ((45 / 2) / (303 / this.rawWidth))).toString() + 'px';
        componentRef.instance.top = '100%';
        componentRef.instance.changedSize.subscribe(this.conveyorSizeChange.bind(this));
      }
    }
    if (this.modulePlan.connections.some(ex => ex.moduleConnection === ModuleConnections.right_1)) {
      const componentRef = this.setComponentToView90deg(this.containerRight_1, DesignConveyor90degComponent, ModuleConnections.right_1);
      if (componentRef !== null) {
        const conveyor: Conveyor = this.modulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_1).conveyor;
        if (conveyor.LenghtType === ConveyorLenghtType.short && conveyor.EndPointType === EndPointTypes.module) {
          conveyor.LenghtType = ConveyorLenghtType.long;
        }
        // componentRef.instance.left = 'calc(65% - ' +
        //  ((((<number><any>(this.height.substring(0, this.height.length - 2)) * 0.50) / 240) * (conveyor.Lenght)) / 2)
        //  + 'px)';
        componentRef.instance.left = ((4.319 * 50) / (303 / this.rawWidth) - ((45 / 2) / (303 / this.rawWidth))).toString() + 'px';
        componentRef.instance.top = '100%';
        componentRef.instance.changedSize.subscribe(this.conveyorSizeChange.bind(this));
      }
    }
    if (this.modulePlan.connections.some(ex => ex.moduleConnection === ModuleConnections.right_2)) {
      const componentRef = this.setComponentToView90deg(this.containerRight_2, DesignConveyor90degComponent, ModuleConnections.right_2);
      if (componentRef !== null) {
        const conveyor: Conveyor = this.modulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_2).conveyor;
        if (conveyor.LenghtType === ConveyorLenghtType.short && conveyor.EndPointType === EndPointTypes.module) {
          conveyor.LenghtType = ConveyorLenghtType.long;
        }
        // componentRef.instance.left = 'calc(85% - ' +
        //  ((((<number><any>(this.height.substring(0, this.height.length - 2)) * 0.50) / 240) * (conveyor.Lenght)) / 2)
        //  + 'px)';
        componentRef.instance.left = ((5.319 * 50) / (303 / this.rawWidth) - ((45 / 2) / (303 / this.rawWidth))).toString() + 'px';
        componentRef.instance.top = '100%';
        componentRef.instance.changedSize.subscribe(this.conveyorSizeChange.bind(this));
      }
    }
    if (this.modulePlan.connections.some(ex => ex.moduleConnection === ModuleConnections.right_3)) {
      const componentRef = this.setComponentToView90deg(this.containerRight_3, DesignConveyor90degComponent, ModuleConnections.right_3);
      if (componentRef !== null) {
        const conveyor: Conveyor = this.modulePlan.connections.find(ex => ex.moduleConnection === ModuleConnections.right_3).conveyor;
        if (conveyor.LenghtType === ConveyorLenghtType.short && conveyor.EndPointType === EndPointTypes.module) {
          conveyor.LenghtType = ConveyorLenghtType.long;
        }
        // componentRef.instance.left = 'calc(85% - ' +
        //  ((((<number><any>(this.height.substring(0, this.height.length - 2)) * 0.50) / 240) * (conveyor.Lenght)) / 2)
        //  + 'px)';
        componentRef.instance.left = ((2.220 * 50) / (303 / this.rawWidth) - ((45 / 2) / (303 / this.rawWidth))).toString() + 'px';
        componentRef.instance.top = '100%';
        componentRef.instance.changedSize.subscribe(this.conveyorSizeChange.bind(this));
      }
    }

  }

  checkConveyor(conveyor: Conveyor, connection: ModuleConnections): Conveyor {
    conveyor.setConveyorOnlineState(false);

    if (this._modulePlan.modul.Components.find(ex => ex.Connections &&
      ex.Connections.filter(et => et === connection).length > 0 && ex.Virtual === true)) {
      if (this.moduleHandling.ClusterInitialized && this.moduleHandling.ClusterConnected) {
        conveyor.setConveyorOnlineState(true);
      } else {
        conveyor.setConveyorOnlineState(true);
      }
      return conveyor;
    }

    if (this.modulePlan.customerModule) {
        if (this.modulePlan.customerModule.InternalBeltStates !== null && this.modulePlan.customerModule.InternalBeltStates !== undefined) {
            if (connection === ModuleConnections.center) {
              const beltname = this._modulePlan.modul.Components.find(ex => ex.Connections &&
                ex.Connections.filter(et => et === ModuleConnections.center).length > 0);

              if (beltname) {
                // FIND CONVEYOR IN NETWORK INFO
                const beltstate = this.modulePlan.customerModule.InternalBeltStates.find(ex => ex.name === beltname.PlcKey);

                if (beltstate) {
                  if (this.moduleHandling.ClusterInitialized && this.moduleHandling.ClusterConnected) {
                    conveyor.setConveyorOnlineState(beltstate.position !== ExternalBeltPosition.NOTCONNECTED);
                  } else {
                    conveyor.setConveyorOnlineState(false);
                  }
                } else {
                  conveyor.setConveyorOnlineState(false);
                }

              }
              return conveyor;
            } else {
              const beltname = this._modulePlan.modul.Components.find(ex => ex.Connections &&
                ex.Connections.filter(et => et === connection).length > 0);

              if (beltname) {
                const beltstate = this.modulePlan.customerModule.InternalBeltStates.find(ex => ex.name === beltname.PlcKey);
                if (beltstate) {
                  if (beltstate.position === ExternalBeltPosition.NOTCONNECTED) {
                    conveyor.setConveyorOnlineState(false);
                  } else {
                    if (beltstate.position === ExternalBeltPosition.RIGHT) {
                      if (this.moduleHandling.ClusterInitialized  && this.moduleHandling.ClusterConnected) {
                        conveyor.setConveyorOnlineState(connection === ModuleConnections.right_1 ||
                          connection === ModuleConnections.right_2 ||
                          connection === ModuleConnections.right_3 ||
                          connection === ModuleConnections.right);
                      } else {
                        conveyor.setConveyorOnlineState(false);
                      }
                    } else if (beltstate.position === ExternalBeltPosition.LEFT) {
                      if (this.moduleHandling.ClusterInitialized && this.moduleHandling.ClusterConnected) {
                        conveyor.setConveyorOnlineState(connection === ModuleConnections.left ||
                          connection === ModuleConnections.left_2 ||
                          connection === ModuleConnections.left_1 ||
                          connection === ModuleConnections.left_3);
                      } else {
                        conveyor.setConveyorOnlineState(false);
                      }
                    } else {
                      conveyor.setConveyorOnlineState(false);
                    }
                  }
                }
              } else {
                conveyor.setConveyorOnlineState(false);
              }
            }

        }

    }
    // conveyor.Conntected = false;
    return conveyor;

  }

  setComponentToView0deg(container: ViewContainerRef,
                         type: Type<DesignConveyor0degComponent>,
                         connectionPoint: ModuleConnections): ComponentRef<DesignConveyor0degComponent> {
    container.clear();
    if (this.modulePlan.connections.find(ex => ex.moduleConnection === connectionPoint).conveyor !== null) {
      let conveyor: Conveyor = this.modulePlan.connections.find(ex => ex.moduleConnection === connectionPoint).conveyor;
      conveyor = this.checkConveyor(conveyor, connectionPoint);

      const factory = this.resolver.resolveComponentFactory(type);
      const componentRef = container.createComponent(factory);

      componentRef.instance.conveyor = conveyor;
      componentRef.instance.connectedToModule = this._modulePlan.customerModule.CustomerModuleId;
      componentRef.instance.size = 6058 / this._rawWidth;
      // componentRef.instance.changedConveyor.subscribe(this.GetChangedConveyor.bind(this));
      // componentRef.instance.changeConnection.subscribe(this.ChangeConnection.bind(this));
      componentRef.instance.isSelected = this.IsModuleSelected;
      componentRef.instance.clickedOnConveyor.subscribe(this.ModuleClicked.bind(this));
      componentRef.instance.modulerotation = this.modulePlan.rotation;
      return componentRef;
    }
    return null;

  }

  setComponentToView90deg(container: ViewContainerRef,
                          type: Type<DesignConveyor90degComponent>,
                          connectionPoint: ModuleConnections): ComponentRef<DesignConveyor90degComponent> {
    container.clear();
    if (this.modulePlan.connections.find(ex => ex.moduleConnection === connectionPoint).conveyor !== null) {
      let conveyor: Conveyor = this.modulePlan.connections.find(ex => ex.moduleConnection === connectionPoint).conveyor;
      conveyor = this.checkConveyor(conveyor, connectionPoint);

      const factory = this.resolver.resolveComponentFactory(type);
      const componentRef = container.createComponent(factory);
      componentRef.instance.conveyor = conveyor;
      componentRef.instance.connectedToModule = this._modulePlan.customerModule.CustomerModuleId;
      componentRef.instance.size = 6058 / this._rawWidth;
      // componentRef.instance.changedConveyor.subscribe(this.GetChangedConveyor.bind(this));
      // componentRef.instance.changeConnection.subscribe(this.ChangeConnection.bind(this));
      componentRef.instance.clickedOnConveyor.subscribe(this.ModuleClicked.bind(this));
      componentRef.instance.isSelected = this.IsModuleSelected;
      componentRef.instance.modulerotation = this.modulePlan.rotation;
      return componentRef;
    }
    return null;

  }

  setComponentToView270deg(container: ViewContainerRef,
                           type: Type<DesignConveyor270degComponent>,
                           connectionPoint: ModuleConnections): ComponentRef<DesignConveyor270degComponent> {
    container.clear();
    if (this.modulePlan.connections.find(ex => ex.moduleConnection === connectionPoint).conveyor !== null) {
      let conveyor: Conveyor = this.modulePlan.connections.find(ex => ex.moduleConnection === connectionPoint).conveyor;
      conveyor = this.checkConveyor(conveyor, connectionPoint);

      const factory = this.resolver.resolveComponentFactory(type);
      const componentRef = container.createComponent(factory);
      componentRef.instance.conveyor = conveyor;
      componentRef.instance.connectedToModule = this._modulePlan.customerModule.CustomerModuleId;
      componentRef.instance.size = 6058 / this._rawWidth;
      // componentRef.instance.changedConveyor.subscribe(this.GetChangedConveyor.bind(this));
      // componentRef.instance.changeConnection.subscribe(this.ChangeConnection.bind(this));
      componentRef.instance.isSelected = this.IsModuleSelected;
      componentRef.instance.modulerotation = this.modulePlan.rotation;
      componentRef.instance.clickedOnConveyor.subscribe(this.ModuleClicked.bind(this));
      return componentRef;
    }
    return null;

  }


  public userDarkTheme(): boolean {
    return this.moduleHandling.CurrentViewMode !== ViewCode.live;
  }

  public runningModeLive(): boolean {

    if (this.moduleHandling.CurrentViewMode === ViewCode.live) {
      if (this.modulePlan) {
        if (this.modulePlan.customerModule) {
          if (this.modulePlan.customerModule.Module) {
            if (this.moduleHandling.ClusterInitialized) {
              return this.modulePlan.customerModule.Module.Connected;
            }
          }
        }
      }
    } else {
      this.blinkingActive = false;
      return false;
    }
    this.blinkingActive = false;
    return false;
  }

  public UpdateRuningState() {

    if (this.running >= 0) {
      switch (this.running) {
        case 0: {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = false;
          this.CurrentStateColor = 'red';
          break;
        }
        case 1: {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = false;
          this.CurrentStateColor = 'yellow';
          break;
        }
        case 2: {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = false;
          this.CurrentStateColor = 'green';
          break;
        }
        case 3: {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = false;
          this.CurrentStateColor = 'yellow';
          break;
        }
        case 4: {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = false;
          this.CurrentStateColor = 'blue';
          break;
        }

        case 6:
        case 9:
        case 10:
        {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = true;

          // this.RuningStateHandler = setInterval( () => {
          //   if (this.CurrentStateColor ===  'blue') {
          //     this.CurrentStateColor = 'transparent';
          //   } else {
          //     this.CurrentStateColor = 'blue';
          //
          //   }
          // }, 500);
          break;
        }
        case 7: {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = false;
          this.CurrentStateColor = 'white';
          break;
        }
        case 8: {
          // clearInterval(this.RuningStateHandler);
          this.blinkingActive = true;
          // this.RuningStateHandler = setInterval( () => {
          //   if (this.CurrentStateColor ===  'red') {
          //     this.CurrentStateColor = 'transparent';
          //   } else {
          //     this.CurrentStateColor = 'red';
          //
          //   }
          // }, 500);
          break;
        }
        default: {
          this.blinkingActive = false;
          clearInterval(this.RuningStateHandler);
          this.CurrentStateColor = 'transparent';
          break;
        }
      }
      return;
    }
  }

  public ModuleRunningState() {
    if (this._modulePlan.customerModule) {
      if (this._modulePlan.customerModule.SerialNumber) {
          if (this._modulePlan.customerModule.Module.NetworkInfo) {
            // this._modulePlan.customerModule.Module.NetworkInfo.statemodule = state.state;
            this.running = this._modulePlan.customerModule.Module.NetworkInfo.statemodule;
            this.UpdateRuningState();
            return;
          } else {
            this.running = -1;
            this.UpdateRuningState();
          }
      }
    }
  }

  public SetModuleActive() {
    this.IsModuleSelected = true;
    this.opacity = '1';
    // this.zindex = '2';

    this.drawElements();
  }

  haveConnection(direction: number): boolean {
    if (!this.IsModuleSelected) {
      return false;
    }

    if (this.modulePlan.modul.ConveyorBelts.find(x => x.ConnectionPoint === direction)) {
      if (!this.modulePlan.connections.some(ex => ex.moduleConnection === direction)) {
        return true;
      }
    }

    return false;
  }

  connectionMissing(direction: number): boolean {
    if (!this.IsModuleSelected) {
      if (this.modulePlan.modul.ConveyorBelts.find(x => x.ConnectionPoint === direction)) {
        if (!this.modulePlan.connections.some(ex => ex.moduleConnection === direction)) {
          return true;
        }
      }
    }

    return false;
  }

  Connected(direction: number): boolean {
    if (!this.IsModuleSelected) {
      return false;
    }

    if (this.modulePlan.modul.ConveyorBelts.find(x => x.ConnectionPoint === direction)) {
      if (this.modulePlan.connections.some(ex => ex.moduleConnection === direction && ex.conveyor !== null)) {
        return true;
      }
    }

    return false;
  }

  haveNotConnection(direction: number): boolean {
    if (!this.IsModuleSelected) {
      return false;
    }

    if (this.modulePlan.modul.ConveyorBelts.find(x => x.ConnectionPoint === direction)) {
      if (this.modulePlan.connections.some(ex => ex.moduleConnection ===  direction)) {
        if (this.modulePlan.connections.find(ex => ex.moduleConnection === direction).conveyor == null) {
          return true;
        }
      }
    }

    return false;
  }

  rotateClockwise() {
    this.modulePlan.rotateCounterClockwise();
    this.rotation();
  }

  rotateCounterClockwise() {
    this.modulePlan.rotateClockwise();
    this.rotation();
  }

  ModuleClicked() {
    if (!this.IsModuleSelected) {
      this.moduleHandling.SetModuleActive(this);
    } else if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
      this.moduleHandling.OpenModuleSettings();
    } else if (this.modulePlan.customerModule && this.modulePlan.customerModule.SerialNumberSetted) {
      // Not init?
      if (!this.modulePlan.customerModule.FullyConfigured) {
        this.ShowModuleOnboarding.emit();
      } else {
        this.moduleHandling.OpenModuleSettings();
      }


    }
  }

  clickConveyorAdd(position: string) {
    let id: string = position;

    if (id.substring(0, 1) === 'x') {
      id = id.substring(1, id.length);
    }

    if (id.substring(0, 1) === 'c') {
      id = id.substring(1, id.length);
    }

    switch (id) {
      case 'left': {
        this.activeButtonSide = ModuleConnections.left;
        break;
      }
      case 'left_1': {
        this.activeButtonSide = ModuleConnections.left_1;
        break;
      }
      case 'left_2': {
        this.activeButtonSide = ModuleConnections.left_2;
        break;
      }
      case 'left_3': {
        this.activeButtonSide = ModuleConnections.left_3;
        break;
      }
      case 'middle': {
        this.activeButtonSide = ModuleConnections.center;
        break;
      }
      case 'right': {
        this.activeButtonSide = ModuleConnections.right;
        break;
      }
      case 'right_1': {
        this.activeButtonSide = ModuleConnections.right_1;
        break;
      }
      case 'right_2': {
        this.activeButtonSide = ModuleConnections.right_2;
        break;
      }
      case 'right_3': {
        this.activeButtonSide = ModuleConnections.right_3;
        break;
      }
      default: {
        this.activeButtonSide = null;
      }
    }

    this.moduleHandling.ModuleConnectionPointChange();

  }

  RemoveSelectedConnection() {
    if (this.activeButtonSide != null) {
      this.modulePlan.removeConveyorConnection(this.activeButtonSide);
      this.drawElements();
    }
  }

  SetSelectedConveyor(selected: Conveyor) {

    if (selected !== null) {
      const conv = selected.Copy();

      if (this.activeButtonSide != null) {
        switch (this.activeButtonSide) {
          case ModuleConnections.right_1: {
            conv.ConnectionPoint = ModuleConnections.right_1;
            break;
          }
          case ModuleConnections.right_2: {
            conv.ConnectionPoint = ModuleConnections.right_2;
            break;
          }
          case ModuleConnections.right_3: {
            conv.ConnectionPoint = ModuleConnections.right_3;
            break;
          }
          case ModuleConnections.center: {
            conv.ConnectionPoint = ModuleConnections.center;
            break;
          }
          case ModuleConnections.left_1: {
            conv.ConnectionPoint = ModuleConnections.left_1;
            break;
          }
          case ModuleConnections.left: {
            conv.ConnectionPoint = ModuleConnections.left;
            break;
          }
          case ModuleConnections.right: {
            conv.ConnectionPoint = ModuleConnections.right;
            break;
          }
          case ModuleConnections.left_2: {
            conv.ConnectionPoint = ModuleConnections.left_2;
            break;
          }
          case ModuleConnections.left_3: {
            conv.ConnectionPoint = ModuleConnections.left_3;
            break;
          }
        }
        this.modulePlan.addConntection(conv, conv.ConnectionPoint);
      }
    } else {
      this.modulePlan.addConntection(null, this.activeButtonSide);
    }
    this.drawElements();
  }

  public ShowSpinner(): boolean {
    if (this.moduleHandling.CurrentViewMode === ViewCode.live) {
      if (this.modulePlan.customerModule) {
        if (this.modulePlan.customerModule.FullyConfigured) {
          if (!this.moduleHandling.ClusterInitialized) {
            return true;
          }
          // return !this.modulePlan.modul.Connected;
        }
        return false;
      }
    }
    return false;
  }

  public ShowService(): boolean {
    if (this.ShowSpinner() || this.ShowOnboarding()) {
      return false;
    }

    if ((this.moduleHandling.ClusterInitialized || this.moduleHandling.DemoView) && this.moduleHandling.CurrentViewMode === ViewCode.live) {
      if (this.modulePlan.customerModule.Module.CurrentMode === ModeSwitch.SERVICE) {
        return true;
      }
    }
    return false;
  }

  public ShowOnboarding(): boolean {
    if (this.moduleHandling.CurrentViewMode === ViewCode.live) {
      if (this._modulePlan) {
        if (this.modulePlan.customerModule) {
          if (!this.modulePlan.customerModule.FullyConfigured) {
            return true;
          }
        }
      }
      return false;
    } else {
      return false;
    }
  }

  // WARNING: Not directly referenced but required
  public SetModuleInactive() {
    this.IsModuleSelected = false;
    this.opacity = '.6';
    this.drawElements();
  }

  public AddSupportModule() {
    this.moduleHandling.AddSupportModuleClick();

    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.panelClass = 'loginDialogGray';
    // dialogConfig.data = 'NOT IMPLEMENTED';
    // this.dialog.open(MessageBoxComponent, dialogConfig);
  }

  public haveSupportModule(): boolean {
    if (this._modulePlan) {
      if (this.modulePlan.customerModule) {
        if (this.modulePlan.customerModule.Module) {

          if (this.moduleHandling.getSupportModuleForModulePlan(this._modulePlan)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public ShowAddSupportButton(): boolean {
    if (this._modulePlan) {
      if (!this.IsModuleSelected) {
        return false;
      }



      if (this.modulePlan.customerModule) {
        if (this.modulePlan.customerModule.Module) {

          if (this.moduleHandling.getSupportModuleForModulePlan(this._modulePlan)) {
            return false;
          }

          if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
            return this._modulePlan.customerModule.Module.AirExtractionPossible;
          }
        }
      }
    }


    return false;
  }

  public SupportModuleOnboardingRequested() {
    if (!this.IsModuleSelected) {
       this.moduleHandling.SetModuleActive(this);
    } else {
      this.ShowSupportModuleOnboarding.emit(this.moduleHandling.getSupportModuleForModulePlan(this._modulePlan));
    }
  }

  public getThis(): ModuleDesignComponent {
    return this;
  }

  public getSupportModule(): SupportModulePlan {

    if (this._modulePlan) {
      if (this.modulePlan.customerModule) {
        if (this.modulePlan.customerModule.Module) {
          return this.moduleHandling.getSupportModuleForModulePlan(this._modulePlan);

        }
      }
    }
    return null;
  }

  public ShowEmergencyStopTestRequired(): boolean {
    if (this._modulePlan?.modul?.EmergencyStopRequired == true && this.runningModeLive()) {
      return true;
    }

    return false;
  }

  public ShowEmergencyStopNotOk(): boolean {
    if (this._modulePlan?.modul?.EmergencyStopRequired == false && this._modulePlan?.modul.NetworkInfo?.emergencystopok == false && this.runningModeLive()) {
      return true;
    }

    return false;
  }

}
