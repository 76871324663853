import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BluectrlApiService} from '../../../services/bluectrl-api.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ServerCommunicationService} from '../../../services/server-communication.service';

@Component({
  selector: 'app-new-module',
  templateUrl: '../../../../themes/basic/templates/dialogs/new-module/new-module.component.html',
  styleUrls: ['../../../../themes/basic/templates/dialogs/new-module/new-module.component.css']
})
export class NewModuleComponent implements OnInit {


  serial: string;

  errorText: string;

  public groups: any[];
  public names: any[];
  public versions: any[];
  public organisations: any[];

  public selectableNames: any[];
  public selectableVersions: any[];

  public selectedGroup: any;
  public selectedName: any;
  public selectedVersion: any;
  public selectedOrganisation: any;
  private _serialPattern = /(B[0-9]{4}-[0-9]{3}-[0-9]{4})/i;
  private _serialPatternBox = /(B[0-9]{4}-[A-Z]{4}-[0-9]{3}-[0-9]{4})/i;


  constructor(private fb: FormBuilder,
              public apiService: BluectrlApiService,
              public server: ServerCommunicationService,
              private dialogRef: MatDialogRef<NewModuleComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
  }

  ngOnInit(): void {
    this.loadOrganisations();
    this.LoadModules();
  }

  CloseDialog() {
    this.dialogRef.close();
  }

  private LoadModules() {
    this.groups = [];
    this.names = [];
    this.versions = [];

    for (const mdl of this.server.Modules) {
      const group = mdl.Group;
      const groupId = mdl.Type.substring(0, 3);
      const name = mdl.Name;
      const nameId = mdl.Type.substring(3);
      const version = mdl.Version;

      if (!this.groups.find(ex => ex.id === groupId)) {
        this.groups.push({
          name: group,
          id: groupId
        });
      }

      if (!this.names.find(ex => ex.groupId === groupId && ex.id === nameId)) {
        this.names.push({
          groupId: groupId,
          id: nameId,
          name: name,
          key: groupId + '' + nameId
        });
      }

      if (!this.versions.find(ex => ex.groupId === groupId && ex.nameId === nameId && ex.id === version)) {
        this.versions.push({
          groupId: groupId,
          nameId: nameId,
          id: version,
          key: groupId + '' + nameId + '-' + version
        });
      }

    }
  }

  public SaveModule() {
    if (this.savePossible()) {

      this.apiService.addNewModule(this.serial, this.selectedOrganisation.id).subscribe((response: any) => {
        this.dialogRef.close(true);
      }, error => {
        console.log(error);
      });
    }
  }
  private loadOrganisations() {
    this.apiService.getOrganizations().subscribe(async (organizations: any) => {
      if (organizations.length > 0) {
        this.organisations = organizations;
      }
    });
  }


  public groupSelectChange(item: any) {
    this.selectedVersion = null;
    this.selectableVersions = null;
    this.selectedName = null;
    this.serial = null;
    if (item.value) {
      this.selectableNames = this.names.filter(ex => ex.groupId === item.value.id);
    } else {
      this.selectableNames = null;
    }
  }

  public namesSelectChange(item: any) {
    this.selectedVersion = null;
    this.serial = null;
    if (item.value) {
      this.selectableVersions = this.versions.filter(ex => ex.nameId === item.value.id && ex.groupId === this.selectedGroup.id);
    } else {
      this.selectedVersion = null;
    }
  }

  public versionSelectChange(item: any) {
    this.serial = null;
    if (item.value) {
      this.serial = item.value.key;
    } else {

    }
  }

  public savePossible(): boolean {

    this.errorText = null;
    if (this.selectedOrganisation) {
      if (this.serial) {
        let match = this.serial.trim().match(this._serialPattern);

        if (!match) {
          match = this.serial.trim().match(this._serialPatternBox);
        }
        if (!match) {
          this.errorText = 'Please enter valid serial number';
        } else {
          if (this.server.CustomerModules.find(ex => ex.SerialNumber === this.serial)) {
            this.errorText = 'Entered serial number already exists';
          } else {
            return true;
          }
        }
      } else {
        this.errorText = 'Please enter serial number';
      }
    } else {
      this.errorText = 'Please select organization';
    }

      return false;
  }



}
