import {Component, OnInit} from '@angular/core';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';


@Component({
  selector: 'app-modulemenubar',
  templateUrl: '../../../../themes/basic/templates/elements/module-menubar/module-menubar.component.html',
  styleUrls: ['../../../../themes/basic/templates/elements/module-menubar/module-menubar.component.css']
})
export class ModuleMenubarComponent implements OnInit {

  public ShowTestRecipeIcon = false;

  constructor(public moduleHandling: ModuleHandlingService,
              public dialog: MatDialog,
              public router: Router) {
  }

  ngOnInit() {
    const location = window.location.href;

    if (location.indexOf('localhost') >= 0) {
      this.ShowTestRecipeIcon = true;
    }
    if (location.indexOf('dev') >= 0) {
      this.ShowTestRecipeIcon = true;
    }

  }

  public SettingsActive(): boolean {

    if (this.moduleHandling.ActiveModule !== null &&
      this.moduleHandling.ActiveModule !== undefined) {
      if (this.moduleHandling.ActiveModule !== null &&
        this.moduleHandling.ActiveModule !== undefined) {
        if (this.moduleHandling.ActiveModule.customerModule !== null &&
          this.moduleHandling.ActiveModule.customerModule !== undefined) {
          return true;
        }
      }
    }

    return false;
  }

  public isDemo(): boolean {
    if (this.moduleHandling.ActiveModule) {
      if (this.moduleHandling.ClusterInitialized !== true) {
        return false;
      }
      return environment.demo;
    } else {
      return false;
    }
  }

  public hasSupport(): boolean {
    if (this.moduleHandling.ActiveModule) {
      if (this.moduleHandling.getSupportModuleForModulePlan(this.moduleHandling.ActiveModule)) {
        return true;
      }
    }

    return false;
  }

}
