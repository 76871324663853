import { Injectable } from '@angular/core';
import {ModulePlan} from '../classes/module-plan';
import {NetworkDevice} from '../classes/messaging/network-device';
import {InternalBeltState} from '../classes/internal-belt-state';
import {ModuleConnections} from '../classes/enums/module-connections.enum';
import {MessageTypes} from '../classes/enums/message-types.enum';
import {ContextTypes} from '../classes/enums/context-types.enum';
import {Entities} from '../classes/enums/entities.enum';
import {ModuleHandlingService} from './module-handling.service';

@Injectable({
  providedIn: 'root'
})
export class DemoHandlingService {


  public currentModulePlan: ModulePlan;

  constructor() { }

  public SetModuleOnboardingInformations(modulePlan: ModulePlan) {

    const beltStates: InternalBeltState[] = [];

    for (const con of modulePlan.connections) {
      if (con.conveyor) {
        // GET COMP
        const comp = modulePlan.modul.Components.filter(ex => ex.Connections).find(ex => ex.Connections.filter(et => et === con.moduleConnection).length > 0);

        if (comp) {
          const conName = this.getSideOfConnection(con.moduleConnection);
          beltStates.push(new InternalBeltState(comp.PlcKey, conName));

          // INTERNAL POSITION
          if (modulePlan.modul.ConveyorBelts) {
            const cs = modulePlan.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === con.moduleConnection);

            if (cs) {
              if (cs.RelatedInternalBelt) {
                beltStates.push(new InternalBeltState(cs.RelatedInternalBelt, conName));
              }
            }
          }

        }
      }
    }



    const networkInfo = new NetworkDevice(modulePlan.customerModule.SerialNumber, '0.0.0.0', 0, 0, 'auto', beltStates, false, true,false, 'x.x.x', 'x.x.x', 'x.x.x');

    if (modulePlan.customerModule.Module.Group === 'SENSOR') {
      networkInfo.additionalModuleInformations = [];
      networkInfo.additionalModuleInformations.push({
        name: 'IDE01.MMI.SZ',
        values: 'SZ1;SZ2;SZ3;SZ4;SZ5;SZ6;SZ7'
      });
    }

    modulePlan.setModuleNetworkState(networkInfo);

  }

  public SetClusterOnboardingInformations(modulePlans: ModulePlan[]) {
    for (const md of modulePlans) {
      this.SetModuleOnboardingInformations(md);
    }
  }

  private getSideOfConnection(con: ModuleConnections): string {
    switch (con) {
      case ModuleConnections.center: {
        return 'middle';
      }
      case ModuleConnections.left:
      case ModuleConnections.left_1:
      case ModuleConnections.left_2:
      case ModuleConnections.left_3: {
        return 'left';
      }
      case ModuleConnections.none: {
        return null;
      }
      case ModuleConnections.right:
      case ModuleConnections.right_1:
      case ModuleConnections.right_2:
      case ModuleConnections.right_3: {
        return 'right';
      }
      default: {
        return '';
      }
    }
  }

  public getMonitoringMessage(msgid: string): any {

    const modulePlan = this.currentModulePlan;

    if (modulePlan) {

      const msgs = [];


      for (const cp of modulePlan.modul.Components) {

        const params = [];

        for (const mn of cp.Monitorings) {
          const nmConf = cp.Configs.find(ex => ex.Name === mn.Name);

          if (nmConf) {
            params.push({
              param: mn.Name,
              value: nmConf.CurrentValue
            });
          } else if (mn.DefaultValue) {
            params.push({
              param: mn.Name,
              value: mn.DefaultValue
            });
          } else if (mn.MinValue) {
            params.push({
              param: mn.Name,
              value: mn.MinValue
            });
          } else {
            params.push({
              param: mn.Name,
              value: 0
            });
          }
        }
        msgs.push({
          name: cp.PlcKey,
          parameter: params
        });
      }

      const response = {
        type: MessageTypes.NOTIFICATION,
        context: ContextTypes.MODULE,
        ctxId: modulePlan.customerModule.SerialNumber,
        msgId: msgid,
        entity: Entities.MONITORING,
        devices: msgs
      };

      return response;

    }

    return null;
  }

}
