import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DatePipe} from '@angular/common';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/views/home/home.component';
import {MaterialModule} from './material/material.module';
import { LayoutComponent } from './components/views/layout/layout.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { HeaderComponent } from './components/navigation/header/header.component';
import {RoutingModule} from './routing/routing.module';
import {MatIconModule} from '@angular/material/icon';
import {UserManagementService} from './services/user-management.service';
import {ServerCommunicationService} from './services/server-communication.service';
import { LoginDialogComponent } from './components/dialogs/login-dialog/login-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MenuComponent } from './components/navigation/menu/menu.component';
import { DesignViewComponent } from './components/views/design-view/design-view.component';
import { ModuleMenubarComponent } from './components/elements/module-menubar/module-menubar.component';
import {MenuActionService} from './services/menu-action.service';
import { ModuleCatalogComponent } from './components/views/module-catalog/module-catalog.component';
import {ModuleCatalogRowComponent} from './components/elements/module-catalog-row/module-catalog-row.component';
import { ModuleDesignComponent } from './components/elements/module-design/module-design.component';
import {ModuleHandlingService} from './services/module-handling.service';
import { DesignConveyor0degComponent } from './components/elements/conveyors/design-conveyor0deg/design-conveyor0deg.component';
import { DesignConveyor90degComponent } from './components/elements/conveyors/design-conveyor90deg/design-conveyor90deg.component';
import { DesignConveyor270degComponent } from './components/elements/conveyors/design-conveyor270deg/design-conveyor270deg.component';
import { ModuleSettingsComponent } from './components/views/module-settings/module-settings.component';
import { ComponentConfiguratorComponent } from './components/elements/component-configurator/component-configurator.component';
import { HammerGestureConfig} from '@angular/platform-browser';
import { MessageBoxComponent } from './components/dialogs/message-box/message-box.component';
import { SaveProjectDialogComponent } from './components/dialogs/save-project-dialog/save-project-dialog.component';
import { LivePlateComponent } from './components/views/live-plate/live-plate.component';
import { SendRecipeComponent } from './components/dialogs/send-recipe/send-recipe.component';
import {CookieService} from 'ngx-cookie-service';
import {CookieHandlingService} from './services/cookie-handling.service';
import { ScrollDirective } from './directives/scroll.directive';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { InventoryComponent } from './components/views/inventory/inventory.component';
import { InventoryRowComponent } from './components/elements/inventory-row/inventory-row.component';
import { ShareDialogComponent } from './components/dialogs/share-dialog/share-dialog.component';
import { ComponentMonitoringComponent } from './components/elements/component-monitoring/component-monitoring.component';
import { ComponentConfigComponent } from './components/elements/component-config/component-config.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import { WifiServiceNotFoundComponent } from './components/dialogs/wifi-service-not-found/wifi-service-not-found.component';
import { ClusterOnboardingComponent } from './components/dialogs/cluster-onboarding/cluster-onboarding.component';
import { QuestionBoxComponent } from './components/dialogs/question-box/question-box.component';
import { WaitingModuleStopComponent } from './components/dialogs/waiting-module-stop/waiting-module-stop.component';
import { ConfirmMessageBoxComponent } from './components/dialogs/confirm-message-box/confirm-message-box.component';
import { RuleNotficationDialogComponent } from './components/dialogs/rule-notfication-dialog/rule-notfication-dialog.component';
import { OptionComponent } from './components/navigation/option/option.component';
import { DialogDemoMenuComponent } from './components/elements/dialog-demo-menu/dialog-demo-menu.component';
import {JwtModule} from '@auth0/angular-jwt';
import {MaintenanceTabComponent} from './components/elements/maintenance-tab/maintenance-tab.component';
import {ModuleOnboardingComponent} from './components/views/module-onboarding/module-onboarding.component';
import { PositioningTabComponent } from './components/elements/positioning-tab/positioning-tab.component';
import { LevelingTabComponent } from './components/elements/leveling-tab/leveling-tab.component';
import { ModuleOnboardingTabComponent } from './components/elements/module-onboarding-tab/module-onboarding-tab.component';
import { IventoryModuleElementComponent } from './components/elements/inventory-module-element/iventory-module-element.component';
import { ServiceModeSynchronizationDialogComponent } from './components/dialogs/service-mode-synchronization-dialog/service-mode-synchronization-dialog.component';
import { ComponentServiceComponent } from './components/elements/component-service/component-service.component';
import { StandaloneClusterInitComponent } from './components/dialogs/standalone-cluster-init/standalone-cluster-init.component';
import {BluectrlTranslateService} from './services/bluectrl-translate.service';
import { RunServiceConfigurationComponent } from './components/dialogs/run-service-configuration/run-service-configuration.component';
import { ClusterOnboardingTabComponent } from './components/elements/cluster-onboarding-tab/cluster-onboarding-tab.component';
import { SplashScreenComponent } from './components/dialogs/splash-screen/splash-screen.component';
import { LoadProjectComponent } from './components/views/load-project/load-project.component';
import { SetupTaskListComponent } from './components/elements/setup-task-list/setup-task-list.component';
import {WebsocketService} from './services/v2/websocket.service';
import {MessageHandlingService} from './services/v2/message-handling.service';
import { WifiAutoChangeErrorComponent } from './components/dialogs/wifi-auto-change-error/wifi-auto-change-error.component';
import { DemoModuleStateComponent } from './components/dialogs/demo-module-state/demo-module-state.component';
import { SupportCatalogComponent } from './components/views/support-catalog/support-catalog.component';
import { SupportModuleDesignComponent } from './components/elements/support-module-design/support-module-design.component';
import { RecipeValidationErrorDialogComponent } from './components/dialogs/recipe-validation-error-dialog/recipe-validation-error-dialog.component';
import {ModuleOnboardingService} from './services/v2/module-onboarding.service';
import {ClusterOnboardingService} from './services/v2/cluster-onboarding.service';
import { ConveyorSelectionDialogComponent } from './components/dialogs/conveyor-selection-dialog/conveyor-selection-dialog.component';
import { NotificationViewComponent } from './components/elements/notification-view/notification-view.component';
import { UpdateProgressComponent } from './components/dialogs/update-progress/update-progress.component';
import {UpdateService} from './services/update.service';
import {WifiManagementService} from './services/v2/wifi-management.service';
import { HandsoverPointsTabComponent } from './components/elements/handsover-points-tab/handsover-points-tab.component';
import { ProjectSaveWarningComponent } from './components/dialogs/project-save-warning/project-save-warning.component';
import { AdminViewComponent } from './components/views/admin-view/admin-view.component';
import { ModuleDetailViewComponent } from './components/views/module-detail-view/module-detail-view.component';
import { NewOrganzationComponent } from './components/dialogs/new-organzation/new-organzation.component';
import { NewModuleComponent } from './components/dialogs/new-module/new-module.component';
import { NewUserComponent } from './components/dialogs/new-user/new-user.component';
import { I18NEXT_SERVICE, I18NextLoadResult, I18NextModule, ITranslationService, defaultInterpolationFormat } from 'angular-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import {VisibilityService} from './services/visibility.service';
import {ConnectionServiceModule, ConnectionServiceOptions, ConnectionServiceOptionsToken} from 'angular-connection-service';
import { InitOfflineUserDialogComponent } from './components/dialogs/init-offline-user-dialog/init-offline-user-dialog.component';
import {ConnectivityService} from './services/connectivity.service';
import { TransferRecipeDialogComponent } from './components/dialogs/transfer-recipe-dialog/transfer-recipe-dialog.component';
import { DecisionMessageBoxComponent } from './components/dialogs/decision-message-box/decision-message-box.component';


// https://www.npmjs.com/package/@auth0/angular-jwt
export function tokenGetter() {
  const token = localStorage.getItem('bearertoken');

  if (token) {
    return token;
  } else {
    return null;
  }
}

const i18nextOptions = {
  whitelist: ['en', 'de'],
  fallbackLng: 'en',
  debug: false, // set debug?
  returnEmptyString: true,
  ns: [
    'translation'
  ],
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat)
  },
  // backend plugin options
  backend: {
    loadPath: 'assets/locale/{{lng}}.{{ns}}.json'
  },
  // lang detection plugin options
  detection: {
    // order and from where user language should be detected
    order: ['cookie'],

    // keys or params to lookup language from
    lookupCookie: 'lang',

    // cache user language on
    caches: ['cookie'],

    // optional expire and domain for set cookie
    cookieMinutes: 10080 // 7 days
  }
};

export function appInit(i18next: ITranslationService) {
  return () => {
    let promise: Promise<I18NextLoadResult> = i18next
      .use(HttpApi)
      .use<any>(LanguageDetector)
      .init(i18nextOptions);
    return promise;
  };
}

export function localeIdFactory(i18next: ITranslationService)  {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory
  },
];

type StoreType = {
  // state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LayoutComponent,
    HeaderComponent,
    LoginDialogComponent,
    MenuComponent,
    DesignViewComponent,
    ModuleMenubarComponent,
    ModuleCatalogComponent,
    ModuleCatalogRowComponent,
    ModuleDesignComponent,
    DesignConveyor0degComponent,
    DesignConveyor90degComponent,
    DesignConveyor270degComponent,
    ModuleSettingsComponent,
    ComponentConfiguratorComponent,
    MessageBoxComponent,
    SaveProjectDialogComponent,
    LivePlateComponent,
    SendRecipeComponent,
    ScrollDirective,
    InventoryComponent,
    InventoryRowComponent,
    ShareDialogComponent,
    ComponentMonitoringComponent,
    ComponentConfigComponent,
    WifiServiceNotFoundComponent,
    ClusterOnboardingComponent,
    QuestionBoxComponent,
    WaitingModuleStopComponent,
    ConfirmMessageBoxComponent,
    RuleNotficationDialogComponent,
    OptionComponent,
    DialogDemoMenuComponent,
    ModuleOnboardingComponent,
    MaintenanceTabComponent,
    PositioningTabComponent,
    LevelingTabComponent,
    ModuleOnboardingTabComponent,
    IventoryModuleElementComponent,
    ServiceModeSynchronizationDialogComponent,
    ComponentServiceComponent,
    StandaloneClusterInitComponent,
    RunServiceConfigurationComponent,
    ClusterOnboardingTabComponent,
    SplashScreenComponent,
    LoadProjectComponent,
    SetupTaskListComponent,
    WifiAutoChangeErrorComponent,
    DemoModuleStateComponent,
    SupportCatalogComponent,
    SupportModuleDesignComponent,
    RecipeValidationErrorDialogComponent,
    ConveyorSelectionDialogComponent,
    NotificationViewComponent,
    UpdateProgressComponent,
    HandsoverPointsTabComponent,
    ProjectSaveWarningComponent,
    AdminViewComponent,
    ModuleDetailViewComponent,
    NewOrganzationComponent,
    NewModuleComponent,
    NewUserComponent,
    InitOfflineUserDialogComponent,
    TransferRecipeDialogComponent,
    DecisionMessageBoxComponent,

  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MaterialModule,
        FlexLayoutModule,
        RoutingModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        HttpClientModule,
        ZXingScannerModule,
        ConnectionServiceModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        FormsModule,
        I18NextModule.forRoot(),
        JwtModule.forRoot({
          config: {
            tokenGetter: tokenGetter,
            allowedDomains: ['localhost:8100',
              'dev.bluectrl-api.3d5.at',
              'test.bluectrl-api.3d5.at',
              'staging.api.bluectrl.app',
              'demo.api.bluectrl.app',
              'api.bluectrl.app'],
            disallowedRoutes: [
              'http://localhost:8100/auth/jwt/login',
              'https://dev.bluectrl-api.3d5.at/auth/jwt/login',
              'https://test.bluectrl-api.3d5.at/auth/jwt/login',
              'https://staging.api.bluectrl.app/auth/jwt/login',
              'https://api.bluectrl.app/auth/jwt/login',
              'https://demo.api.bluectrl.app/auth/jwt/login',
              'https://dev.bluectrl-api.3d5.at/health',
              'https://test.bluectrl-api.3d5.at/health',
              'https://staging.api.bluectrl.app/health',
              'https://api.bluectrl.app/health',
              'https://demo.api.bluectrl.app/health',
              /assets\.*/,
              /themes\.*/,
              /locales\.*/,

            ],
            authScheme: (request => {
              return 'Bearer ';
            }),
            throwNoTokenError: true,
            skipWhenExpired: true
          }
        })
    ],
  providers: [
    {
      provide: ConnectionServiceOptionsToken,
      useValue: <ConnectionServiceOptions>{
        enableHeartbeat: true,
        heartbeatUrl: environment.bluectrlapi + '/health',
        // heartbeatUrl: 'http://localhost:8000/health',
        requestMethod: 'get',
        heartbeatInterval: 30000,
        heartbeatRetryInterval: 10000
      }
    },
    I18N_PROVIDERS,
    ConnectivityService,
    VisibilityService,
    BluectrlTranslateService,
    WebsocketService,
    MessageHandlingService,
    WifiManagementService,
    UserManagementService,
    ServerCommunicationService,
    MenuActionService,
    ModuleHandlingService,
    CookieService,
    CookieHandlingService,
    ModuleOnboardingService,
    ClusterOnboardingService,
    DatePipe,
    UpdateService
  ],
  entryComponents: [LoginDialogComponent,
    InitOfflineUserDialogComponent,
    MessageBoxComponent,
    ConfirmMessageBoxComponent,
    SaveProjectDialogComponent,
    SendRecipeComponent,
    WifiServiceNotFoundComponent,
    WaitingModuleStopComponent,
    ],

  bootstrap: [AppComponent]
})



export class AppModule { }

