import { Component, OnInit, Input } from '@angular/core';
import {NotificationEntry} from '../../../classes/notificationEntry';
import {ModulePlan} from '../../../classes/module-plan';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';

@Component({
  selector: 'app-notification-view',
  templateUrl: '../../../../themes/basic/templates/elements/notification-view/notification-view.component.html',
  styleUrls: ['../../../../themes/basic/templates/elements/notification-view/notification-view.component.css']
})
export class NotificationViewComponent implements OnInit {

  private _notification: NotificationEntry;
  private _selected: string;

  @Input() set Selected(value: string) {
    if (value !== this._selected) {
      this._selected = value;
      if (this._notification) {
        if (this._selected === this._notification.Component) {
          this.opened = true;
        } else {
          this.opened = false;
        }
      }
    }
  }

  @Input() set Notification(value: NotificationEntry) {
    this._notification = value;

    if (this._selected) {
      if (this._selected === this._notification.Component) {
        this.opened = true;
      } else {
        this.opened = false;
      }
    }
  }

  public get Notification(): NotificationEntry {
    return this._notification;
  }

  public opened = false;
  constructor(public translate: BluectrlTranslateService) { }

  ngOnInit(): void {
  }

  public GetNotificationComponentNumber(notification: NotificationEntry): string {

    if (notification.Component) {
      const nmbr = notification.Component.substr(notification.Component.length - 2, 2);
      const nbr = Number(nmbr);
      if (nbr) {
        return nbr.toString();
      }
    }
    return '';
  }

  public GetNotificationText(notification: NotificationEntry): string {
    const text = this.translate.GetTranslation(notification.TranslateIDText);
    if (text && text !== notification.TranslateIDText) {
      return text;
    } else {
      return notification.Index.toString();
    }
  }

  public GetNotificationCode(notification: NotificationEntry): string {
    // const code = this.translate.GetTranslation(notification.TranslateIDNumber);
    // if (code && code !== notification.TranslateIDNumber) {
    //   return code;
    // } else {
    //   return notification.Index.toString();
    // }
    return notification.TextNumber;
  }

  // public GetNotificationBmK(notification: NotificationEntry): string {
  //   const text = this.translate.GetTranslation(notification.Bmk);
  //   if (text && text !== notification.Bmk) {
  //     return text;
  //   } else {
  //     return '';
  //   }
  // }

}
