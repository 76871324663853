import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomerModule} from '../../../classes/customer-module';
import {ModuleGlobalState} from '../../../classes/enums/module-global-state.enum';
import {ModuleServiceService} from '../../../services/module-service.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ServiceModeSynchronizationDialogComponent} from '../../dialogs/service-mode-synchronization-dialog/service-mode-synchronization-dialog.component';
import {DatabaseService} from '../../../services/database.service';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {QuestionBoxComponent} from '../../dialogs/question-box/question-box.component';
import {Router} from '@angular/router';
import {ConfirmMessageBoxComponent} from '../../dialogs/confirm-message-box/confirm-message-box.component';
import {StandaloneClusterInitComponent} from '../../dialogs/standalone-cluster-init/standalone-cluster-init.component';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {ModeSwitch} from '../../../classes/enums/mode-switch.enum';

@Component({
  selector: 'app-iventory-module-element',
  templateUrl: '../../../../themes/basic/templates/elements/inventory-module-element/iventory-module-element.component.html',
  styleUrls: ['../../../../themes/basic/templates/elements/inventory-module-element/iventory-module-element.component.css']
})
export class IventoryModuleElementComponent implements OnInit {

  @Input() customerModule: CustomerModule;
  @Output() ShowSettings = new EventEmitter<CustomerModule>();
  @Output() ShowDetailView = new EventEmitter<CustomerModule>();

  private _standAloneInitOngoing = false;
  private currentDemoState = 0;

  constructor(public moduleService: ModuleServiceService,
              public moduleHandling: ModuleHandlingService,
              public database: DatabaseService,
              public router: Router,
              public translate: BluectrlTranslateService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    const location = window.location.href;

    // if (location.indexOf('localhost') >= 0 ||
    //   location.indexOf('theme.') >= 0) {
    //   // SWITCH
    //   this.moduleService.Demo = true;
    // }
  }

  public SelectAction($event) {
    $event.stopPropagation();
    const location = window.location.href;

    // if (location.indexOf('localhost') >= 0 ||
    //   location.indexOf('theme.') >= 0) {
    //   // SWITCH
    //   this.moduleService.Demo = true;
    //
    //   if (this.currentDemoState === 0) {
    //     this.customerModule.ModuleSSID = '1';
    //     this.currentDemoState = 1;
    //   } else if (this.currentDemoState === 1) {
    //     this.customerModule.ModuleSSID = null;
    //     this.customerModule.ModuleGlobalState = ModuleGlobalState.Service;
    //     this.currentDemoState = 2;
    //   } else if (this.currentDemoState === 2) {
    //     this.customerModule.ModuleSSID = null;
    //     this.currentDemoState = 3;
    //     this.customerModule.ModuleGlobalState = ModuleGlobalState.ClusterService;
    //   } else if (this.currentDemoState === 3) {
    //     this.customerModule.ModuleSSID = null;
    //     this.customerModule.ModuleGlobalState = ModuleGlobalState.Cluster;
    //     this.currentDemoState = 4;
    //   } else if (this.currentDemoState === 4) {
    //     this.customerModule.ModuleSSID = null;
    //     this.customerModule.ModuleGlobalState = ModuleGlobalState.Unknown;
    //     this.currentDemoState = 5;
    //   } else if (this.currentDemoState === 5) {
    //     // EMERG STOP
    //     this.currentDemoState = 6;
    //   } else if (this.currentDemoState === 6) {
    //     // Service online
    //     this.currentDemoState = 0;
    //   }
    // }
  }

  public ShowSettingsOnClick($event) {
    $event.stopPropagation();
    const location = window.location.href;

    // if (location.indexOf('localhost') >= 0 ||
    //   location.indexOf('theme.') >= 0) {
    //   this.ShowDetailView.emit(this.customerModule);
    //   return;
    // }

    if (this.customerModule) {
      if (this.customerModule.Module.EmergencyStopRequired === false) {
        if (this.moduleService.StandaloneClusterInitialized === true &&
          this.moduleService.StandaloneModulePlan.customerModule.SerialNumber === this.customerModule.SerialNumber) {
          this.ShowSettings.emit(this.customerModule);
          return;
        }
      }
    }

    this.ShowDetailView.emit(this.customerModule);
  }

  public StartModuleSync() {
    // OPEN DIALOG
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'loginDialogGray';
    dialogConfig.data = this.customerModule;

    const dialogRef = this.dialog.open(ServiceModeSynchronizationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
    });

  }

  public HaveClusterId(): boolean {
    if (this.customerModule) {
      if (this.customerModule.InventoryInfo) {
        if (this.customerModule.InventoryInfo.ClusterId) {
          return true;
        }
      }
    }
    return false;
  }

  public CheckLoadCluster() {
    if (this.customerModule.ModuleGlobalState === ModuleGlobalState.Cluster ||
      this.customerModule.ModuleGlobalState === ModuleGlobalState.ClusterService) {
      if (this.customerModule.InventoryInfo) {
        if (this.customerModule.InventoryInfo.RecipeId) {
          // CHECK IF RECIPE
          this.database.recipes.toArray().then(rec => {
            const lookedfor = rec.find(ex => ex.id === this.customerModule.InventoryInfo.RecipeId);
            if (lookedfor) {
              const dt = JSON.parse(JSON.stringify(lookedfor));

              if (dt.recipe) {
                const reci = JSON.parse(dt.recipe);
                if (reci.cluster) {
                  if (this.customerModule.ModuleGlobalState === ModuleGlobalState.ClusterService) {
                    // ASK FOR
                    this.ConnectToCurrentCluster(dt);
                    return;
                  } else {
                    this.ConnectToExistingCluster(dt);
                    return;
                  }
                } else {
                  if (this.customerModule.ModuleGlobalState === ModuleGlobalState.Cluster) {
                    const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.NOTFOUND');
                    const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.INVENTORY.RECIPENOTFOUND');
                    const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
                      {panelClass: 'panelclass', data: {header: msg, content: content}});
                  } else {
                    this.CreateStandaloneCluster();
                    return;
                  }
                }
              } else {
                if (this.customerModule.ModuleGlobalState === ModuleGlobalState.Cluster) {
                  const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.NOTFOUND');
                  const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.INVENTORY.RECIPENOTFOUND');
                  const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
                    {panelClass: 'panelclass', data: {header: msg, content: content}});
                } else {
                  this.CreateStandaloneCluster();
                  return;
                }
              }

            } else if (this.customerModule.ModuleGlobalState === ModuleGlobalState.ClusterService) {
              this.CreateStandaloneCluster();
              return;
            } else {
              // CAN'T CONNECT TO CLUSTER
              const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.NOTFOUND');
              const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.INVENTORY.RECIPENOTFOUND');
              const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
                {panelClass: 'panelclass', data: {header: msg, content: content}});
            }
          });
        }
      }
    } else if (this.customerModule.ModuleGlobalState === ModuleGlobalState.Service) {
      this.CreateStandaloneCluster();
      return;
    }
  }

  public ConnectToCurrentCluster(recipe: any) {
    if (recipe) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data =
        this.translate.GetTranslation('MESSAGEBOX.CONTENT.INVENTORY.PARTOFCLUSTER');
      const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          // CONNECT TO EXISTING CLUSTER
          this.ConnectToExistingCluster(recipe);
        } else {
          this.CreateStandaloneCluster();
        }
      });
    }
  }

  public ConnectToExistingCluster(recipe: any) {

    this.moduleHandling.LoadRecipeFromInventoryInfo = {
      recipe: recipe,
      clusterid: this.customerModule.InventoryInfo.ClusterId,
      masterSerial: this.customerModule.InventoryInfo.MasterSerial,
      masterIp: this.customerModule.InventoryInfo.MasterIp
    };
    this.moduleService.RemoveConnectionInfo();
    this.router.navigate(['/live']);
  }

  public CreateStandaloneCluster() {
    if (this._standAloneInitOngoing === false) {
      this._standAloneInitOngoing = true;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data = this.customerModule;
      const dialogRef = this.dialog.open(StandaloneClusterInitComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        this._standAloneInitOngoing = false;
      });
    }
  }

  public StandaloneClusterInitialized(): boolean {
    if (this.moduleService.ServiceModule) {
      if (this.moduleService.StandaloneClusterInitialized === true) {
        if (this.moduleService.ServiceModule.SerialNumber === this.customerModule.SerialNumber) {
          return true;
        }
      }
    }
    return false;
  }

  public ShowServiceDetailView(): boolean {
    if (this.currentDemoState === 6) {
      return true;
    }
    if (this.StandaloneClusterInitialized()) {
      if (this.customerModule.Module.CurrentMode === ModeSwitch.SERVICE && this.customerModule.Module.EmergencyStopRequired === false) {
        return true;
      }
    }
    return false;
  }

  public ShowEmergencyStopRequired(): boolean {
    if (this.currentDemoState === 5) {
      return true;
    }
    if (this.StandaloneClusterInitialized()) {
      if (this.customerModule.Module.EmergencyStopRequired === true) {
        return true;
      }
    }
    return false;
  }

  public ShowEmergencyNotOk(): boolean {
    if (this.currentDemoState === 5) {
      return true;
    }
    if (this.StandaloneClusterInitialized()) {
      if (this.customerModule.Module.EmergencyStopRequired === false && this.customerModule?.Module.NetworkInfo?.emergencystopok == false) {
        return true;
      }
    }
    return false;
  }

  public IsStandaloneOnline(): boolean {
    if (this.currentDemoState >= 5) {
      return true;
    }
    if (this.moduleService.StandaloneClusterInitialized) {
      if (this.moduleService.StandaloneModulePlan.customerModule.SerialNumber === this.customerModule.SerialNumber) {
        return true;
      }
    }
    return false;
  }

}
