import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: '../../../../themes/basic/templates/views/layout/layout.component.html',
  styleUrls: ['../../../../themes/basic/templates/views/layout/layout.component.css']
})
export class LayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
