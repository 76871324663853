<section fxLayout="column" fxFlexAlign="stretch">
  <div class="header" fxLayout="row" fxLayoutAlign="stretch">
    <h2 fxFlexAlign="center" mat-dialog-title class="onboardingText">MODUL STATUS DEMO</h2>
    <div  fxFlex fxLayout fxLayoutAlign="end" fxFlexAlign="center">
      <button mat-button (click)="CloseDialog()">
        <img class="invertSVG" src="/themes/basic/assets/general/close.svg">
      </button>
    </div>
  </div>

  <div fxLayout="row" fxFlexAlign="stretch">
    <div fxLayout="column" class="elementColumn">
      <h3 fxFlexAlign="center" mat-dialog-title class="onboardingText">LIGHT STATE</h3>
      <div fxLayout="row">
        AKTUELL: {{this.currentState}}
      </div>

      <button class="selectButton" (click)="changeState(0)">
        0 - FEHLER
      </button>
      <button class="selectButton" (click)="changeState(1)">
        1 - GELB
      </button>
      <button class="selectButton" (click)="changeState(2)">
        2 -BEREIT
      </button>
      <button class="selectButton" (click)="changeState(3)">
        3 - GELB
      </button>
      <button class="selectButton" (click)="changeState(4)">
        4 - LÄUFT
      </button>
      <button class="selectButton" (click)="changeState(6)">
        6 - STARTET
      </button>
      <button class="selectButton" (click)="changeState(7)">
        7 - SERVICE
      </button>
      <button class="selectButton" (click)="changeState(8)">
        8 - SAFETY
      </button>
      <button class="selectButton" (click)="changeState(9)">
        9 - START
      </button>
      <button class="selectButton" (click)="changeState(10)">
        10 - STOP
      </button>

    </div>

    <div fxLayout="column" class="elementColumn">
      <h3 fxFlexAlign="center" mat-dialog-title class="onboardingText">SWITCH STATE</h3>
      <div fxLayout="row">
        AKTUELL: {{this.currentMode}}
      </div>

      <button class="selectButton" (click)="changeModeState('auto')">
        auto
      </button>
      <button class="selectButton" (click)="changeModeState('service')">
        service
      </button>


    </div>

    <div fxLayout="column" class="elementColumn">
      <h3 fxFlexAlign="center" mat-dialog-title class="onboardingText">EMERGENCY TEST</h3>
      <div fxLayout="row">
        AKTUELL: {{this.currentEmergencyState}}
      </div>

      <button class="selectButton" (click)="changeEmergencyTestRequired(true)">
        AN
      </button>
      <button class="selectButton" (click)="changeEmergencyTestRequired(false)">
        AUS
      </button>


    </div>

    <div fxLayout="column" class="elementColumn">
      <h3 fxFlexAlign="center" mat-dialog-title class="onboardingText">EMERGENCY OK TEST</h3>
      <div fxLayout="row">
        AKTUELL: {{this.currentEmergencyStateOk}}
      </div>

      <button class="selectButton" (click)="changeEmergencyStopOk(true)">
        FREIGEGEBEN
      </button>
      <button class="selectButton" (click)="changeEmergencyStopOk(false)">
        GEDRÜCKT
      </button>


    </div>

  </div>
  <div fxLayout="row" fxFlexAlign="stretch">
    <button class="selectButton" (click)="openModuleSettings()">
      Einstellungen öffnen
    </button>
  </div>

</section>
