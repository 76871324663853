<section fxLayout="row" *ngIf="ShowOnboarding && SelectedModule">
  <button class="closebutton" mat-button (click)="closeOnboarding()">
      <img src="/themes/basic/assets/general/close_blau.svg">
  </button>

  <div class="rightmenuribbon">
     <div fxLayout="row" class="menuItem" [ngClass]="{'selectedMenuItem': selectedTab === 0}" (click)="SwitchToTab(0)">
        <div fxLayout="row" *ngIf="!SelectedModule.customerModule.Initialized; else elseBlock">
          <img class="tabicon" *ngIf="selectedTab !== 0" src="/themes/basic/assets/general/connecting_black.svg">
          <img class="tabicon" *ngIf="selectedTab === 0" src="/themes/basic/assets/general/connecting.svg">
          <div class="tabdiv">
            <p class="tabtext">{{'COMPONENTS.MODULEONBOARDING.ONBOARDING' | i18next }}</p>
            <div *ngIf="selectedTab === 0" class="tabline"></div>
          </div>
        </div>
        <ng-template #elseBlock>
          <img class="tabicon" *ngIf="selectedTab !== 0" src="/themes/basic/assets/general/done.svg">
          <img class="tabicon" *ngIf="selectedTab === 0" src="/themes/basic/assets/general/done_selected.svg">
          <div class="tabdivM" fxLayout="column">
            <p class="tabtextM">{{'COMPONENTS.MODULEONBOARDING.ONBOARDING' | i18next }}</p>
            <p class="tabtextM">{{SelectedModule.modul.Group}} {{SelectedModule.modul.Name}}</p>
            <p class="tabtextM">{{SelectedModule.modul.Model}} {{SelectedModule.customerModule.SerialNumber}}</p>
            <div *ngIf="selectedTab === 0" class="tabline"></div>
          </div>
        </ng-template>
      </div>
    <div fxLayout="row" class="menuItem" [ngClass]="{'selectedMenuItem': selectedTab === 1}" (click)="SwitchToTab(1)">
      <img class="tabicon" *ngIf="selectedTab < 1" src="/themes/basic/assets/general/positioning.svg">
      <img class="tabicon" *ngIf="selectedTab === 1 && SelectedModule.modul.PositioningRequired" src="/themes/basic/assets/general/positioning_blue.svg">
      <img class="tabicon" *ngIf="!SelectedModule.modul.PositioningRequired && selectedTab > 1" src="/themes/basic/assets/general/done.svg">
      <img class="tabicon" *ngIf="!SelectedModule.modul.PositioningRequired && selectedTab === 1" src="/themes/basic/assets/general/done_selected.svg">
      <div class="tabdiv">
        <p class="tabtext">{{'COMPONENTS.MODULEONBOARDING.POSITIONING' | i18next }}</p>
        <div *ngIf="selectedTab === 1" class="tabline"></div>
      </div>
    </div>
    <div fxLayout="row" class="menuItem" [ngClass]="{'selectedMenuItem': selectedTab === 2}" (click)="SwitchToTab(2)">
      <img class="tabicon" *ngIf="selectedTab < 2" src="/themes/basic/assets/general/levelling.svg">
      <img class="tabicon" *ngIf="selectedTab === 2 && !LevelRequiredDone()" src="/themes/basic/assets/general/levelling_blau.svg">
      <img class="tabicon" *ngIf="LevelRequiredDone() && selectedTab > 2" src="/themes/basic/assets/general/done.svg">
      <img class="tabicon" *ngIf="LevelRequiredDone() && selectedTab === 2" src="/themes/basic/assets/general/done_selected.svg">
      <div class="tabdiv">
        <p class="tabtext">{{'COMPONENTS.MODULEONBOARDING.LEVELING' | i18next }}</p>
        <div *ngIf="selectedTab === 2" class="tabline"></div>
      </div>
    </div>

    <div fxLayout="row" class="menuItem" [ngClass]="{'selectedMenuItem': selectedTab === 3}" (click)="SwitchToTab(3)">
      <img class="tabicon" *ngIf="selectedTab < 3" src="/themes/basic/assets/general/spanner.svg">
      <img class="tabicon" *ngIf="selectedTab === 3 && !AllTasksDone()" src="/themes/basic/assets/general/spanner_blau.svg">
      <img class="tabicon" *ngIf="AllTasksDone() && selectedTab > 3" src="/themes/basic/assets/general/done.svg">
      <img class="tabicon" *ngIf="AllTasksDone() && selectedTab === 3" src="/themes/basic/assets/general/done_selected.svg">
      <div class="tabdiv">
        <p class="tabtext">{{'COMPONENTS.SETUPTASKLIST.TITLE' | i18next }}</p>
        <div *ngIf="selectedTab === 3" class="tabline"></div>
      </div>
    </div>

    <div *ngIf="isCurrentModuleLastModule()" fxLayout="row" class="menuItem" [ngClass]="{'selectedMenuItem': selectedTab === 4}" (click)="SwitchToTab(4)">
      <img class="tabicon" *ngIf="selectedTab < 4 && !handsoverDone" src="/themes/basic/assets/general/handsover.svg">
      <img class="tabicon" *ngIf="selectedTab === 4 && !handsoverDone" src="/themes/basic/assets/general/handsover_blau.svg">
      <img class="tabicon" *ngIf="handsoverDone && selectedTab > 4" src="/themes/basic/assets/general/done.svg">
      <img class="tabicon" *ngIf="handsoverDone && selectedTab === 4" src="/themes/basic/assets/general/done_selected.svg">
      <div class="tabdiv">
        <p class="tabtext">{{'COMPONENTS.MODULEONBOARDING.HANDSOVER' | i18next }}</p>
        <div *ngIf="selectedTab === 4" class="tabline"></div>
      </div>
    </div>

    <div *ngIf="isCurrentModuleLastModule()" fxLayout="row" class="menuItem" [ngClass]="{'selectedMenuItem': selectedTab === 5}" (click)="SwitchToTab(5)">
      <img class="tabicon" *ngIf="selectedTab !== 5 && !moduleHandling.ClusterInitialized" src="/themes/basic/assets/general/cluster.svg">
      <img class="tabicon" *ngIf="selectedTab === 5 && !moduleHandling.ClusterInitialized" src="/themes/basic/assets/general/cluster_blau.svg">
      <img class="tabicon" *ngIf="moduleHandling.ClusterInitialized && selectedTab > 5" src="/themes/basic/assets/general/done.svg">
      <img class="tabicon" *ngIf="moduleHandling.ClusterInitialized && selectedTab === 5" src="/themes/basic/assets/general/done_selected.svg">

      <div class="tabdiv">
        <p class="tabtext">{{'COMPONENTS.MODULEONBOARDING.CLUSTERONB' | i18next }}</p>
        <div *ngIf="selectedTab === 5" class="tabline"></div>
      </div>
    </div>
  </div>

  <div class="stepsViewer">
    <app-module-onboarding-tab *ngIf="selectedTab === 0 && SelectedModule" [SelectedModule]="SelectedModule" [ShowOnBoarding]="selectedTab === 0" (RequestClusterReinit)="OnClusterReinitRequist()" (CloseRequested)="CloseOnboarding.emit()"></app-module-onboarding-tab>
    <app-setup-task-list *ngIf="selectedTab === 3 && SelectedModule" [ActiveTasks]="SelectedModule.customerModule.ActiveTasks" [Module]="SelectedModule" (AllDone)="CallBackAllSetupTaskDone"></app-setup-task-list>
    <app-positioning-tab *ngIf="selectedTab == 1" [SelectedModule]="SelectedModule"></app-positioning-tab>
    <app-leveling-tab *ngIf="selectedTab==2" [SelectedModule]="SelectedModule" [ShowLevelingInput]="SubscribeLeveling()"></app-leveling-tab>
    <app-handsover-points-tab *ngIf="selectedTab===4" [onboardingComp]="this"></app-handsover-points-tab>
    <app-cluster-onboarding-tab *ngIf="selectedTab === 5 && SelectedModule" [ReinitRequested]="clusterReinitRequested" [ShowOnBoarding]="selectedTab === 5" (CloseRequested)="CloseOnboarding.emit()"></app-cluster-onboarding-tab>
  </div>

  <div class="continueDiv">
    <button class="continueButton" *ngIf="SwitchTabAllowed(selectedTab+1, true)" class="continueButton" mat-button (click)="ConfirmStep(selectedTab+1)">
      <p>{{'COMPONENTS.MODULEONBOARDING.CONFIRM' | i18next }}</p>
    </button>
  </div>

</section>
