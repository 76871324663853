import {ModuleComponent} from './module-component';
import {ConveyorBeltInfo} from './conveyor-belt-info';
import {NetworkDevice} from './messaging/network-device';
import {ModeSwitch} from './enums/mode-switch.enum';
import {EventEmitter} from '@angular/core';
import {ModuleConnections} from './enums/module-connections.enum';
import {ServerCommunicationService} from '../services/server-communication.service';
import {Conveyor} from './conveyor';
import {Guid} from 'guid-typescript';
import {ModuleRule} from './module-rule';
import {ModuleHandlingService} from '../services/module-handling.service';
import {ViewCode} from './enums/view-code.enum';
import {SetupTask} from './setup-task';

export class ModuleDefinition {

  public Group: string;
  public Line: string;
  public Name: string;
  public Type: string;
  public Version: string;
  public Key: string;
  public Model: string;
  public Manufacturer: string;
  public Components: ModuleComponent[];
  public ConveyorBelts: ConveyorBeltInfo[];
  public ModuleRules: ModuleRule[];
  public SupportModule = false;
  public MaximumConnections = 0;
  public AirExtractionPossible = false;
  public SetupTasks: SetupTask[];



  public PathIconSymboleBlack: string;
  public PathIconSymboleWhite: string;
  public PathDetailImage: string;
  public PathComponents: string;
  public IsInitialModuleOnly = false;
  NetworkInfo: NetworkDevice;
  Connected: boolean;
  HaveConfiguration = false;
  ConfigurationChanged = false;
  public EmergencyStopRequired = false;
  public ServiceTaskRequired = true;
  public PositioningRequired = true;
  public LevelingRequired = true;
  CurrentMode: ModeSwitch = ModeSwitch.AUTOMATIC;
  public OnNetworkInfoUpdated = new EventEmitter();

  public OnSwitchModeChanged = new EventEmitter<ModeSwitch>();
  public UniqueId: string;

  public LastServiceConfiguration: any;
  private moduleHandling: ModuleHandlingService;
  public RuleConfirmationRequired = new EventEmitter<any>();
  public OnResetBeltView = new EventEmitter<any>();

  constructor(group: string, line: string, name: string, type: string, version: string, key: string) {

    this.Group = group;
    this.Name = name;
    this.Line = line;
    this.Type = type;
    this.Version = version;
    this.Key = key;
    this.Components = [];
    this.ConveyorBelts = [];
    this.ModuleRules = [];
    this.PathDetailImage = 'themes/basic/assets/ModulDetail/' + this.Type + '_' + this.Version + '.png';
    this.PathIconSymboleBlack = 'themes/basic/assets/ModuleIcons/' + this.Type + '_' + this.Version + '_b.svg';
    this.PathIconSymboleWhite = 'themes/basic/assets/ModuleIcons/' + this.Type + '_' + this.Version + '_w.svg';
    this.PathComponents = 'themes/basic/assets/ModuleComponents/' + this.Type + '_' + this.Version + '.svg';
    this.SetupTasks = [];
  }

  public Copy(): ModuleDefinition {
    const md = new ModuleDefinition(this.Group, this.Line, this.Name, this.Type, this.Version, this.Key);
    md.Connected = this.Connected;
    md.HaveConfiguration = this.HaveConfiguration;
    md.ConfigurationChanged = this.ConfigurationChanged;
    md.NetworkInfo = this.NetworkInfo;
    md.EmergencyStopRequired = this.EmergencyStopRequired;
    md.ServiceTaskRequired = this.ServiceTaskRequired;
    md.PositioningRequired = this.PositioningRequired;
    md.LevelingRequired = this.LevelingRequired;
    md.CurrentMode = this.CurrentMode;
    md.ModuleRules = this.ModuleRules;
    md.IsInitialModuleOnly = this.IsInitialModuleOnly;
    md.SetModuleHandling(this.moduleHandling);
    md.Manufacturer = this.Manufacturer;
    md.Model = this.Model;
    md.SetupTasks = this.SetupTasks;
    md.LastServiceConfiguration = this.LastServiceConfiguration;
    md.UniqueId = this.UniqueId;
    md.SupportModule = this.SupportModule;
    md.AirExtractionPossible = this.AirExtractionPossible;
    md.MaximumConnections = this.MaximumConnections;
    for (const comp of this.Components) {
      md.AddComponent(comp.Copy());
    }

    for (const blt of this.ConveyorBelts) {
      md.ConveyorBelts.push(blt.Copy());
    }

    return md;
  }

  public SetModuleHandling(moduleHandling: ModuleHandlingService) {
    this.moduleHandling = moduleHandling;
  }

  public GetModuleHandling() {
    return this.moduleHandling;
  }

  public AddComponent(component: ModuleComponent) {
    this.Components.push(component);
    component.ComponentVariableChanged.subscribe(this.ComponentVariableChanged.bind(this));
    component.ComponentMaintenanceVariableChanged.subscribe(this.ComponentMaintenanceVariableChanged.bind(this));
  }

  public CheckAllRules() {

    let view = 'Design';

    if (this.moduleHandling) {
      switch (this.moduleHandling.CurrentViewMode) {
        case ViewCode.design:
          view = 'Design';
          break;
        case ViewCode.live:
          view = 'Live';
          break;
      }

      // Get all relevant rules:
      const rules = this.ModuleRules.filter(ex => ex.Mode === view || ex.Mode === 'Both');
      for (const rule of rules) {
        if (rule.TriggerComponent) {
          const comp = this.Components.find(ex => ex.PlcKey === rule.TriggerComponent);
          if (comp && rule.TriggerParameter) {
            if (rule.ActionName !== 'SetDefaultValue') {
              const param = comp.Configs.find(ex => ex.Name === rule.TriggerParameter);
              if (param) {
                param.Deactivated = [];
                param.Excluded = false;

                const newData = {
                  component: comp.PlcKey,
                  variable: param.Name,
                  value: param.CurrentValue
                };
                this.ComponentVariableChanged(newData);
              }
            }
          }
        }
      }

    }
  }

  public ComponentVariableChanged(data: any) {
    if (data.component) {
      // Rule exists?
      const rules = this.ModuleRules.filter(ex => ex.TriggerComponent === data.component);

      for (const rule of rules) {
        this.HandleRule(data, rule);
      }
    }
  }

  public ComponentMaintenanceVariableChanged(data: any) {
    if (data.component) {
      // Rule exists?
      const rules = this.ModuleRules.filter(ex => ex.TriggerComponent === data.component);

      for (const rule of rules.filter(ex => ex.RuleName.toUpperCase().indexOf('SERVICE') >= 0)) {
        this.HandleRule(data, rule);
      }
    }
  }

  public HandleRule(data: any, rule: ModuleRule) {
    if (rule) {
      let inActiveView = false;
      if (this.moduleHandling) {
        if (rule.Mode === 'Design' && this.moduleHandling.CurrentViewMode === ViewCode.design) {
          inActiveView = true;
        } else if (rule.Mode === 'Live' && this.moduleHandling.CurrentViewMode === ViewCode.live) {
          inActiveView = true;
        } else if (rule.Mode === 'Both') {
          inActiveView = true;
        }
      }

      if (inActiveView) {
        // SHOULD RUN RULE
        // CHECK TRIGGER

        if (rule.TriggerParameter) {

          if (data.variable === rule.TriggerParameter) {
            if (rule.TriggerValue) {
              if (rule.TriggerValue.toString().toUpperCase() === data.value.toString().toUpperCase()) {
                if (!rule.ActionName) {
                  if (rule.TargetValue && rule.TargetParameter && rule.TargetComponent) {
                    // GET TARGET COMP
                    const comp = this.Components.find(ex => ex.PlcKey === rule.TargetComponent);

                    if (comp) {
                      // GET TARGET PARAM
                      const param = comp.Configs.find(ex => ex.Name === rule.TargetParameter);

                      if (param) {

                        if (param.CurrentValue.toString() !== rule.TargetValue.toString()) {
                          if (rule.Silent === true) {
                            try {
                              const numtar = Number(rule.TargetValue);

                              if (!isNaN(numtar)) {
                                param.SetValue(numtar);
                              } else {
                                param.SetValue(rule.TargetValue);
                              }
                            } catch {
                              param.SetValue(rule.TargetValue);
                            }
                          } else {
                            const fromComponent = this.Components.find(ex => ex.PlcKey === data.component);
                            if (fromComponent) {
                              // Get Paramfrom
                              const fromParam = fromComponent.Configs.find(ex => ex.Name === data.variable);

                              if (fromParam) {
                                const TriggerCompName = fromComponent.GetTranslationId();
                                const TriggerVarName = fromParam.TranslationId;
                                let TriggerValName = data.value;

                                if (fromParam.States.length > 0) {
                                  const state = fromParam.States.find(ex => ex.State.toString() === data.value.toString());
                                  if (state) {
                                    const stTrans = this.moduleHandling.translate.GetTranslation(state.GetTranslationID());
                                    if (stTrans && stTrans !== state.GetTranslationID()) {
                                      TriggerValName = stTrans;
                                    } else {
                                      TriggerValName = state.DefaultText;
                                    }


                                  }
                                }

                                const TargetCompName = comp.GetTranslationId();
                                const TargetVarName = param.TranslationId;
                                let TargetValName = rule.TargetValue;

                                if (param.States.length > 0) {
                                  const state = param.States.find(ex => ex.State.toString() === rule.TargetValue.toString());
                                  if (state) {
                                    const stTrans = this.moduleHandling.translate.GetTranslation(state.GetTranslationID());
                                    if (stTrans && stTrans !== state.GetTranslationID()) {
                                      TargetValName = stTrans;
                                    } else {
                                      TargetValName = state.DefaultText;
                                    }
                                  }
                                }

                                const datas = {
                                  rule: rule,
                                  parameter: param,
                                  changed: data,
                                  valueToSet: rule.TargetValue,
                                  TriggerComponent: TriggerCompName,
                                  TriggerParameter: TriggerVarName,
                                  TriggerValue: TriggerValName,
                                  TargetComponent: TargetCompName,
                                  TargetParameter: TargetVarName,
                                  TargetValue: TargetValName
                                };
                                this.RuleConfirmationRequired.emit(datas);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                } else {
                  // WE HAVE A RULE NAME
                  this.HandleRuleName(data, rule);
                }
              }
            }
          }
        }
      }
    }
  }

  private HandleRuleName(data: any, rule: ModuleRule) {
    switch (rule.ActionName) {
      case 'ResetBeltView':
        if (rule.TargetComponent) {
          this.ResetBeltView(rule.TargetComponent, rule);
        }
        break;
      case 'DeactivateParameter':
        if (rule.TargetComponent && rule.TargetParameter) {
          this.DeactivateParameter(rule);

        }
        break;
      case 'ReactivateParameter':
        if (rule.TargetComponent && rule.TargetParameter) {
          this.ReactivateParameter(rule);

        }
        break;
      case 'ExcludeParameter':
        if (rule.TargetComponent && rule.TargetParameter) {
          this.ExcludeParameter(rule);
        }
        break;
      case 'IncludeParameter':
        if (rule.TargetComponent && rule.TargetParameter) {
          this.IncludeParameter(rule);
        }
        break;
      // case 'SetMaxValue':
      //   if (rule.TargetComponent && rule.TargetParameter && rule.TargetValue) {
      //     this.SetMaxValue(rule.TargetComponent, rule);
      //   }
      //   break;
      // case 'SetMinValue':
      //   if (rule.TargetComponent && rule.TargetParameter && rule.TargetValue) {
      //     this.SetMinValue(rule.TargetComponent, rule);
      //   }
      //   break;
      // case 'SetDefaultValue':
      //   if (rule.TargetComponent && rule.TargetParameter && rule.TargetValue) {
      //     this.SetDefaultValue(rule.TargetComponent, rule);
      //   }
      //   break;
      case 'SetBoundaryValue':
        if (rule.TargetComponent && rule.TargetParameter && rule.TargetValue) {
          this.SetBoundaryValue(rule.TargetComponent, rule);
        }
        break;
      case 'SetBoundaryValueService':
        if (rule.TargetComponent && rule.TargetParameter && rule.TargetValue) {
          this.SetBoundaryValueService(rule.TargetComponent, rule);
        }
        break;

    }
  }

  public ReactivateParameter(rule: ModuleRule) {
    // FIND COMPONENT
    const comp = this.Components.find(ex => ex.PlcKey === rule.TargetComponent);
    if (comp) {
      // FIND PARAMETER
      const param = comp.Configs.find(ex => ex.Name === rule.TargetParameter);
      if (param) {
        switch (rule.Mode) {
          case 'Live':
            if (param.Deactivated.find(ex => ex === ViewCode.live)) {
              param.Deactivated = param.Deactivated.filter(ex => ex !== ViewCode.live);

            }

            break;
          case 'Design':
            if (param.Deactivated.find(ex => ex === ViewCode.design)) {
              param.Deactivated = param.Deactivated.filter(ex => ex !== ViewCode.design);
            }
            break;
          case 'Both':
            if (param.Deactivated.find(ex => ex === ViewCode.design)) {
              param.Deactivated = param.Deactivated.filter(ex => ex !== ViewCode.design);
            }
            if (param.Deactivated.find(ex => ex === ViewCode.live)) {
              param.Deactivated = param.Deactivated.filter(ex => ex !== ViewCode.live);
            }
            break;
        }
      }
    }
  }

  public DeactivateParameter(rule: ModuleRule) {
    // FIND COMPONENT

    const comp = this.Components.find(ex => ex.PlcKey === rule.TargetComponent);
    if (comp) {
      // FIND PARAMETER
      const param = comp.Configs.find(ex => ex.Name === rule.TargetParameter);
      if (param) {
        switch (rule.Mode) {
          case 'Live':
            if (!param.Deactivated.find(ex => ex === ViewCode.live)) {
              param.Deactivated.push(ViewCode.live);
            }
            break;
          case 'Design':
            if (!param.Deactivated.find(ex => ex === ViewCode.design)) {
              param.Deactivated.push(ViewCode.design);
            }
            break;
          case 'Both':
            if (!param.Deactivated.find(ex => ex === ViewCode.design)) {
              param.Deactivated.push(ViewCode.design);
            }
            if (!param.Deactivated.find(ex => ex === ViewCode.live)) {
              param.Deactivated.push(ViewCode.live);
            }
            break;
        }
      }
    }
  }

  public ExcludeParameter(rule: ModuleRule) {
    // FIND COMPONENT

    const comp = this.Components.find(ex => ex.PlcKey === rule.TargetComponent);
    if (comp) {
      // FIND PARAMETER
      const param = comp.Configs.find(ex => ex.Name === rule.TargetParameter);
      if (param) {
        switch (rule.Mode) {
          case 'Live':
            if (!param.Deactivated.find(ex => ex === ViewCode.live)) {
              param.Excluded = true;

            }

            break;
          case 'Design':
            if (!param.Deactivated.find(ex => ex === ViewCode.live)) {
              param.Excluded = true;
            }
            break;
          case 'Both':
            if (!param.Deactivated.find(ex => ex === ViewCode.design)) {
              param.Excluded = true;
            }
            if (!param.Deactivated.find(ex => ex === ViewCode.live)) {
              param.Excluded = true;
            }
            break;
        }
      }
    }
  }

  public IncludeParameter(rule: ModuleRule) {
    // FIND COMPONENT

    const comp = this.Components.find(ex => ex.PlcKey === rule.TargetComponent);
    if (comp) {
      // FIND PARAMETER
      const param = comp.Configs.find(ex => ex.Name === rule.TargetParameter);
      if (param) {

        if (param.Excluded === true) {

          switch (rule.Mode) {
            case 'Live':
              if (!param.Deactivated.find(ex => ex === ViewCode.live)) {
                param.Excluded = false;

              }

              break;
            case 'Design':
              if (!param.Deactivated.find(ex => ex === ViewCode.live)) {
                param.Excluded = false;
              }
              break;
            case 'Both':
                param.Excluded = false;
              break;
          }
        }
      }
    }
  }

  public ResetBeltView(component: string, rule: ModuleRule) {
    // Get Effected Connections
    if (component) {
      const comp = this.Components.find(ex => ex.PlcKey === component);
      if (comp) {
        // HAS TO BE HANDLED BY MODULE PLAN
        this.OnResetBeltView.emit(comp);
      }
    }
  }

  public SetBoundaryValue(component: string, rule: ModuleRule) {
    if (component) {
      const comp = this.Components.find(ex => ex.PlcKey === component);
      if (comp) {
        // HAS TO BE HANDLED BY MODULE PLAN
        const param = comp.Configs.find(ex => ex.Name === rule.TargetParameter);

        if (param) {

          // GET PARAMS

          const splitted = rule.TargetValue.split(';');
          if (splitted.length >= 2) {

            if (param.ShowPositiveOnly) {
              let mn = Number(splitted[0]);
              let mx = Number(splitted[1]);

              if (mx < 0) {
                param.RecipeDirection = -1;
                mn = mn * param.RecipeDirection;
                mx = mx * param.RecipeDirection;
              } else {
                param.RecipeDirection = 1;
              }

              if (mx < mn) {
                param.MinValue = mx;
                param.MaxValue = mn;
              } else {
                param.MinValue = mn;
                param.MaxValue = mx;
              }

            } else {
              param.MinValue = Number(splitted[0]);
              param.MaxValue = Number(splitted[1]);
            }



            const oldCurren = param.CurrentValue;
            if (splitted.length > 2) {
              param.DefaultValue = (Number(splitted[2]) * param.RecipeDirection);

              if (oldCurren < 0 && Number(splitted[2]) < 0) {
                param.SetValue(oldCurren);
              }
              if (oldCurren > 0 && Number(splitted[2]) > 0) {
                param.SetValue(oldCurren);
              }

            }
          }
        }
      }
    }
  }

  public SetBoundaryValueService(component: string, rule: ModuleRule) {
    if (component) {
      const comp = this.Components.find(ex => ex.PlcKey === component);
      if (comp) {
        // HAS TO BE HANDLED BY MODULE PLAN
        const param = comp.Maintenance.find(ex => ex.Name === rule.TargetParameter);

        if (param) {

          // GET PARAMS

          const splitted = rule.TargetValue.split(';');
          if (splitted.length >= 2) {
            if (param.ShowPositiveOnly) {
              let mn = Number(splitted[0]);
              let mx = Number(splitted[1]);

              if (mx < 0) {
                param.RecipeDirection = -1;
                mn = mn * param.RecipeDirection;
                mx = mx * param.RecipeDirection;
              } else {
                param.RecipeDirection = 1;
              }

              if (mx < mn) {
                param.MinValue = mx;
                param.MaxValue = mn;
              } else {
                param.MinValue = mn;
                param.MaxValue = mx;
              }

            } else {
              param.MinValue = Number(splitted[0]);
              param.MaxValue = Number(splitted[1]);
            }

            const oldCurren = param.CurrentValue;
            if (splitted.length > 2) {
              param.DefaultValue = (Number(splitted[2]) * param.RecipeDirection);

              if (oldCurren < 0 && Number(splitted[2]) < 0) {
                param.SetValue(oldCurren);
              }
              if (oldCurren > 0 && Number(splitted[2]) > 0) {
                param.SetValue(oldCurren);
              }
            }
          }
        }
      }
    }
  }

  public SetMinValue(component: string, rule: ModuleRule) {
    if (component) {
      const comp = this.Components.find(ex => ex.PlcKey === component);
      if (comp) {
        // HAS TO BE HANDLED BY MODULE PLAN
        const param = comp.Configs.find(ex => ex.Name === rule.TargetParameter);

        if (param) {
          param.MinValue = Number(rule.TargetValue);
        }
      }
    }
  }

  public SetMaxValue(component: string, rule: ModuleRule) {
    if (component) {
      const comp = this.Components.find(ex => ex.PlcKey === component);
      if (comp) {
        // HAS TO BE HANDLED BY MODULE PLAN
        const param = comp.Configs.find(ex => ex.Name === rule.TargetParameter);

        if (param) {
          param.MaxValue = Number(rule.TargetValue);
        }
      }
    }
  }

  public SetDefaultValue(component: string, rule: ModuleRule) {
    if (component) {
      const comp = this.Components.find(ex => ex.PlcKey === component);
      if (comp) {
        // HAS TO BE HANDLED BY MODULE PLAN
        const param = comp.Configs.find(ex => ex.Name === rule.TargetParameter);

        if (param) {
          const oldCurren = param.CurrentValue.clone();
          param.DefaultValue = Number(rule.TargetValue);

          if (oldCurren < 0 && Number(rule.TargetValue) < 0) {
            param.SetValue(oldCurren);
          }
          if (oldCurren > 0 && Number(rule.TargetValue) > 0) {
            param.SetValue(oldCurren);
          }





            // param.SetValue(Number(rule.TargetValue));
        }
      }
    }
  }

  public SetId() {
    this.UniqueId = Guid.create().toString();
  }

  public setOffline() {
    this.Connected = false;
    this.CurrentMode = ModeSwitch.NONE;
  }

  public EnableServiceMode() {
    // TODO: Check if its allowed with an service
    if (this.CurrentMode !== ModeSwitch.SERVICE) {
      this.CurrentMode = ModeSwitch.SERVICE;
      this.OnSwitchModeChanged.emit(this.CurrentMode);
    }
  }

  public DisableServiceMode() {
    // TODO: Check if any device is currently running
    if (this.CurrentMode !== ModeSwitch.AUTOMATIC) {
      this.CurrentMode = ModeSwitch.AUTOMATIC;
      this.LastServiceConfiguration = null;
      this.OnSwitchModeChanged.emit(this.CurrentMode);
    }
  }

  public NetworkInfoChanged() {
    this.OnNetworkInfoUpdated.emit();
  }

  public AddNetworkInfo(networkInfo: NetworkDevice) {
    this.NetworkInfo = networkInfo;
    this.OnNetworkInfoUpdated.emit();
  }

  public GetAllowedConveyors(connectionPoint: ModuleConnections, server: ServerCommunicationService): Conveyor[] {
    const con: Conveyor[] = [];
    const convAv = this.ConveyorBelts.find(ex => ex.ConnectionPoint === connectionPoint);

    if (convAv) {
      for (const type of convAv.AllowedConveyorBeltTypes) {
        const cv  = server.GetConveyorByType(type);
        if (cv !== null && cv !== undefined) {
          const conv = cv.Copy();
          cv.SetId(null);
          con.push(conv);
        }
      }
    }
    return con;
  }

}
