import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-rule-notfication-dialog',
  templateUrl: '../../../../themes/basic/templates/dialogs/rule-notfication-dialog/rule-notfication-dialog.component.html',
  styleUrls: ['../../../../themes/basic/templates/dialogs/rule-notfication-dialog/rule-notfication-dialog.component.css']
})
export class RuleNotficationDialogComponent implements OnInit {

  constructor(public DialogRef: MatDialogRef<RuleNotficationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  public Change() {
    this.DialogRef.close(true);
  }

  public Cancel() {
    this.DialogRef.close(false);
  }

}
