import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ClusterOnboardingComponent} from '../../dialogs/cluster-onboarding/cluster-onboarding.component';
import {DemoModuleStateComponent} from '../../dialogs/demo-module-state/demo-module-state.component';

@Component({
  selector: 'app-dialog-demo-menu',
  templateUrl: '../../../../themes/basic/templates/elements/dialog-demo-menu/dialog-demo-menu.component.html',
  styleUrls: ['../../../../themes/basic/templates/elements/dialog-demo-menu/dialog-demo-menu.component.css']
})
export class DialogDemoMenuComponent implements OnInit {


  public ShowBar = false;
  @Output() OpenModuleSettings = new EventEmitter();
  @Output() OpenModuleOnboarding = new EventEmitter();

  constructor(public moduleHandling: ModuleHandlingService,
              public dialog: MatDialog
              ) { }

  ngOnInit(): void {
    const location = window.location.href;

    if (location.indexOf('localhost') >= 0) {
      this.ShowBar = true;
    }
    if (location.indexOf('theme') >= 0) {
      this.ShowBar = true;
    }
  }

  public OpenSerialSelection() {
    this.moduleHandling.DemoView = true;
    this.OpenModuleOnboarding.emit();
  }

  public OpenInitCluster() {
    this.moduleHandling.DemoView = true;

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'loginDialogGray';
    dialogConfig.data = null;

    const dialogRef = this.dialog.open(ClusterOnboardingComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.moduleHandling.DemoView = false;
    });
  }

  public openModuleSettings() {
    this.moduleHandling.DemoView = true;
    this.OpenModuleSettings.emit();
  }

  public openModuleState() {
    if (this.moduleHandling.ActiveModule) {

      this.moduleHandling.DemoView = true;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'settingControl';
      dialogConfig.data = null;

      const dialogRef = this.dialog.open(DemoModuleStateComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        this.moduleHandling.setClusterInitialized(false);
        this.moduleHandling.setClusterConnected(false);
        this.moduleHandling.DemoView = false;
        this.moduleHandling.ActiveModule.leaveLiveMode();
      });
    }
  }

  public openSupportModuleState() {
    if (this.moduleHandling.ActiveModule) {

      const support = this.moduleHandling.getSupportModuleForModulePlan(this.moduleHandling.ActiveModule);
      if (support) {
        this.moduleHandling.DemoView = true;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'settingControl';
        dialogConfig.data = 'support';

        const dialogRef = this.dialog.open(DemoModuleStateComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(data => {
          this.moduleHandling.setClusterInitialized(false);
          this.moduleHandling.setClusterConnected(false);
          this.moduleHandling.DemoView = false;
          support.leaveLiveMode();
        });
      }
      }


  }

}
