import {EventEmitter, Component, Input, OnInit, Output, AfterViewInit} from '@angular/core';
import {ModulePlan} from '../../../classes/module-plan';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SetupTask} from '../../../classes/setup-task';
import {ConfirmMessageBoxComponent} from '../../dialogs/confirm-message-box/confirm-message-box.component';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';
import {QuestionBoxComponent} from '../../dialogs/question-box/question-box.component';
import {ModuleOnboardingService} from '../../../services/v2/module-onboarding.service';
import {MessageHandlingService} from '../../../services/v2/message-handling.service';
import * as uuidV4 from 'uuid/v4';
import {ClusterOnboardingService} from '../../../services/v2/cluster-onboarding.service';

@Component({
  selector: 'app-module-onboarding',
  templateUrl: '../../../../themes/basic/templates/views/module-onboarding/module-onboarding.component.html',
  styleUrls: ['../../../../themes/basic/templates/views/module-onboarding/module-onboarding.component.css']
})
export class ModuleOnboardingComponent implements OnInit, AfterViewInit {

  @Input() set SelectedModulePlan(value: ModulePlan) {
    this.SelectedModule = value;
    this.handsoverDone = false;
    if (!this.SelectedModule.customerModule.ActiveTasks) {
      this.SelectedModule.customerModule.ActiveTasks = [];
    }
    for (const tsk of this.SelectedModule.modul.SetupTasks.filter(ex => ex.DefaultTask === false)) {
      const existingTask = this.SelectedModule.customerModule.ActiveTasks.find(ex => ex.Code === tsk.Code);
      if (!existingTask) {
        this.SelectedModule.customerModule.ActiveTasks.push(tsk.Clone());
      }
    }
    if (this.isCurrentModuleLastModule()) {
      for (const tsk of this.SelectedModule.modul.SetupTasks.filter(ex => ex.DefaultTask === true)) {
        const existingTask = this.SelectedModule.customerModule.ActiveTasks.find(ex => ex.Code === tsk.Code);
        if (!existingTask) {
          this.SelectedModule.customerModule.ActiveTasks.push(tsk.Clone());
        }
      }
    }
  }



  @Input() ShowOnboarding = false;
  @Output() CloseOnboarding = new EventEmitter();

  get SelectedModulePlan(): ModulePlan {
    return this.SelectedModule;
  }

  public SelectedModule: ModulePlan;
  public selectedTab = -1;
  public clusterReinitRequested = false;
  public handsoverDone = false;

  constructor(public server: ServerCommunicationService,
              public moduleHandling: ModuleHandlingService,
              private moduleOnboarding: ModuleOnboardingService,
              private clusterOnboarding: ClusterOnboardingService,
              private messageHandling: MessageHandlingService,
              public translate: BluectrlTranslateService,
              public dialog: MatDialog
              ) {
    this.moduleOnboarding.ModuleOnboardingReinitRequest.subscribe(this.onModuleOnboardingReinitRequested.bind(this));

  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    // CHECK TABS
    this.clusterReinitRequested = false;
    // CHECK MODULE PLAN
    if (this.SelectedModule.customerModule.Initialized) {
      if (!this.SelectedModule.modul.NetworkInfo) {
        // WE LOST CONNECTION
        this.RemoveInitInfo();
      }
    }
    this.selectedTab = this.GetInitTab(0);
    this.handsoverDone = false;
  }

  private onModuleOnboardingReinitRequested() {
    if (this.SelectedModule) {
      if (!this.SelectedModule.customerModule.FullyConfigured && this.SelectedModule.customerModule.Initialized) {
        const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.MODULECONNTECTIONLOST');
        const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.MODULECONNTECTIONLOST');
        const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
          {panelClass: 'panelclass', data: {header: msg, content: content}});

        dialogRef.afterClosed().subscribe(() => {
          this.RemoveInitInfo();
          if (this.SelectedModule) {
            this.SelectedModule.RemoveOnboardingInfo();
          }
          this.selectedTab = 0;
        });
      }
    }
  }

  public RemoveInitInfo() {
    this.SelectedModule.customerModule.Initialized = false;
    this.SelectedModule.modul.ServiceTaskRequired = false;
    this.SelectedModule.modul.PositioningRequired = true;
    this.SelectedModule.customerModule.FullyConfigured = false;
    this.SelectedModule.ConfigFinishedSended = false;
  }

  public GetInitTab(index: number) {
    if (index === 0) {
      if (this.SelectedModule.customerModule.Initialized) {
        return this.GetInitTab(index + 1);
      } else {
        return index;
      }
    } else if (index === 1) {
      if (!this.SelectedModule.modul.PositioningRequired) {
        return this.GetInitTab(index + 1);
      } else {
        return index;
      }
    } else if (index === 2) {
      if (!this.SelectedModule.modul.NetworkInfo) {
        if (!this.SelectedModule.modul.LevelingRequired) {
          return this.GetInitTab(index + 1);
        } else {
          return index;
        }
      } else {
        return 0;
      }
    } else {
      return index;
    }
  }

  public SwitchTabAllowed(index: number, justCheck: boolean = false): boolean {
    if (!this.moduleHandling.DemoView) {

      if ((this.selectedTab === 1 || this.selectedTab === 3 || this.selectedTab === 4) && justCheck === true) {
        return true;
      }

      if (this.selectedTab === 5) {
        if (this.SelectedModule.customerModule.FullyConfigured) {
          return false;
        }
      }

      if (this.selectedTab === 0) {
        if (!this.SelectedModule.customerModule.Initialized) {
          return false;
        }
      }


      if (this.selectedTab === 1) {
        if (!this.SelectedModule.customerModule.Initialized) {
          return false;
        }
        if (this.SelectedModule.modul.PositioningRequired) {
          return false;
        }
      }

      if (this.selectedTab === 2) {
        if (!this.SelectedModule.customerModule.Initialized) {
          return false;
        }
        if (this.SelectedModule.modul.PositioningRequired) {
          return false;
        }

        if (!this.SelectedModule.modul.NetworkInfo) {
          return false;

        }



      }


      if (index !== 5 && this.clusterOnboarding.OnboardingRunning) {
        return false;
      }



      if (index === 3 && !justCheck) {
        if (this.SelectedModule.customerModule.ActiveTasks.length <= 0) {
          if (!this.isCurrentModuleLastModule()) {
            this.closeOnboarding();
            return true;
          }
        }
      } else if (index == 3) {
        return this.LevelRequiredDone();
      }

      if (index === 4 ) {
        if (this.SelectedModulePlan.checkIfConfigFinished()) {
          if (!this.SelectedModulePlan.ConfigFinishedSended) {
            this.messageHandling.sendModuleInitFinished(uuidV4(), this.SelectedModule.customerModule.SerialNumber);
            this.SelectedModulePlan.ConfigFinishedSended = true;
            if (this.moduleHandling.sequenceNumberToOnboard === this.SelectedModulePlan.seqno) {
              this.moduleHandling.sequenceNumberToOnboard++;
            }
          }
        }

        if (justCheck) {
          if (this.SelectedModule.customerModule.ActiveTasks.filter(ex => ex.Done !== true).length <= 0) {
            return true;
          }
        } else {

            if (!this.isCurrentModuleLastModule()) {
              this.closeOnboarding();
              return true;
            }

        }





        if (this.moduleHandling.CheckIfModuleInitFinished()) {
          return true;
        } else {
          return false;
        }
      }

      if (index === 5 ) {

        if (!this.isCurrentModuleLastModule() && !justCheck) {
          this.closeOnboarding();
        }

        // CHECK IF FINISHED
        if (!this.handsoverDone) {
          return false;
        }

        if (this.moduleHandling.CheckIfModuleInitFinished() && !this.moduleHandling.ClusterInitialized) {
          return true;
        } else {
          // MESSAGE BOX
          const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.NOTREADY');
          const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.NOTREADY');
          this.dialog.open(ConfirmMessageBoxComponent,
            {panelClass: 'panelclass', data: {header: msg, content: content}});

          return false;
        }
      }
    }
    return true;
  }

  public ConfirmStep(index: number) {

    if (this.selectedTab === 1) {
      // POSITIONING
      if (this.SelectedModule) {
        this.SelectedModule.modul.PositioningRequired = false;
      }

    } else if (this.selectedTab === 3) {
      for (const t of this.SelectedModule.customerModule.ActiveTasks) {
        t.Done = true;
      }
    } else if (this.selectedTab === 4) {
      this.handsoverDone = true;
    }



    this.SwitchToTab(index);
  }

  public SwitchToTab(index: number) {

      if (this.SwitchTabAllowed(index)) {
        this.selectedTab = index;
      }

  }

  public GetTaskText(task: SetupTask): string {
    if (task.Surfix || task.Prefix) {
      let surfix = '';
      let prefix = '';

      if (task.Surfix) {
        if (task.Surfix.component && task.Surfix.parameter) {
          const component = this.SelectedModule.modul.Components.find(ex => ex.PlcKey === task.Surfix.component);
          if (component) {
            const param = component.Configs.find(ex => ex.Name === task.Surfix.parameter);
            if (param) {
              const val = param.getValue();
              if (param.States.length > 0) {
                const state = param.States.find(ex => ex.State === val);
                if (state) {
                  surfix = state.GetName();
                }

              }
            }
          }
        }
      }

      if (task.Prefix) {
        if (task.Prefix.component && task.Prefix.parameter) {
          const component = this.SelectedModule.modul.Components.find(ex => ex.PlcKey === task.Prefix.component);
          if (component) {
            const param = component.Configs.find(ex => ex.Name === task.Prefix.parameter);
            if (param) {
              const val = param.getValue();
              if (param.States.length > 0) {
                const state = param.States.find(ex => ex.State === val);
                if (state) {
                  prefix = state.GetName();
                }

              }
            }
          }
        }
      }

      return prefix + ' ' + task.Text + ' ' + surfix;

    } else {
      return task.Text;
    }
  }

  public isCurrentModuleLastModule(): boolean {
    if (this.clusterReinitRequested === true) {
      return true;
    }

    let isLastModule = true;

    for (const mdl of this.moduleHandling.ActiveProject.Modules) {
      if (mdl.customerModule.CustomerModuleId !== this.SelectedModule.customerModule.CustomerModuleId) {
        if (!mdl.customerModule.FullyConfigured) {
          isLastModule = false;
        }
      }
    }

    if (this.moduleHandling.ActiveProject.SupportModules) {
      for (const mdl of this.moduleHandling.ActiveProject.SupportModules) {
        if (mdl.customerModule.CustomerModuleId !== this.SelectedModule.customerModule.CustomerModuleId) {
          if (!mdl.customerModule.FullyConfigured) {
            isLastModule = false;
          }
        }
      }
    }

    return isLastModule;

  }

  public SubscribeLeveling(): boolean {
    if (this.selectedTab === 2) {
      if (this.SelectedModule.customerModule.Module.Connected) {
        // this.messageHandling.SubscribeLeveling(uuidV4, this.SelectedModule.customerModule.SerialNumber);
        return true;
      }
    }
    return false;
  }

  public LevelRequiredDone(): boolean {
    if (this.moduleHandling.DemoView) {
      return false;
    }
    if (this.SelectedModule) {
      if (this.SelectedModule.modul) {
        if (this.SelectedModule.modul.LevelingRequired !== true) {
          return true;
        }
      }
    }
    return false;
  }

  public AllTasksDone(): boolean {
    let done = true;
    if (this.SelectedModule.customerModule.ActiveTasks) {
      for (const t of this.SelectedModule.customerModule.ActiveTasks) {
        if (t.Done === false) {
          done = false;
        }
      }
    }
    return done;
  }

  public CallBackAllSetupTaskDone() {

  }

  public OnClusterReinitRequist() {
    this.selectedTab = 4;
    // this.clusterOnboarding.SetReinit(true);
    this.clusterReinitRequested = true;
  }

  public closeOnboarding() {
    if (this.moduleOnboarding.OnboardingRunning) {
      // ONBOARD ONGOING
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data =
        this.translate.GetTranslation('MESSAGEBOX.CONTENT.ONBOARDINGONGOING');
      const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          // CONNECT TO EXISTING CLUSTER
          this.moduleOnboarding.CancelOnboarding();
          this.CloseOnboarding.emit();
        }
      });
    } else if (this.clusterOnboarding.OnboardingRunning) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data =
        this.translate.GetTranslation('MESSAGEBOX.CONTENT.ONBOARDINGONGOING');
      const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          // CONNECT TO EXISTING CLUSTER
          this.clusterOnboarding.CancelOnboarding();
          this.moduleHandling.resetOnboardingPerModule();
          this.CloseOnboarding.emit();


        }
      });
    } else {
      this.CloseOnboarding.emit();
    }
  }

}
