<section fxLayout="column">
  <div class="img" fxLayout="column" fxFlexAlign="center">
    <img class="imgfile" src="/themes/basic/assets/line_technology.png">
  </div>

  <div class="hdr">
    <div class="menu"
      fxLayout="row wrap"
      fxLayout.lt-sm="column"
      fxLayoutGap="50px"
      fxLayoutAlign="center"
    >
      <div class="menuitem" routerLink="/inventory"
           fxFlex="0 1 calc(20% - 50px)"
           fxFlex.lt-md="0 1 calc(50% - 50px)"
           fxFlex.lt-sm="100%">
        <div fxLayout="column">
          <img class="imgIconView" src="/themes/basic/assets/general/inventory.svg">
          <p class="textfld opticalalign" fxFlexAlign="center" style="color: white;" >{{'COMPONENTS.HOME.INVENTORY' | i18next }}</p>
        </div>
      </div>
      <div class="menuitem" routerLink="/design"
        fxFlex="0 1 calc(20% - 50px)"
        fxFlex.lt-md="0 1 calc(50% - 50px)"
        fxFlex.lt-sm="100%">
        <div fxLayout="column">
          <img class="imgIconView" src="/themes/basic/assets/general/design.svg">
          <p class="textfld opticalalign" fxFlexAlign="center">{{'COMPONENTS.HOME.DESIGN' | i18next }}</p>
        </div>
      </div>
      <div class="menuitem" routerLink="/live"
        fxFlex="0 1 calc(20% - 50px)"
        fxFlex.lt-md="0 1 calc(50% - 50px)"
        fxFlex.lt-sm="100%">
        <div fxLayout="column">
          <img class="imgIconView" src="/themes/basic/assets/general/live.svg">
          <p class="textfld opticalalign" fxFlexAlign="center">{{'COMPONENTS.HOME.LIVE' | i18next }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
