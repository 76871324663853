import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {ComponentVariable} from '../../../classes/component-variable';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {VariableStates} from '../../../classes/variable-states';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';


@Component({
  selector: 'app-module-device-configurator',
  templateUrl: '../../../../themes/basic/templates/elements/component-configurator/component-configurator.component.html',
  styleUrls: ['../../../../themes/basic/templates/elements/component-configurator/component-configurator.component.css']
})
export class ComponentConfiguratorComponent implements OnInit {

  @Input() deviceValue: ComponentVariable;
  @Output() OnValueChanged = new EventEmitter<ComponentVariable>();
  @ViewChild('inputCurrentValue', {static: true}) inputData: ElementRef;

  constructor(public moduleHandling: ModuleHandlingService,
  public translate: BluectrlTranslateService) { }

  ngOnInit() {
    if (this.deviceValue.FeatureName === 'ConfigurableDualPosition') {

    }
  }

  public ValueChanged($event) {
    this.deviceValue.CurrentValue = $event;

    this.OnValueChanged.emit(this.deviceValue);
  }

  public ParameterDisabled(): boolean {

    if (this.deviceValue.Name === 'ServiceActive') {
      return true;
    }

    if (this.deviceValue.Deactivated && this.deviceValue.Deactivated.length > 0) {
      const vl = this.deviceValue.Deactivated.find(ex => ex === this.moduleHandling.CurrentViewMode);

      return !!vl;
    }
    return false;

  }

  Forward() {
    if (this.deviceValue.DirectionDependency) {

      this.deviceValue.Direction = 1;
      this.deviceValue.MaxValue = Math.abs(this.deviceValue.MaxValue);
      this.deviceValue.MinValue = Math.abs(this.deviceValue.MinValue);
      if (this.deviceValue.CurrentValue) {
        this.deviceValue.CurrentValue = Math.abs(this.deviceValue.CurrentValue);
      }

    }
  }

  Backward() {
    if (this.deviceValue.DirectionDependency) {
      this.deviceValue.Direction = -1;
      this.deviceValue.MaxValue = this.deviceValue.Direction * Math.abs(this.deviceValue.MaxValue);
      this.deviceValue.MinValue = this.deviceValue.Direction * Math.abs(this.deviceValue.MinValue);
      if (this.deviceValue.CurrentValue) {
        this.deviceValue.CurrentValue = this.deviceValue.Direction * Math.abs(this.deviceValue.CurrentValue);
      }


    }
  }

  OnInputChange(event: any) {
    this.deviceValue.CurrentValue = event.value;
  }

  AddValue() {

    if (!this.deviceValue.CurrentValue) {
      this.deviceValue.CurrentValue = 0;
    }

    this.deviceValue.SetValue(this.deviceValue.CurrentValue + this.deviceValue.StepSize);

    if (this.deviceValue.CurrentValue < this.deviceValue.MinValue) {
      this.deviceValue.SetValue(this.deviceValue.MinValue);
    }
    if (this.deviceValue.CurrentValue > this.deviceValue.MaxValue) {
      this.deviceValue.SetValue(this.deviceValue.MaxValue);
    }

    const stl = 1.0 / this.deviceValue.StepSize;

    this.deviceValue.SetValue(Math.round(this.deviceValue.CurrentValue * stl) / stl);

    this.OnValueChanged.emit(this.deviceValue);
  }

  SubValue() {

    if (!this.deviceValue.CurrentValue) {
      this.deviceValue.CurrentValue = 0;
    }

    this.deviceValue.SetValue(this.deviceValue.CurrentValue - this.deviceValue.StepSize);
    if (this.deviceValue.CurrentValue < this.deviceValue.MinValue) {
      this.deviceValue.SetValue(this.deviceValue.CurrentValue = this.deviceValue.MinValue);
    }

    const stl = 1.0 / this.deviceValue.StepSize;

    this.deviceValue.SetValue(Math.round(this.deviceValue.CurrentValue * stl) / stl);

    this.OnValueChanged.emit(this.deviceValue);
  }

  public GetTranslationOfState(state: any) {
    if (this.deviceValue.ValuePath && this.deviceValue.DisplayPath) {
      for (const s of this.deviceValue.SelectionList) {
        if (s[this.deviceValue.ValuePath] == state) {

          // this.ValueChangedInSelector(s[this.deviceValue.ValuePath]);
          // console.log('its a state');
          // console.log(s[this.deviceValue.DisplayPath]);

          return s[this.deviceValue.DisplayPath];
        }
      }
    }

    const st = this.deviceValue.States.find(ex => ex.State === state);


    if (st) {
      return this.GetStateTranslation(st);
    } else {
      return 'NOT FOUND';
    }
  }

  public GetStateTranslation(state: VariableStates) {
    if (state) {
      const translated =  this.translate.GetTranslation(state.GetTranslationID());

      if (translated !== state.GetTranslationID()) {
        return translated;
      } else {
        return state.GetName();
      }
    } else {
      return '';
    }
  }

  public GetCurrentValue(): any {
    if (this.deviceValue.States.length > 0) {
      if (this.deviceValue.CurrentValue || this.deviceValue.CurrentValue === 0 || this.deviceValue.CurrentValue === false) {
        const fnd = this.deviceValue.States.find(ex => ex.State === this.deviceValue.CurrentValue);
        if (fnd) {
          const translated =  this.translate.GetTranslation(fnd.GetTranslationID());
          if (translated !== fnd.GetTranslationID()) {
            return translated;
          } else {
            return fnd.GetName();
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return this.deviceValue.CurrentValue;
    }
  }

  attributeDisplay(attribute1,attribute2){
    console.log(attribute1);
    console.log(attribute2);
    console.log(this.deviceValue.getValue());

    if (attribute1 == attribute2) {
      return attribute1;
    } else if (this.deviceValue.getValue() === attribute1) {
        return attribute1;
    }
    else {
      return "";
    }
  }

  public ValueChangedInSelector($event) {
    if ($event === 'true') {
      this.deviceValue.CurrentValue = true;
    } else if ($event === 'false') {
      this.deviceValue.CurrentValue = false;
    } else if (!isNaN(Number($event))) {
      this.deviceValue.CurrentValue = Number($event);
    } else {
      this.deviceValue.CurrentValue = $event;
    }

    // if (this.deviceValue.Virtual) {
      this.deviceValue.forceValueChanged();
    // }
    this.OnValueChanged.emit(this.deviceValue);
  }

}
