<section fxLayout="column" class="inventoryTheme">
  <!-- SEARCH BOX !-->
  <mat-dialog-content class="searchBar" [formGroup]="form" fxLayout="row">
    <mat-form-field class="inputsx">
     <img class="searchIcon" src="/themes/basic/assets/general/search.svg">
      <input matInput placeholder="{{'COMPONENTS.INVENTORY.PLACEHOLDER_SEARCH' | i18next }}" formControlName="searchtext" (keydown.enter)="SearchFor($event)">
    </mat-form-field>
  </mat-dialog-content>
  <div class="maincontainer" fxLayout="column">
  <!-- UPSCROLL !-->
    <div fxLayout="column"  fxFlexAlign="center" class="downscrollarrow">
      <button mat-button class="arrowbtn" (click)="ScrollUp()" *ngIf="UpScrolling()">
        <svg height="40" width="70" class="svgarrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.56 4.24">
          <defs><style>.cls-1{fill:white;}</style></defs>
          <title>Element 34</title>
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Menu">
              <path class="cls-1" d="m 7.155861,0.7077706 a 0.23335842,0.19198841 0 0 1 0.2083558,0 l 5.9673082,2.427282 -0.27503,0.459401 -5.7422836,-2.3312879 a 0.12501344,0.10285094 0 0 0 -0.108345,0 l -5.7339493,2.3312879 -0.27503,-0.459401 z"/>
            </g>
          </g>
        </svg>
      </button>
    </div>
    <!-- ELEMENTS !-->
    <div fxLayout="column" class="deviceContainer" #deviceContainer>
      <app-inventory-row class="itemrow" *ngFor="let m of ModuleTypes" [Group]="m" [Modules]="getModules(m)" (CustomerModuleSelected)="CustomerModuleSelected($event)" (ShowModuleDetailView)="showModuleDetailView($event)">
      </app-inventory-row>
    </div>
    <!-- DOWNSCROLL !-->
    <div fxLayout="column"  fxFlexAlign="center" class="downscrollarrow">
      <button mat-button class="arrowbtn" (click)="ScrollDown()" *ngIf="DownScrolling()">
        <svg height="40" width="70" class="svgarrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.56 4.24">
          <defs><style>.cls-1{fill:white;}</style></defs>
          <title>Element 34</title>
          <g id="Ebene_2" data-name="Ebene 2">
            <g id="Menu">
              <path class="cls-1" d="m 7.3725511,3.5742601 a 0.23335842,0.19198841 0 0 1 -0.2083558,0 L 1.1968871,1.1469781 1.4719167,0.68757717 7.2142007,3.018865 a 0.12501344,0.10285094 0 0 0 0.108345,0 l 5.7339493,-2.33128783 0.27503,0.45940093 z"/>
            </g>
          </g>
        </svg>
      </button>
    </div>
  </div>
  <app-module-settings *ngIf="ShowModuleSettings" [monitoringMsgId]="monitoringMsgId" [inventoryView]="true" [SelectedModule]="ActiveModulePlan"></app-module-settings>
  <app-module-detail-view *ngIf="ShowModuleDetail" (ShowSettings)="CustomerModuleSelected($event)" [SelectedModule]="ActiveModulePlan" (CloseDetailView)="closeDetailView()"></app-module-detail-view>

</section>
