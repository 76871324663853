import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-message-box',
  templateUrl: '../../../../themes/basic/templates/dialogs/default-message-box/message-box.component.html',
  styleUrls: ['../../../../themes/basic/templates/dialogs/default-message-box/message-box.component.css']
})
export class MessageBoxComponent implements OnInit {

  public left = '0px';
  public timeout = 3000;
  public text = '';

  constructor(public thisDialogRef: MatDialogRef<MessageBoxComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.text) {
      this.text = data.text;

      if (data.timeout) {
        this.timeout = data.timeout;
      }

    } else {
      this.text = data as string;
    }
  }

  ngOnInit() {
    this.left = window.innerWidth / 2 - 150 + 'px';
    this.waitForIt();
  }

  onCloseConfirm() {
    this.thisDialogRef.close('confirmed second');
  }

  async waitForIt() {
    await setTimeout(() => {this.onCloseConfirm(); } , this.timeout);
  }

}
