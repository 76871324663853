import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {BluectrlApiService} from '../../../services/bluectrl-api.service';
import {ServerCommunicationService} from '../../../services/server-communication.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-transfer-recipe-dialog',
  templateUrl: '../../../../themes/basic/templates/dialogs/transfer-recipe-dialog/transfer-recipe-dialog.component.html',
  styleUrls: ['../../../../themes/basic/templates/dialogs/transfer-recipe-dialog/transfer-recipe-dialog.component.css']
})
export class TransferRecipeDialogComponent implements OnInit {

  public organisations: any[];
  public selectedOrganisation: any;
  public recipeId: string

  constructor(private fb: FormBuilder,
              public apiService: BluectrlApiService,
              public server: ServerCommunicationService,
              private dialogRef: MatDialogRef<TransferRecipeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {

    this.recipeId = data as string;

  }

  ngOnInit(): void {
    this.loadOrganisations();
  }

  private loadOrganisations() {
    this.apiService.getOrganizations().subscribe(async (organizations: any) => {
      if (organizations.length > 0) {
        this.organisations = organizations;

        console.log(this.organisations);
      }
    });
  }

  CloseDialog() {
    this.dialogRef.close();
  }

  public savePossible(): boolean {


    if (this.selectedOrganisation) {
      return true;

    }

    return false;
  }

  public transferRecipe() {
      if (this.savePossible()) {
        this.apiService.transferRecipe(this.recipeId, this.selectedOrganisation.id).subscribe((response: any) => {
          this.dialogRef.close(true);
        }, error => {
          console.log(error);
        });
      }
  }

}
