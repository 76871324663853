<section fxLayout="column" fxFlexAlign="stretch">
  <div class="header" fxLayout="row" fxLayoutAlign="stretch">
    <h2 fxFlexAlign="center" mat-dialog-title class="onboardingText">{{'DIALOGS.INVENTORYSYNC.TITLE' | i18next }}</h2>
    <div  fxFlex fxLayout fxLayoutAlign="end" fxFlexAlign="center">
      <button mat-button (click)="CloseDialog()" *ngIf="!InitSucessfull">
        <img class="invertSVG" src="/themes/basic/assets/general/close.svg">
      </button>
      <button mat-button (click)="CloseDialog()" *ngIf="InitSucessfull">
        <img src="/themes/basic/assets/general/ok_green.svg">
      </button>
    </div>
  </div>

  <div class="connectionState" fxLayout="row" fxFlexAlign="center">
    <div class="actionList" fxLayout="row" fxFlexAlign="stretch" *ngFor="let a of actionList">
      <div fxLayout="column" [ngClass]="{'actiondivstart': a.id === 1, 'actiondivend': a.id === 2}">
        <p class="generellTxt" [ngClass]="{'actiontextstart': a.id === 1, 'actiontextend specialPosition': a.id === 2}">{{a.action | i18next}}</p>
        <div [ngSwitch]="a.id">
          <div *ngSwitchCase="1" class="actionSvgContainer">
            <img *ngIf="a.state === 0" class="actionimgStart progressIcon" src="/themes/basic/assets/stepIcon_start_b.svg">
            <img *ngIf="a.state === 1" class="actionimgStart progressIcon" src="/themes/basic/assets/stepIcon_start_d.svg">
            <img *ngIf="a.state >= 2" class="actionimgStart progressIcon" src="/themes/basic/assets/stepIcon_start_r.svg">
          </div>
          <div *ngSwitchCase="2" class="actionSvgContainer">
            <img *ngIf="a.state === 0" class="actionimgEnd progressIcon" src="/themes/basic/assets/stepIcon_end_b.svg">
            <img *ngIf="a.state === 1" class="actionimgEnd progressIcon" src="/themes/basic/assets/stepIcon_end_d.svg">
            <img *ngIf="a.state >= 2" class="actionimgEnd progressIcon" src="/themes/basic/assets/stepIcon_end_r.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
  <button class="retryButton" mat-button (click)="RetryInit()" *ngIf="RetryPossible()">
    {{'COMMON.RETRY' | i18next }}
  </button>
</section>
