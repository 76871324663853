export enum Entities {
  INIT = 'init',
  STATE = 'state',
  MODECHANGE = 'modechange',
  SERVICE = 'service',
  NOTIFICATION = 'notification',
  RESET = 'reset',
  MONITORING = 'monitoring',
  RECIPE = 'recipe',
  AUTOSTATE = 'autostate',
  REVISION = 'revision',
  SIGNALTEST = 'signaltest',
  CONNECTIONSTATE = 'connectionstate',
  EMERGENCYREQUIRED = 'emergencyrequired',
  BELTSTATE = 'beltstate',
  LEVELLING = 'levelling',
  LEVELLINGREQUIRED = 'levellingrequired',
  CLUSTERCONFIG = 'clusterConfig',
  MODULEINITFINISHED = 'moduleinitfinished',
  MODULEINIT = 'moduleinit',
  CLUSTERREQUEST = 'clusterRequest',
  CLUSTERESET = 'clusterReset',
  MODULESSTOPPED = 'modulesstopped',
  POPUPNOTIFICATION = 'popupnotification',
  DATADOWNLOAD = 'datadownload',
  DATAUPLOAD = 'dataupload',
  HEARTBEAT = 'heartbeat',
  SAFETYFINISHED = 'safetyfinished',
  WRONGMODE = 'wrongmode',
  SPECIFICATION = 'specification',

  LOGNOTIFICATION = 'lognotification',

  SETPARAMETER = 'setParameter'
}
