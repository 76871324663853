<div class="main" fxLayout="column">
   <img class="closeXicon" (click)="Confirm()" src="/themes/basic/assets/general/close.svg">
  <mat-dialog-content>
    <h2 class="popupHeadline"><p>{{'DIALOGS.RUNSERVICECONFIG.TITLE' | i18next }}</p></h2>
    <p class="content">{{'DIALOGS.RUNSERVICECONFIG.INFO' | i18next }}</p>
    <p *ngIf="customerModule">{{customerModule.Module.Group}} {{customerModule.Module.Name}} {{customerModule.SerialNumber}}</p>
    <img class="ButtonImage" src="/themes/basic/assets/general/start_button_graphic.svg">
  </mat-dialog-content>
  <!-- <div class="buttondiv" fxLayout="row" fxFlexAlign="stretch">
    <button class="yesButton" fxFlexAlign="flex-start" (click)="Confirm()">{{'COMMON.OK' | i18next }}</button>
  </div> -->
</div>
