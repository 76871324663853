<section fxLayout="column" fxFlexAlign="stretch">
  <div class="header" fxLayout="row" fxLayoutAlign="stretch">
    <h2 class="uppercaseText" fxFlexAlign="center" mat-dialog-title>{{'DIALOGS.NEWORGANIZATION.TITLE' | i18next }}</h2>
    <div  fxFlex fxLayout fxLayoutAlign="end" fxFlexAlign="center">
      <button mat-button (click)="CloseDialog()">
        <img class="invertedWhite" src="/themes/basic/assets/general/close.svg">
      </button>
    </div>
  </div>

  <div class="inputs" fxLayout="column">
    <mat-dialog-content [formGroup]="form" fxLayout="column">
      <mat-form-field class="inputblue">
        <input matInput placeholder="{{'DIALOGS.NEWORGANIZATION.NAME' | i18next }}" formControlName="Name">
      </mat-form-field>
    </mat-dialog-content>
  </div>

  <div class="savediv" fxLayout="row" fxFlexAlign="center">
    <button mat-button (click)="SaveOrganization()" *ngIf="form.value.Name">
      <img class="invertedWhite" src="/themes/basic/assets/general/save.svg">
    </button>
  </div>

</section>
