import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-recipe-validation-error-dialog',
  templateUrl: '../../../../themes/basic/templates/dialogs/recipe-validation-error-dialog/recipe-validation-error-dialog.component.html',
  styleUrls: ['../../../../themes/basic/templates/dialogs/recipe-validation-error-dialog/recipe-validation-error-dialog.component.css']
})
export class RecipeValidationErrorDialogComponent implements OnInit {

  public ValidationInfo: any[];
  @ViewChild('validationContainer', { read: ElementRef, static: false}) public widgetsContent: ElementRef<any>;

  constructor(public DialogRef: MatDialogRef<RecipeValidationErrorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.ValidationInfo = data;
    }
  }

  ngOnInit(): void {
  }

  public Confirm() {
    this.DialogRef.close(true);
  }

  public ScrollDown() {
    this.widgetsContent.nativeElement.scrollTop = this.widgetsContent.nativeElement.scrollTop + 50;
  }

  public ScrollUp() {
    this.widgetsContent.nativeElement.scrollTop =
      this.widgetsContent.nativeElement.scrollTop >= 0 ? this.widgetsContent.nativeElement.scrollTop - 50 : 0;
  }

  public DownScrolling(): boolean {

    if (this.widgetsContent !== undefined && this.widgetsContent !== null) {
      const off = this.widgetsContent.nativeElement.clientHeight + this.widgetsContent.nativeElement.scrollTop;
      if (off < this.widgetsContent.nativeElement.scrollHeight) {
        return true;
      }
    }
    return false;
  }

  public UpScrolling(): boolean {


    if (this.widgetsContent !== undefined && this.widgetsContent !== null) {
      if (this.widgetsContent.nativeElement.scrollTop > 0) {
        return true;
      }
    }
    return false;
  }

}
