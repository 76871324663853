import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {CustomerModule} from '../../../classes/customer-module';

import {ModuleDefinition} from '../../../classes/module-definition';
import {CatalogModule} from '../../../classes/catalog-module';

@Component({
  selector: 'app-module-catalog-row',
  templateUrl: '../../../../themes/basic/templates/elements/module-catalog-row/module-catalog-row.component.html',
  styleUrls: ['../../../../themes/basic/templates/elements/module-catalog-row/module-catalog-row.component.css']
})
export class ModuleCatalogRowComponent implements OnInit {

  public _customerModules: CatalogModule[];
  public _modules: ModuleDefinition[];
  public displayCustomerModules: CatalogModule[];
  public displayModules: ModuleDefinition[];
  public currentIndex = 0;


  @Input() set customerModule(value: CatalogModule[]) {
    this._customerModules = value;

    if (this._customerModules != null && this._customerModules !== undefined) {
      if (this._customerModules.length > 3) {

          if (this.currentIndex + 3 > this._customerModules.length) {
            this.currentIndex = 0;
          }

          this.displayCustomerModules = [];
          for (let i = this.currentIndex; i < this.currentIndex + 3; i++ ) {
            this.displayCustomerModules.push(this._customerModules[i]);
          }

      } else {
        this.displayCustomerModules = this._customerModules;
      }
    }
  }
  @Input() set modules(value: ModuleDefinition[]) {
    this._modules = value;

    if (this._modules !== null && this._modules !== undefined) {
      if (this._modules.length > 3) {

        this.displayModules = [];
        for (let i = 0; i < 3; i++ ) {
          this.displayModules.push(this._modules[i]);
        }

      } else {
        this.displayModules = this._modules;
      }
    }

  }
  get customerModule(): CatalogModule[] {
    return this._customerModules;
  }
  get modules(): ModuleDefinition[] {
    return this._modules;
  }

  @Output() CustomerModuleSelected = new EventEmitter<CustomerModule>();
  @Output() ModuleSelected = new EventEmitter<ModuleDefinition>();

  constructor() { }

  ngOnInit() {

  }

  public goRight() {
    if (this._customerModules !== null && this._customerModules !== undefined) {
      if (this.currentIndex + 3 < this._customerModules.length) {
        this.currentIndex++;
        this.displayCustomerModules = [];
        for (let i = this.currentIndex; i < this.currentIndex + 3; i++ ) {
          this.displayCustomerModules.push(this._customerModules[i]);
        }
      }
    } else {

      if (this.currentIndex + 3 < this._modules.length) {
        this.currentIndex++;
        this.displayModules = [];
        for (let i = this.currentIndex; i < this.currentIndex + 3; i++ ) {
          this.displayModules.push(this._modules[i]);
        }
      }
    }

  }

  public goLeft() {
    if (this._customerModules !== null && this._customerModules !== undefined) {

      if (this.currentIndex >= 1) {
        this.currentIndex--;
        this.displayCustomerModules = [];
        for (let i = this.currentIndex; i < this.currentIndex + 3; i++ ) {
          this.displayCustomerModules.push(this._customerModules[i]);
        }
      }
    } else {

      if (this.currentIndex >= 1) {
        this.currentIndex--;
        this.displayModules = [];
        for (let i = this.currentIndex; i < this.currentIndex + 3; i++ ) {
          this.displayModules.push(this._modules[i]);
        }
      }
    }

  }

  public GetCurrentArrayLength(): number {
    if (this._customerModules !== null && this._customerModules !== undefined) {
      return this._customerModules.length;
    } else if (this._modules !== null && this._modules !== undefined) {
      return this._modules.length;
    } else {
      return 0;
    }
  }

  public ModuleClicked(module: ModuleDefinition) {
    this.ModuleSelected.emit(module);
  }

  public CustomerModuleClicked(customerModule: CustomerModule) {
    this.CustomerModuleSelected.emit(customerModule);
  }

}
