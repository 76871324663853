import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {ClusterOnboardingService} from '../../../services/v2/cluster-onboarding.service';
import {ModuleOnboardingComponent} from '../../views/module-onboarding/module-onboarding.component';
import {ModuleOnboardingService} from '../../../services/v2/module-onboarding.service';

@Component({
  selector: 'app-waiting-module-stop',
  templateUrl: '../../../../themes/basic/templates/dialogs/waiting-module-stop/waiting-module-stop.component.html',
  styleUrls: ['../../../../themes/basic/templates/dialogs/waiting-module-stop/waiting-module-stop.component.css']
})
export class WaitingModuleStopComponent implements OnInit {

  private handler: any;
  public progress: number;
  private time = 5.0 * 60.0;
  private finishRequested = false;

  constructor(private dialogRef: MatDialogRef<WaitingModuleStopComponent>,
              @Inject(MAT_DIALOG_DATA) data,
              private moduleOnboarding: ModuleOnboardingService) {

    this.moduleOnboarding.ModulesStopped.subscribe(this.ModulesStopped.bind(this));
  }

  CloseDialog() {

    this.dialogRef.close(false);
  }

  ngOnInit(): void {
    this.progress = 0.0;

    // RUN PROGRESS
    const stepper = 100.0 / this.time;

    this.handler = setInterval(et => {

      this.progress = this.progress + stepper;

      if (this.progress >= 100) {
        clearInterval(this.handler);
        this.dialogRef.close(false);
      }

    }, 1000);
  }

  public Confirm() {
    clearInterval(this.handler);
    this.moduleOnboarding.ManuellModuleStopConfirmation();
    this.dialogRef.close(true);
  }

  public ModulesStopped() {
    if (!this.finishRequested) {
      this.finishRequested = true;
        // Finish animation
        clearInterval(this.handler);
        const rest = 100.0 - this.progress;
        const stp = rest / 3.0;
        this.handler = setInterval(et => {

          this.progress = this.progress + stp;

          if (this.progress >= 100) {
            clearInterval(this.handler);
            this.dialogRef.close(true);
            this.finishRequested = false;
          }
        }, 1000);

    }
  }
}
