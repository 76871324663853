import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CustomerModule} from '../../../classes/customer-module';

@Component({
  selector: 'app-run-service-configuration',
  templateUrl: '../../../../themes/basic/templates/dialogs/run-service-configuration/run-service-configuration.component.html',
  styleUrls: ['../../../../themes/basic/templates/dialogs/run-service-configuration/run-service-configuration.component.css']
})
export class RunServiceConfigurationComponent implements OnInit {

  public customerModule: CustomerModule;

  constructor(public DialogRef: MatDialogRef<RunServiceConfigurationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      const cs = data as CustomerModule;

      if (cs) {
        this.customerModule = cs;
      }
    }

    setTimeout(() => {this.Confirm(); }, 6000);

  }

  ngOnInit(): void {
  }

  public Confirm() {
    this.DialogRef.close(true);
  }

}
