import {EventEmitter, Injectable} from '@angular/core';
import {ModuleHandlingService} from '../module-handling.service';
import {InitStateMessage} from '../../classes/init-state-message';
import {ClusterOnboardingErrors} from '../../classes/enums/cluster-onboarding-errors';
import {MessageHandlingService} from './message-handling.service';
import {WebsocketService} from './websocket.service';
import {WifiManagementService} from './wifi-management.service';
import {HttpClient} from '@angular/common/http';
import {BluectrlTranslateService} from '../bluectrl-translate.service';
import {MatDialog} from '@angular/material/dialog';
import {DatabaseService} from '../database.service';
import {Project} from '../../classes/project';
import {ActionInitState} from '../../classes/enums/action-init-state.enum';
import {WebsocketConnectionErrors} from '../../classes/enums/websocket-connection-errors';
import {environment} from '../../../environments/environment';
import {WifiStates} from '../../classes/enums/wifi-states';
import {ClusterOnboardingActions} from '../../classes/enums/cluster-onboarding-actions';
import * as uuidV4 from 'uuid/v4';
import {ViewCode} from '../../classes/enums/view-code.enum';
import {UserManagementService} from '../user-management.service';
import {OnboardingLogItem} from '../../classes/onboarding-log-item';
import {OnboardingLogType} from '../../classes/enums/onboarding-log-type';
import {VisibilityService} from '../visibility.service';
import {ConfirmMessageBoxComponent} from '../../components/dialogs/confirm-message-box/confirm-message-box.component';

@Injectable({
  providedIn: 'root'
})
export class ClusterOnboardingService {

  private OnboardingProject: Project;
  private masterSerial: string;
  private masterIp: string;
  private clusterId: string;
  private wifiSSID: string;
  private _ClusterInitialized = false;
  public get ClusterInitialized() {
    return this._ClusterInitialized;
  }

  public get MasterSerial(): string {
    return this.masterSerial;
  }

  public set ClusterInitialized(value: boolean) {
    this._ClusterInitialized = value;
    this.moduleHandling.setClusterInitialized(value);
  }

  public OnboardingRunning = true;
  private OnboardingStopRequested = false;
  private waitForSafety = true;
  private wifiChangeRequested = false;
  private websocketInitRequested = false;
  private waitForWebsocketConnection = false;
  private waitForClusterInit = false;
  private waitForWebsocketDisconnect = false;
  private isReinit = false;
  private standaloneClusterInit = false;
  public _clusterConnected = false;
  private waitForSSID = false;
  private endlessRetrySendInitMessageOn = false;

  public reconnectActive = false;
  public reconnectTries = 3;
  public currentReconnectTry = 1;
  public lastLogMessage = '';

  public get ClusterConnected() {
    return this._clusterConnected;
  }
  public set ClusterConnected(value: boolean) {
    this._clusterConnected = value;
    this.moduleHandling.setClusterConnected(value);
  }

  private currentMsgId: string;
  private clusterInitRetries = 0;
  private wifiChangeRetries = 0;


  public OnboardingCancled = new EventEmitter();
  public OnboardingError = new EventEmitter<ClusterOnboardingErrors>();
  public OnboardingProgress = new EventEmitter<InitStateMessage>();
  public ClusterOnboardingReinitRequested = new EventEmitter();
  public ClusterInitializingFinished = new EventEmitter();
  public SafetyRequired = new EventEmitter<boolean>();
  public SafetyRequiredFinishedByTimeout = new EventEmitter<boolean>();
  public NewLogMessage = new EventEmitter<OnboardingLogItem>();
  public AppendToLastMessage = new EventEmitter<string>();

  public hasInternet = true;

  public waitForWifiCallback = false;
  public waitForVisible = false;


  constructor(private messaging: MessageHandlingService,
              private websocket: WebsocketService,
              private wifi: WifiManagementService,
              private http: HttpClient,
              private moduleHandling: ModuleHandlingService,
              private translate: BluectrlTranslateService,
              private dialog: MatDialog,
              private usermgmnt: UserManagementService,
              private database: DatabaseService,
              private visibilityServ: VisibilityService) {

    this.moduleHandling.OnProjectLoaded.subscribe(this.onNewProjectLoaded.bind(this));
    this.moduleHandling.NewProjectCreated.subscribe(this.onNewProjectCreated.bind(this));
    this.moduleHandling.OnViewModeChanged.subscribe(this.onViewModeChanged.bind(this));
    this.moduleHandling.OnUserLoggedOut.subscribe(this.onUserLoggedOff.bind(this));

    this.websocket.WebsocketClosed.subscribe(this.onWebsocketClosed.bind(this));
    this.websocket.WebsocketError.subscribe(this.onWebsocketError.bind(this));
    this.websocket.WebsocketConnected.subscribe(this.onWebsocketConnected.bind(this));
    this.websocket.Disconnected.subscribe(this.onWebsocketDisconnected.bind(this));
    this.websocket.ConnectionError.subscribe(this.onWebsocketConnectionError.bind(this));

    this.websocket.NewLogMessage.subscribe(this.sendLogMessageFromServices.bind(this));

    this.messaging.ClusterInitReponse.subscribe(this.OnClusterInitResponse.bind(this));
    this.messaging.EmergencyTestStateChanged.subscribe(this.onEmergencyRequiredChanged.bind(this));
    this.messaging.ModuleStateChanged.subscribe(this.onModuleStateChanged.bind(this));
    this.messaging.ModuleOnlineStateChanged.subscribe(this.onModuleOnlineStateChanged.bind(this));
    this.messaging.ClusterOnboardingLogMessage.subscribe(this.onLogMessage.bind(this));

    this.wifi.WifiChangeError.subscribe(this.onWifiChangeError.bind(this));
    this.wifi.WifiChanged.subscribe(this.onWifiChanged.bind(this));
    this.wifi.NewLogMessage.subscribe(this.sendLogMessageFromServices.bind(this));
    this.wifi.AvailableWifisUpdated.subscribe(this.onAvailableSSIDUpdated.bind(this));

    this.visibilityServ.visibilityChanged.subscribe(this.onVisibilityChanged.bind(this));

    this.runCheckForWifiServiceHandler();

  }

  private onVisibilityChanged(state: boolean) {
    if (this.waitForVisible) {
      if (state == true) {
        this.waitForVisible = false;
        this.automaticReconnect();
      }
    }

  }

  private runCheckForWifiServiceHandler() {
    this.waitForWifiCallback = true;
    setTimeout(() => {
      if (this.waitForWifiCallback) {
        if (this.wifi.ServiceAvailable) {
          this.wifi.WifiChangeError.subscribe(this.onWifiChangeError.bind(this));
          this.wifi.WifiChanged.subscribe(this.onWifiChanged.bind(this));
          this.wifi.NewLogMessage.subscribe(this.sendLogMessageFromServices.bind(this));
          this.wifi.AvailableWifisUpdated.subscribe(this.onAvailableSSIDUpdated.bind(this));
          this.runCheckForWifiServiceHandler();
        }
      } else {

      }
    }, 30000);
  }

  private sendLogMessageFromServices(message: OnboardingLogItem) {
    this.NewLogMessage.emit(message);
  }

  private onAvailableSSIDUpdated(ssids: string[]) {

    this.waitForWifiCallback = false;

    if ((this.OnboardingRunning && !this.isCancled()) || this.checkReconnectStillActive()) {
      if (this.waitForSSID) {
        if (ssids.find(ex => ex === this.wifiSSID)) {

          this.waitForSSID = false;
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.CLUSTERWIFIAVAILABLE_1') + ' ' +
            this.wifiSSID + ' ' + this.translate.GetTranslation('LOGMESSAGES.CLUSTERWIFIAVAILABLE_2') , OnboardingLogType.INFO));


          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.TRYCHANGEWIFI_1') + ' ' + this.wifiSSID +
            ' ' + this.translate.GetTranslation('LOGMESSAGES.TRYCHANGEWIFI_2') + '. ' +
            this.translate.GetTranslation('LOGMESSAGES.TRYCOUNTER') + ' ' +
            (this.wifiChangeRetries + 1 ).toString() + ' / 7' ,
            OnboardingLogType.INFO));

          setTimeout(() => {
            this.wifiChangeRequested = true;
            this.wifi.changeWifi(this.wifiSSID, environment.DefaultClusterWifiKey);
          }, 2000);
        } else {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.CLUSTERWIFIAVAILABLE_1') +
            ' ' + this.wifiSSID + ' ' +
            this.translate.GetTranslation('LOGMESSAGES.CLUSTERWIFINOTAVAILABLE') , OnboardingLogType.WARNING));
        }
      }
    }
  }

  private onEmergencyRequiredChanged(data: any) {
    if (data.state === true) {
      this.NewLogMessage.emit(new OnboardingLogItem(
        this.translate.GetTranslation('LOGMESSAGES.SAFTEYTESTREQUIREDINFORECEIVED') +
        ' ' + data.ctxId,
        OnboardingLogType.INFO));
    } else if (data.state === false) {
      this.NewLogMessage.emit(new OnboardingLogItem(
        this.translate.GetTranslation('LOGMESSAGES.SAFTEYTESTNOTREQUIREDINFORECEIVED') +
        ' ' + data.ctxId,
        OnboardingLogType.INFO));
    }


    if (this.OnboardingRunning && !this.isCancled() && this.waitForSafety) {
      const mdl = this.OnboardingProject.Modules.find(ex => ex.customerModule.SerialNumber === data.ctxId);

      if (mdl) {
        if (mdl.customerModule.Module.NetworkInfo) {
          if (data.state === true) {
            // DONE
            this.NewLogMessage.emit(new OnboardingLogItem(
              this.translate.GetTranslation('LOGMESSAGES.WAITFORSAFETYFINISHED'), OnboardingLogType.INFO));

            this.SafetyRequired.emit(false);
            this.waitForSafety = false;
            this.finishOnboardingForSafety();
          }
        }
      } else if (this.OnboardingProject.SupportModules) {
        const mdlsup = this.OnboardingProject.SupportModules.find(ex => ex.customerModule.SerialNumber === data.ctxId);
        if (mdlsup) {
          if (mdlsup.customerModule.Module.NetworkInfo) {
            if (data.state === true) {
              // DONE

              this.NewLogMessage.emit(new OnboardingLogItem(
                this.translate.GetTranslation('LOGMESSAGES.WAITFORSAFETYFINISHED'), OnboardingLogType.INFO));
              this.SafetyRequired.emit(false);
              this.waitForSafety = false;
              this.finishOnboardingForSafety();
            }
          }
        }
      }
    }
  }

  private onViewModeChanged(viewMode: ViewCode) {
    if (viewMode !== ViewCode.live) {
      this.resetOnboarding();
      this.reconnectActive = false;
    }
  }

  private onNewProjectCreated() {
    this.resetOnboarding();
  }

  private onLogMessage(msg: any) {
    if (this.OnboardingRunning && !this.isCancled()) {
      console.log(msg.text);

      this.NewLogMessage.emit(new OnboardingLogItem(msg.text,
        OnboardingLogType.INFO));

    }
  }

  private onModuleStateChanged(data: any) {

    this.NewLogMessage.emit(new OnboardingLogItem(
      this.translate.GetTranslation('LOGMESSAGES.MODULESTATECHANGED_1') + ' ' +
      data.ctxId + ' ' + this.translate.GetTranslation('LOGMESSAGES.MODULESTATECHANGED_2') + ' ' + data.state,
      OnboardingLogType.DEBUG));

    if (this.OnboardingRunning && !this.isCancled() && this.waitForSafety) {
      const mdl = this.OnboardingProject.Modules.find(ex => ex.customerModule.SerialNumber === data.ctxId);
      if (mdl) {
        if (mdl.customerModule.Module.NetworkInfo) {
          if (this.waitForSafety === true && data.state !== 8) {
            // DONE

            // WAIT 5 Seconds if really changed to
            const timer = setTimeout(() => {
              if (this.waitForSafety) {
                if (mdl.customerModule.Module.NetworkInfo.statemodule === data.state) {
                  this.NewLogMessage.emit(new OnboardingLogItem(
                    this.translate.GetTranslation('LOGMESSAGES.WAITFORSAFETYFINISHED'), OnboardingLogType.INFO));
                  this.SafetyRequired.emit(false);
                  this.waitForSafety = false;
                  this.finishOnboardingForSafety();
                }
              }
            }, 5000);


          }
        }
      } else if (this.OnboardingProject.SupportModules) {
        const mdlsup = this.OnboardingProject.SupportModules.find(ex => ex.customerModule.SerialNumber === data.ctxId);
        if (mdlsup) {
          if (mdlsup.customerModule.Module.NetworkInfo) {
            if (this.waitForSafety === true && data.state !== 8) {
              // DONE
              // WAIT 5 Seconds if really changed to
              const timer = setTimeout(() => {
                if (this.waitForSafety) {
                  if (mdl.customerModule.Module.NetworkInfo.statemodule === data.state) {
                    this.NewLogMessage.emit(new OnboardingLogItem(
                      this.translate.GetTranslation('LOGMESSAGES.WAITFORSAFETYFINISHED'), OnboardingLogType.INFO));
                    this.SafetyRequired.emit(false);
                    this.waitForSafety = false;
                    this.finishOnboardingForSafety();
                  }
                }
              }, 5000);
            }
          }
        }
      }
    }
  }

  private onModuleOnlineStateChanged(data: any) {
    if (this.OnboardingRunning && !this.isCancled() && this.waitForSafety) {
      // KEEP OPEN
      // this.NewLogMessage.emit(new OnboardingLogItem(
      // 'FEHLER beim Cluster Onboarding Module ' + data.ctxId + ' ist offline',
      // OnboardingLogType.INFO));
    }
  }

  private onNewProjectLoaded() {
    this.resetOnboarding();
  }

  private onUserLoggedOff(data: any) {
    this.resetOnboarding();
  }

  private onWebsocketConnected() {
    if (this.OnboardingRunning && !this.isCancled()) {
      if (this.websocketInitRequested && this.waitForWebsocketConnection) {

        this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.CONNECTEDTOMASTER'), OnboardingLogType.INFO));

        this.waitForWebsocketConnection = false;
        this.SendClusterInit();
      }
    } else if (this.ClusterInitialized) {
      if (this.websocket.GetCurrentIP() === this.masterIp) {
        this.ClusterConnected = true;
      }
    }
  }

  private onWebsocketClosed() {
    if (this.OnboardingRunning && !this.isCancled()) {
      if (this.websocketInitRequested || this.waitForSafety) {
        if (this.websocketInitRequested) {
          console.log('Connection to websocket closed');
          this.checkForReinitClusterInit();
        } else {
          this.stopInitOnError(ClusterOnboardingActions.CONFIGUREMASTER);
        }
      }
    } if (this.ClusterInitialized && this.ClusterConnected) {
      if (this.isInLive()) {

        this.ClusterConnected = false;
        this.waitForSafety = false;
        this.currentReconnectTry = 1;
        this.checkForAutomaticReconnect();
      }

    } else if (this.waitForSafety && this.ClusterConnected) {
      this.stopInitOnError(ClusterOnboardingActions.CONFIGUREMASTER);
    } else {

    }
  }

  private onWebsocketDisconnected() {
    if (this.OnboardingRunning && !this.isCancled()) {


      if (this.waitForWebsocketDisconnect) {
        this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.WEBSOCKETCONNECTIONCLOSED'),
          OnboardingLogType.DEBUG));

        this.waitForWebsocketDisconnect = false;
        this.startOnboardingProcess();
      }


    } else if (this.ClusterInitialized) {
      // REINIT
      this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.LOSTWEBSOCKETCONNECTION'), OnboardingLogType.WARNING));
      this.ClusterConnected = false;
      this.resetOnboarding();

    }
  }

  private onWebsocketConnectionError(error: WebsocketConnectionErrors) {
    if (this.OnboardingRunning && !this.isCancled()) {
      if (this.websocketInitRequested) {
        this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.ERRORONWEBSOCKETCONNECT'),
          OnboardingLogType.DEBUG));
        this.checkForReinitClusterInit();
      }
    } else if (this.ClusterInitialized) {
      // REINIT
      this.ClusterConnected = false;
      if (this.checkReconnectStillActive()) {
        if (this.websocketInitRequested) {
          this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.ERRORONWEBSOCKETCONNECT'),
            OnboardingLogType.DEBUG));
          this.checkForReinitClusterInit();
        }
      } else {
        if (this.moduleHandling.ActiveProject) {
          for (const mdls of this.moduleHandling.ActiveProject.Modules) {
            mdls.clusterConnectionLost();
          }
          if (this.moduleHandling.ActiveProject.SupportModules) {
            for (const mdls of this.moduleHandling.ActiveProject.SupportModules) {
              mdls.clusterConnectionLost();
            }
          }
        }
      }
    }
  }

  private onWebsocketError() {
    if (this.OnboardingRunning && !this.isCancled()) {
      if (this.websocketInitRequested) {
        this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.ERRORONWEBSOCKETCONNECT'),
          OnboardingLogType.DEBUG));
        this.checkForReinitClusterInit();
      }
    } else if (this.ClusterInitialized) {

      // REINIT
      if (this.checkReconnectStillActive()) {
        if (this.websocketInitRequested) {
          this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.ERRORONWEBSOCKETCONNECT'),
            OnboardingLogType.DEBUG));
          this.checkForReinitClusterInit();
        }
      } else if (this._ClusterInitialized) {
        this.ClusterConnected = false;
        this.waitForSafety = false;
        // this.currentReconnectTry = 1;
        this.checkForAutomaticReconnect();

      }


    }
  }

  private onWifiChanged(ssid: string) {
    if ((this.OnboardingRunning && !this.isCancled()) || (this.checkReconnectStillActive())) {
      if (this.wifiChangeRequested) {
        // SUCCESS
        if (this.wifi.getCurrentSSID()) {
          this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.WIFICHANGEDONE') +
            ' ' + this.wifi.getCurrentSSID(), OnboardingLogType.DEBUG));
        } else {
          this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.NOWIFICONNECTION'),
            OnboardingLogType.DEBUG));
        }
        this.waitForSSID = false;
        this.wifiChangeRequested = false;
        if (this.wifiSSID === this.wifi.getCurrentSSID()) {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.SUCCESSFULLWIFICONNECTION_1') +
            ' ' + this.wifiSSID + ' ' + this.translate.GetTranslation('LOGMESSAGES.SUCCESSFULLWIFICONNECTION_2'),
            OnboardingLogType.INFO));

          this.sendProgress(ClusterOnboardingActions.CONNECTWIFI, ActionInitState.success);

          this.initWebsocketConnection();
        } else {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.WIFINOTREACHED_1') +
            ' ' + this.wifiSSID + ' ' +
            this.translate.GetTranslation('LOGMESSAGES.WIFINOTREACHED_2'),
            OnboardingLogType.WARNING));
          this.rechangeWifi();
        }
      } else if (this.checkReconnectStillActive()) {
        this.retryReconnect();
      } else {

        this.NewLogMessage.emit(new OnboardingLogItem(
          this.translate.GetTranslation('LOGMESSAGES.UNREGISTEREDWIFICHANGE'),
          OnboardingLogType.ERROR));
        this.stopInitOnError(ClusterOnboardingActions.CONNECTWIFI);
      }
    } else if (this.ClusterInitialized && this.ClusterConnected) {
      this.NewLogMessage.emit(new OnboardingLogItem(
        this.translate.GetTranslation('LOGMESSAGES.WIFICHANGEBROKECLUSTER'),
        OnboardingLogType.DEBUG));
      if (this.ClusterConnected && this.wifiSSID !== this.wifi.getCurrentSSID()) {
        this.ClusterConnected = false;
        this.waitForSafety = false;
        setInterval(() => {
          this.ClusterOnboardingReinitRequested.emit();
        }, 3000);
      }
    }
  }

  private rechangeWifi() {
    this.wifiChangeRetries += 1;
    if (this.wifiChangeRetries < 7) {

      setTimeout(() => {
        this.wifiChangeRequested = true;

        this.NewLogMessage.emit(new OnboardingLogItem(
          this.translate.GetTranslation('LOGMESSAGES.TRYCHANGEWIFI_1') + ' ' + this.wifiSSID +
          ' ' + this.translate.GetTranslation('LOGMESSAGES.TRYCHANGEWIFI_2') + '. ' +
          this.translate.GetTranslation('LOGMESSAGES.TRYCOUNTER') + ' ' +
          (this.wifiChangeRetries + 1).toString() + ' / 7', OnboardingLogType.INFO));

        this.wifi.changeWifi(this.wifiSSID, environment.DefaultClusterWifiKey);
      }, 2000);
    } else if (this.checkReconnectStillActive()) {
      this.currentReconnectTry = 10;
      this.retryReconnect();
    } else {
      // ERROR

      this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.AUTOWIFICHANGEFAILED'),
        OnboardingLogType.WARNING));
      this.sendProgress(ClusterOnboardingActions.CONNECTWIFI, ActionInitState.wifiSkip);
      this.continueWithoutWifiService();
    }
  }

  private onWifiChangeError(state: WifiStates) {
    if ((this.OnboardingRunning && !this.isCancled()) || (this.checkReconnectStillActive())) {

      if (this.wifiChangeRequested) {
        // FAILED RETRY
        this.NewLogMessage.emit(new OnboardingLogItem(
          this.translate.GetTranslation('LOGMESSAGES.WIFINOTREACHED_1') +
          ' ' + this.wifiSSID + ' ' +
          this.translate.GetTranslation('LOGMESSAGES.WIFINOTREACHED_2'),
          OnboardingLogType.WARNING));
        this.waitForSSID = false;
        this.wifiChangeRequested = false;
        this.rechangeWifi();

      }
    }
  }

  public RequestClusterReinit() {
    if (!this.OnboardingRunning && !this.isCancled()) {
      this.waitForSafety = false;
    }
  }

  public setMasterIp(ip: string) {
    this.masterIp = ip;
  }

  public setMasterSerial(serial: string) {
    this.masterSerial = serial;
  }

  public resetOnboarding() {
    this.masterIp = null;
    this.masterSerial = null;
    this.clusterId = null;
    this.wifiSSID = null;
    this.clusterInitRetries = 0;
    this.ClusterConnected = false;
    if (this.ClusterInitialized) {
      this.ClusterInitialized = false;
      if (this.websocket.IsWebsocketConnected()) {
        this.websocket.Disconnect();
      }
    }




    this.OnboardingProject = null;
    this.resetOnboardingParameters();


  }

  private resetOnboardingParameters() {

    // this.NewLogMessage.emit(new OnboardingLogItem('Cluster onboarding Parameter werden zurückgesetzt', OnboardingLogType.WARNING));

    this.OnboardingRunning = false;
    this.waitForVisible = false;

    // this.waitForSafety = true;
    this.wifiChangeRetries = 0;

    this.websocketInitRequested = false;
    this.waitForWebsocketConnection = false;
    this.waitForClusterInit = false;
    this.standaloneClusterInit = false;
    this.waitForWebsocketDisconnect = false;
    this.isReinit = false;
    this.OnboardingStopRequested = false;
    this.waitForSSID = false;
    this.endlessRetrySendInitMessageOn = false;
    this.reconnectActive = false;
    // this.initWebsocketConnection();
  }

  public CancelOnboarding(): boolean {
    if (this.OnboardingRunning) {
      this.OnboardingStopRequested = true;
    }
    return true;
  }

  private AbortOnBoardingProcess() {
    this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.CLUSTERONBOARDABORT'), OnboardingLogType.WARNING));
    this.resetOnboardingParameters();
    this.resetOnboarding();
    if (this.websocket.IsWebsocketConnected()) {
      this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.CLOSEMASTERCONNECTION'), OnboardingLogType.DEBUG));
      this.websocket.Disconnect();
    }
    this.OnboardingCancled.emit();
  }

  private isCancled(): boolean {
    if (this.OnboardingStopRequested) {
      // STOP IT
      this.OnboardingStopRequested = false;
      this.AbortOnBoardingProcess();
      return true;
    } else {
      return false;
    }
  }

  public SetReinit(reinit: boolean) {
    this.isReinit = reinit;
  }

  private finishOnboarding() {

    this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.FINISHCLUSTERONBOARDING'),
      OnboardingLogType.INFO));
    this.finishOnboardingForSafety();
    this.sendProgress(ClusterOnboardingActions.CONFIGUREMASTER, ActionInitState.success);
  }

  public finishOnboardingOnSafetyTimeout() {
    this.endlessRetrySendInitMessageOn = false;
    // this.finishOnboardingForSafety();
    this.AbortOnBoardingProcess();
    this.moduleHandling.resetOnboardingPerModule();

  }

  private finishOnboardingForSafety() {
    // SAVE CLUSTER INFO IN RECIPE
    if (!this.standaloneClusterInit) {

      this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.CREATECLUSTERECIPE'),
        OnboardingLogType.DEBUG));

      const recipeForSave = this.moduleHandling.CreateRecipeDatabase(this.moduleHandling.ActiveProject, true);
      if (this.moduleHandling.ActiveProject.tempSaved === true && this.moduleHandling.ActiveProject.id) {
        // DELETE IT
        this.database.DeleteRecipie(this.moduleHandling.ActiveProject.id);
        // this.project.id = null;
      } else {
        const savevalue = {
          id: this.moduleHandling.ActiveProject.id,
          name: this.moduleHandling.ActiveProject.name,
          tempSaved: this.moduleHandling.ActiveProject.tempSaved,
          description: this.moduleHandling.ActiveProject.description,
          customer: this.usermgmnt.currentUser.CustomerId,
          author: (this.moduleHandling.ActiveProject.author !== undefined && this.moduleHandling.ActiveProject.author !== null ?
            this.moduleHandling.ActiveProject.author : this.usermgmnt.currentUser.Id),
          created: this.moduleHandling.ActiveProject.created,
          modified: new Date(),
          public: this.moduleHandling.ActiveProject.public,
          valid: true,
          recipe: JSON.stringify(recipeForSave),
        };

        this.database.AddNewRecipe(savevalue, true);
      }

      // CLEAR ALL TEMP
      this.database.recipes.toArray().then(recs => {
        const allrcs = recs.filter(ex => ex.name === '' || ex.name === null || ex.name === undefined || ex.name.indexOf('temp') >= 0);
        for (const r of allrcs) {
          this.database.DeleteRecipie(r.id);
        }
      });
    }

    if (this.checkReconnectStillActive()) {
      this.resetOnboardingParameters();
      this.ClusterInitialized = true;
      this.ClusterConnected = true;
      this.reconnectActive = false;
      this.currentReconnectTry = 1;
    } else {
      this.resetOnboardingParameters();
      this.ClusterInitialized = true;
      this.ClusterConnected = true;

      this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.CLUSTERONBOARDINGFINISHED'),
        OnboardingLogType.DEBUG));
      this.ClusterInitializingFinished.emit();
    }
  }

  private sendProgress(action: ClusterOnboardingActions, state: ActionInitState, forced: boolean = false) {
    if (this.OnboardingRunning) {
      if (this.standaloneClusterInit) {
        this.OnboardingProgress.emit(new InitStateMessage(Number(action) + 6, state, forced));
      } else {
        this.OnboardingProgress.emit(new InitStateMessage(action, state, forced));
      }


    }
  }

  private stopInitOnError(action: ClusterOnboardingActions, forced: boolean = false) {
    if (this.OnboardingRunning && !this.isCancled()) {
      this.sendProgress(action, ActionInitState.failed, forced);
      this.AbortOnBoardingProcess();

    }
  }

  public startStandaloneClusterOnboarding(masterIp: string, masterSerial: string, clusterId: string, onboardingProject: Project) {

    if (this.OnboardingRunning) {
      this.OnboardingError.emit(ClusterOnboardingErrors.ONBOARDINGRUNING);
    } else {
      this.resetOnboarding();
      this.OnboardingProject = onboardingProject;
      this.standaloneClusterInit = true;

      this.masterIp = masterIp;
      this.masterSerial = masterSerial;
      this.clusterId = clusterId;

      const splitter = this.clusterId.split('-');
      this.wifiSSID = environment.ClusterWifiSurFix +  splitter[splitter.length - 1];

      let modulesReady = true;

      for (const md of this.OnboardingProject.Modules) {
        md.clusterOnboardingStarted();
        if (!md.customerModule.FullyConfigured) {
          modulesReady = false;
        }
      }
      if (modulesReady) {
        this.initOnboarding();
      } else {
        this.resetOnboardingParameters();
        this.OnboardingError.emit(ClusterOnboardingErrors.MODULESNOTCONFIGURED);
      }
    }
  }

  public startClusterOnboarding(masterIp: string, masterSerial: string, clusterId: string, onboardingProject: Project, reinit = false) {

    if (this.OnboardingRunning) {
        this.OnboardingError.emit(ClusterOnboardingErrors.ONBOARDINGRUNING);
      } else {
        this.OnboardingProject = onboardingProject;
        this.isReinit = reinit;

        this.masterIp = masterIp;
        this.masterSerial = masterSerial;
        this.clusterId = clusterId;

        const splitter = this.clusterId.split('-');



        this.wifiSSID = environment.ClusterWifiSurFix +  splitter[splitter.length - 1];

        this.NewLogMessage.emit(new OnboardingLogItem(
          this.translate.GetTranslation('LOGMESSAGES.CLUSTERWIFI') + ' ' + this.wifiSSID, OnboardingLogType.DEBUG));

        let modulesReady = true;


        for (const md of this.OnboardingProject.Modules) {
          md.clusterOnboardingStarted();
          if (!md.customerModule.FullyConfigured) {
            modulesReady = false;
            this.NewLogMessage.emit(new OnboardingLogItem(
              md.customerModule.SerialNumber + ' ' + this.translate.GetTranslation('LOGMESSAGES.MODULENOTCONFIGURED'),
              OnboardingLogType.ERROR));
          }
        }
        if (modulesReady) {
          this.initOnboarding();
        } else {
          this.resetOnboardingParameters();
          this.OnboardingError.emit(ClusterOnboardingErrors.MODULESNOTCONFIGURED);
        }
      }
  }

  private initOnboarding() {

    if (this.masterSerial && this.masterIp && this.clusterId) {

      setTimeout(() => {
        if (this.OnboardingProject) {
          this.OnboardingRunning = true;
          this.wifiChangeRetries = 0;
          this.clusterInitRetries = 0;
          // LETS GO

          if (this.websocket.IsWebsocketConnected()) {
            this.NewLogMessage.emit(new OnboardingLogItem(
              this.translate.GetTranslation('LOGMESSAGES.WEBSOCKETCONNECTIONCLOSE'), OnboardingLogType.DEBUG));
            this.waitForWebsocketDisconnect = true;
            this.websocket.Disconnect();
          } else {
            this.startOnboardingProcess();
          }
        }
      }, 3000);
    } else {

      this.NewLogMessage.emit(new OnboardingLogItem(
        this.translate.GetTranslation('LOGMESSAGES.MISSINGPARAMETERS'), OnboardingLogType.ERROR));
      this.resetOnboardingParameters();
      this.OnboardingError.emit(ClusterOnboardingErrors.MISSINGINFORMATIONS);
    }
  }

  private startOnboardingProcess() {
    if (this.OnboardingRunning && !this.isCancled()) {
      if (this.wifi.ServiceAvailable) {
        // GO

        if (this.wifi.getCurrentSSID() === this.wifiSSID) {
          // CORRECT NETWORK

          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.CURRENTWIFIISCORRECT') + ' ' + this.wifiSSID,
            OnboardingLogType.DEBUG));

          this.sendProgress(ClusterOnboardingActions.CONNECTWIFI, ActionInitState.success);
          this.initWebsocketConnection();
        } else {
          // CHANGE WIFI
          this.wifiChangeRequested = true;
          this.checkIfClusterWifiAvailable();

        }
      } else {
        this.sendProgress(ClusterOnboardingActions.CONNECTWIFI, ActionInitState.noWifiService, false);
      }
    }
  }

  private checkReconnectStillActive(): boolean {
    if(this.reconnectActive && this.isInLive()) {
      if (this.ClusterInitialized) {
        return true;
      } else {
        this.reconnectActive = false;
        return false;
      }
    }
    return false;
  }

  public checkIfClusterWifiAvailable() {

    if ((this.OnboardingRunning && !this.isCancled()) || (this.checkReconnectStillActive())) {
      if (!this.waitForSSID) {
        if (this.wifi.getAvailableSSIDs().find(ex => ex === this.wifiSSID)) {
          this.waitForSSID = false;
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.CLUSTERWIFIAVAILABLE_1') + ' ' +
            this.wifiSSID + ' ' + this.translate.GetTranslation('LOGMESSAGES.CLUSTERWIFIAVAILABLE_2'),
            OnboardingLogType.INFO));
          this.wifiChangeRequested = true;
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.TRYCHANGEWIFI_1') + ' ' + this.wifiSSID +
            ' ' + this.translate.GetTranslation('LOGMESSAGES.TRYCHANGEWIFI_2') + '. ' +
            this.translate.GetTranslation('LOGMESSAGES.TRYCOUNTER') + ' ' +
            (this.wifiChangeRetries + 1 ).toString() + ' / 7' , OnboardingLogType.INFO));
          setTimeout(() => {
            this.wifiChangeRequested = true;
            this.wifi.changeWifi(this.wifiSSID, environment.DefaultClusterWifiKey);
          }, 2000);
        } else {
          this.waitForSSID = true;
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.WAITINGFORWIFI_1') + ' ' +
            this.wifiSSID + ' ' + this.translate.GetTranslation('LOGMESSAGES.WAITINGFORWIFI_2'),
            OnboardingLogType.DEBUG));
        }
      }
    }
  }

  public continueWithoutWifiService() {
    if (this.OnboardingRunning && !this.isCancled()) {
      this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.WIFICHANGENOTPOSSIBLE'),
        OnboardingLogType.WARNING));

      this.initWebsocketConnection();
    }
  }

  private initWebsocketConnection() {
    if (this.OnboardingRunning && !this.isCancled()) {
      if (!this.ClusterInitialized && !this.websocketInitRequested) {
        this.websocketInitRequested = true;
        this.realInitWebsocket();
      }
    } else if (this.checkReconnectStillActive() && !this.websocketInitRequested) {
      if (!this.websocketInitRequested) {
        this.websocketInitRequested = true;
        this.realInitWebsocket(2000);
      }
    }
  }

  private realInitWebsocket(timeout = 18000) {
    setTimeout(() => {
      if ((this.OnboardingRunning && !this.isCancled()) || (this.checkReconnectStillActive())) {
        // CONNECT TO WEBSOCKET
        this.NewLogMessage.emit(new OnboardingLogItem(
          this.translate.GetTranslation('LOGMESSAGES.TRYTOCONNECTTOMASTER_1') +
          ' (' + this.masterSerial + ') ' +
          this.translate.GetTranslation('LOGMESSAGES.TRYTOCONNECTTOMASTER_2'),
          OnboardingLogType.INFO));
        this.waitForWebsocketConnection = true;
        this.websocket.ConnectToSecuredServer(this.masterIp, true);

        setTimeout(() => {
          if ((this.OnboardingRunning && !this.isCancled()) || (this.checkReconnectStillActive())) {
            if (this.waitForWebsocketConnection) {
              this.NewLogMessage.emit(new OnboardingLogItem(
                this.translate.GetTranslation('LOGMESSAGES.TIMEOUTCONNECTTOMASTER') + ' (' + this.masterSerial + ')',
                OnboardingLogType.WARNING));
              this.checkForReinitClusterInit();
            }
          }
        }, 10000);
      }
    }, timeout);
  }

  public isInLive(): boolean {
    return this.moduleHandling.CurrentViewMode === ViewCode.live;
  }

  private checkForReinitClusterInit() {

    if ((this.OnboardingRunning && !this.isCancled()) || (this.checkReconnectStillActive())) {
      if (this.waitForWebsocketConnection) {
        if ( this.clusterInitRetries <= 3 ) {
          if (this.websocket.IsWebsocketConnected()) {
            this.websocketInitRequested = true;
            this.waitForClusterInit = false;
            this.clusterInitRetries = this.clusterInitRetries + 1;
            this.NewLogMessage.emit(new OnboardingLogItem(
              this.translate.GetTranslation('LOGMESSAGES.RETRYCLUSTERINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.TRYCOUNTER') + (this.clusterInitRetries).toString() +
              ' / 4', OnboardingLogType.INFO));
            setTimeout(() => {
              this.SendClusterInit();
            }, 5000);


          } else {
            this.waitForWebsocketConnection = false;
            this.websocketInitRequested = false;
            this.waitForClusterInit = false;
            this.clusterInitRetries = this.clusterInitRetries + 1;
            this.NewLogMessage.emit(new OnboardingLogItem(
              this.translate.GetTranslation('LOGMESSAGES.RECONNECTTOMASTER') + ' (' + this.masterSerial + ') . ' + this.translate.GetTranslation('LOGMESSAGES.TRYCOUNTER') +
              (this.clusterInitRetries).toString() + ' / 4', OnboardingLogType.INFO));
            this.initWebsocketConnection();
          }
        } else if (this.checkReconnectStillActive()) {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.ERRORCONNECTTOMASTER') + ' (' + this.masterSerial + ')',
            OnboardingLogType.WARNING));
          this.retryReconnect();
        } else {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.ERRORCONNECTTOMASTER') + ' (' + this.masterSerial + ')',
            OnboardingLogType.WARNING));
          this.stopInitOnError(ClusterOnboardingActions.CONFIGUREMASTER);


        }
      } else if (this.waitForClusterInit) {
        if ( this.clusterInitRetries <= 3 || this.endlessRetrySendInitMessageOn === true) {
          if (this.websocket.IsWebsocketConnected()) {
            this.websocketInitRequested = true;
            this.waitForClusterInit = false;
            this.clusterInitRetries = this.clusterInitRetries + 1;

            setTimeout(() => {
              this.SendClusterInit();
            }, 5000);
          } else {
            this.waitForWebsocketConnection = false;
            this.websocketInitRequested = false;
            this.waitForClusterInit = false;
            this.clusterInitRetries = this.clusterInitRetries + 1;
            this.NewLogMessage.emit(new OnboardingLogItem(
              this.translate.GetTranslation('LOGMESSAGES.RECONNECTTOMASTER') +
              ' (' + this.masterSerial + '). ' +
              this.translate.GetTranslation('LOGMESSAGES.TRYCOUNTER') +
              (this.clusterInitRetries).toString() + ' / 4', OnboardingLogType.INFO));
            this.initWebsocketConnection();
          }
        } else if (this.checkReconnectStillActive()) {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.ERRORCONNECTTOMASTER') + ' (' + this.masterSerial + ')',
            OnboardingLogType.WARNING));
          this.retryReconnect();
        } else {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.ERRORCONNECTTOMASTER') + ' (' + this.masterSerial + ')',
            OnboardingLogType.WARNING));
          this.stopInitOnError(ClusterOnboardingActions.CONFIGUREMASTER);
        }

      }
    }
  }

  private SendClusterInit() {
    if ((this.OnboardingRunning && !this.isCancled()) || (this.checkReconnectStillActive()) ) {

      if (!this.waitForClusterInit && this.websocketInitRequested) {

        if (!this.endlessRetrySendInitMessageOn) {
          this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.CREATECLUSTERMESSAGE'),
            OnboardingLogType.DEBUG));
        }

        this.waitForClusterInit = true;
        this.currentMsgId = uuidV4();

        const serials: any[] = [];
        let strft: any;

        for (const mdc of this.OnboardingProject.Modules) {
          serials.push({
            serialnumber: mdc.customerModule.SerialNumber,
            ipaddress: mdc.customerModule.NodeIpAddress
          });
        }

        if (this.OnboardingProject.SupportModules) {
          for (const mdc of this.OnboardingProject.SupportModules) {
            serials.push({
              serialnumber: mdc.customerModule.SerialNumber,
              ipaddress: mdc.customerModule.NodeIpAddress
            });
          }
        }

        const rec = this.moduleHandling.CreateRecipe(this.OnboardingProject);
        strft = JSON.parse(rec);

        if (!this.endlessRetrySendInitMessageOn) {
          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.SENDCLUSTERINIT'), OnboardingLogType.DEBUG));
        }
        this.messaging.requestClusterInit(this.currentMsgId, this.clusterId, strft, serials);

        setTimeout(() => {
          if (this.OnboardingRunning && !this.isCancled() && this.websocketInitRequested) {
            if (this.waitForClusterInit) {
              if (!this.endlessRetrySendInitMessageOn) {
                this.NewLogMessage.emit(new OnboardingLogItem(
                  this.translate.GetTranslation('LOGMESSAGES.TIMEOUTSENDCLUSTERINIT'),
                  OnboardingLogType.WARNING));
              } else {
                this.AppendToLastMessage.emit('X');
              }
              this.checkForReinitClusterInit();
            }
          }
        }, 20000);

      }


    } else {
      console.log('No send');
    }
  }

  private OnClusterInitResponse(cluster: any) {

    if (this.OnboardingRunning &&
      !this.isCancled() &&
      !this.ClusterInitialized) {
      if (this.waitForClusterInit) {
        if (this.clusterId === cluster.cluster && this.currentMsgId === cluster.msgId) {

          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.CLUSTERINITRESPONSE'),
            OnboardingLogType.DEBUG));

          if (cluster.clusterstate) {
            if (cluster.clusterstate.code) {
              if (cluster.clusterstate.code === 2) {
                if (this.endlessRetrySendInitMessageOn === true) {
                  this.AppendToLastMessage.emit('.');
                } else {
                  this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.CLUSTERNOTREADY'), OnboardingLogType.WARNING));
                  this.NewLogMessage.emit(new OnboardingLogItem(
                    this.translate.GetTranslation('LOGMESSAGES.RETRYCLUSTERINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.PLEASEWAIT') + '...',
                    OnboardingLogType.INFO));
                  this.waitForClusterInit = true;
                  this.endlessRetrySendInitMessageOn = true;
                  this.SafetyRequired.emit(true);
                }
                this.waitForClusterInit = true;
                this.endlessRetrySendInitMessageOn = true;
                this.checkForReinitClusterInit();
                return;
              }
            }
          }


          this.waitForClusterInit = false;

          let safetyRequired = true;
          let haveWith8 = false;

          let serialsWithEmergency = [];

          // CHECK MODULES
          for (const md of cluster.modules) {
            const custModule = this.OnboardingProject.Modules.find(ex => ex.customerModule &&
              ex.customerModule.SerialNumber === md.serialnumber);

            if (md.emergencystopok !== undefined) {
              if (md.emergencystopok == false) {
                // ERROR stop is on
                // MESSAGE BOX
                serialsWithEmergency.push(md.serialnumber);

              }
            }

            if (custModule) {
              custModule.setModuleNetworkState(md);

              if (md.statemodule === 8) {
                safetyRequired = true;
                haveWith8 = true;
                this.waitForSafety = true;
              }
              if (md.emergencystoprequired && !haveWith8) {
                safetyRequired = false;
              }
            } else if (this.OnboardingProject.SupportModules) {
              const custModulesup = this.OnboardingProject.SupportModules.find(ex => ex.customerModule &&
                ex.customerModule.SerialNumber === md.serialnumber);

              if (custModulesup) {
                custModulesup.setModuleNetworkState(md);

                if (md.statemodule === 8) {
                  safetyRequired = true;
                  haveWith8 = true;
                  this.waitForSafety = true;
                }
                if (md.emergencystoprequired && !haveWith8) {
                  safetyRequired = false;
                }
              } else {


                this.NewLogMessage.emit(new OnboardingLogItem(
                  md.serialnumber + ' ' + this.translate.GetTranslation('LOGMESSAGES.NOTINCURRENTSETUP'),
                  OnboardingLogType.WARNING));

                this.stopInitOnError(ClusterOnboardingActions.CONFIGUREMASTER);
                return;
              }
            } else {

              this.NewLogMessage.emit(new OnboardingLogItem(
                md.serialnumber + ' ' + this.translate.GetTranslation('LOGMESSAGES.NOTINCURRENTSETUP'),
                OnboardingLogType.WARNING));
              this.stopInitOnError(ClusterOnboardingActions.CONFIGUREMASTER);
              return;
            }

          }
          for (const md of this.OnboardingProject.Modules) {
            if (!md.customerModule.Module.NetworkInfo) {
              if (this.endlessRetrySendInitMessageOn === true) {
                this.AppendToLastMessage.emit('.');
              } else {
                this.NewLogMessage.emit(new OnboardingLogItem(
                  this.translate.GetTranslation('LOGMESSAGES.MODULENOTREADY') + ' (' + md.customerModule.SerialNumber + ')',
                  OnboardingLogType.WARNING));
                this.NewLogMessage.emit(new OnboardingLogItem(
                  this.translate.GetTranslation('LOGMESSAGES.RETRYCLUSTERINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.PLEASEWAIT') + '...',
                  OnboardingLogType.INFO));
                this.waitForClusterInit = true;
                this.endlessRetrySendInitMessageOn = true;
                this.SafetyRequired.emit(true);
              }
              this.waitForClusterInit = true;
              this.endlessRetrySendInitMessageOn = true;
              this.checkForReinitClusterInit();
              return;
            }
          }

          if (this.OnboardingProject.SupportModules) {
            for (const md of this.OnboardingProject.SupportModules) {
              if (!md.customerModule.Module.NetworkInfo) {
                if (this.endlessRetrySendInitMessageOn === true) {
                  this.AppendToLastMessage.emit('.');
                } else {
                  this.NewLogMessage.emit(new OnboardingLogItem(
                    this.translate.GetTranslation('LOGMESSAGES.MODULENOTREADY') + ' (' + md.customerModule.SerialNumber + ')',
                    OnboardingLogType.WARNING));
                  this.NewLogMessage.emit(new OnboardingLogItem(
                    this.translate.GetTranslation('LOGMESSAGES.RETRYCLUSTERINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.PLEASEWAIT') + '...',
                    OnboardingLogType.INFO));
                  this.waitForClusterInit = true;
                  this.endlessRetrySendInitMessageOn = true;
                  this.SafetyRequired.emit(true);
                }
                this.checkForReinitClusterInit();
                return;
              }
            }
          }

          if (serialsWithEmergency.length > 0) {
            const msg = this.translate.GetTranslation('MESSAGEBOX.HEADERS.EMERGENCYPRESSED');
            const content = this.translate.GetTranslation('MESSAGEBOX.CONTENT.EMERGENCYPRESSED');
            const dialogRef = this.dialog.open(ConfirmMessageBoxComponent,
              {panelClass: 'panelclass', data: {header: msg, content: content, elements: serialsWithEmergency}});
          }

          // If it's not a reinit safety is always required
          if (!this.isReinit) {
            this.waitForSafety = true;
            safetyRequired = true;
          } else {
            this.waitForSafety = false;
            safetyRequired = false;
          }

          if (this.waitForSafety) {
            if (safetyRequired) {

              this.SafetyRequired.emit(true);
              this.websocketInitRequested = false;

              this.NewLogMessage.emit(new OnboardingLogItem(
                this.translate.GetTranslation('LOGMESSAGES.MASTERREADY'), OnboardingLogType.INFO));
              this.endlessRetrySendInitMessageOn = false;
              this.sendProgress(ClusterOnboardingActions.CONFIGUREMASTER, ActionInitState.success);
            } else {

              this.NewLogMessage.emit(new OnboardingLogItem(
                this.translate.GetTranslation('LOGMESSAGES.MASTERREADY'), OnboardingLogType.INFO));
              this.endlessRetrySendInitMessageOn = false;
              this.waitForSafety = false;
              this.SafetyRequired.emit(false);
              this.finishOnboarding();
            }
          } else {

            this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.MASTERREADY'),
              OnboardingLogType.INFO));
            this.endlessRetrySendInitMessageOn = false;
            this.finishOnboarding();
            this.SafetyRequired.emit(false);
          }
        }
      }
    } else if (this.checkReconnectStillActive()) {
      if (this.waitForClusterInit) {
        if (this.clusterId === cluster.cluster && this.currentMsgId === cluster.msgId) {

          this.NewLogMessage.emit(new OnboardingLogItem(
            this.translate.GetTranslation('LOGMESSAGES.CLUSTERINITRESPONSE'),
            OnboardingLogType.DEBUG));

          if (cluster.clusterstate) {
            if (cluster.clusterstate.code) {
              if (cluster.clusterstate.code === 2) {
                if (this.endlessRetrySendInitMessageOn === true) {
                  this.AppendToLastMessage.emit('.');
                } else {
                  this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.CLUSTERNOTREADY'), OnboardingLogType.WARNING));
                  this.NewLogMessage.emit(new OnboardingLogItem(
                    this.translate.GetTranslation('LOGMESSAGES.RETRYCLUSTERINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.PLEASEWAIT') + '...',
                    OnboardingLogType.INFO));
                  this.waitForClusterInit = true;
                  this.endlessRetrySendInitMessageOn = true;
                  this.SafetyRequired.emit(false);
                }
                this.waitForClusterInit = true;
                this.endlessRetrySendInitMessageOn = true;
                this.checkForReinitClusterInit();
                return;
              }
            }
          }


          this.waitForClusterInit = false;

          let safetyRequired = false;

          // CHECK MODULES
          for (const md of cluster.modules) {
            const custModule = this.OnboardingProject.Modules.find(ex => ex.customerModule &&
              ex.customerModule.SerialNumber === md.serialnumber);
            if (custModule) {
              custModule.setModuleNetworkState(md);
            } else if (this.OnboardingProject.SupportModules) {
              const custModulesup = this.OnboardingProject.SupportModules.find(ex => ex.customerModule &&
                ex.customerModule.SerialNumber === md.serialnumber);

              if (custModulesup) {
                custModulesup.setModuleNetworkState(md);
              } else {


                this.NewLogMessage.emit(new OnboardingLogItem(
                  md.serialnumber + ' ' + this.translate.GetTranslation('LOGMESSAGES.NOTINCURRENTSETUP'),
                  OnboardingLogType.WARNING));

                this.currentReconnectTry = 10;
                this.retryReconnect();
                return;
              }
            } else {

              this.NewLogMessage.emit(new OnboardingLogItem(
                md.serialnumber + ' ' + this.translate.GetTranslation('LOGMESSAGES.NOTINCURRENTSETUP'),
                OnboardingLogType.WARNING));
              this.currentReconnectTry = 10;
              this.retryReconnect();
              return;
            }

          }

          for (const md of this.OnboardingProject.Modules) {
            if (!md.customerModule.Module.NetworkInfo) {
              if (this.endlessRetrySendInitMessageOn === true) {
                this.AppendToLastMessage.emit('.');
              } else {
                this.NewLogMessage.emit(new OnboardingLogItem(
                  this.translate.GetTranslation('LOGMESSAGES.MODULENOTREADY') + ' (' + md.customerModule.SerialNumber + ')',
                  OnboardingLogType.WARNING));
                this.NewLogMessage.emit(new OnboardingLogItem(
                  this.translate.GetTranslation('LOGMESSAGES.RETRYCLUSTERINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.PLEASEWAIT') + '...',
                  OnboardingLogType.INFO));
                this.waitForClusterInit = true;
                this.endlessRetrySendInitMessageOn = true;
                this.SafetyRequired.emit(false);
              }
              console.log('module missing');
              this.waitForClusterInit = true;
              this.endlessRetrySendInitMessageOn = true;
              this.checkForReinitClusterInit();
              return;
            }
          }

          if (this.OnboardingProject.SupportModules) {
            for (const md of this.OnboardingProject.SupportModules) {
              if (!md.customerModule.Module.NetworkInfo) {
                if (this.endlessRetrySendInitMessageOn === true) {
                  this.AppendToLastMessage.emit('.');
                } else {
                  this.NewLogMessage.emit(new OnboardingLogItem(
                    this.translate.GetTranslation('LOGMESSAGES.MODULENOTREADY') + ' (' + md.customerModule.SerialNumber + ')',
                    OnboardingLogType.WARNING));
                  this.NewLogMessage.emit(new OnboardingLogItem(
                    this.translate.GetTranslation('LOGMESSAGES.RETRYCLUSTERINIT') + '. ' + this.translate.GetTranslation('LOGMESSAGES.PLEASEWAIT') + '...',
                    OnboardingLogType.INFO));
                  this.waitForClusterInit = true;
                  this.endlessRetrySendInitMessageOn = true;
                  this.SafetyRequired.emit(true);
                }
                this.checkForReinitClusterInit();
                return;
              }
            }
          }
          this.NewLogMessage.emit(new OnboardingLogItem(this.translate.GetTranslation('LOGMESSAGES.MASTERREADY'),
            OnboardingLogType.INFO));
          this.endlessRetrySendInitMessageOn = false;
          this.finishOnboarding();
          this.SafetyRequired.emit(false);
        }
      }
    } else if (this.ClusterInitialized) {

      const clusterSize = (this.OnboardingProject.Modules ? this.OnboardingProject.Modules.length : 0) + (this.OnboardingProject.SupportModules ? this.OnboardingProject.SupportModules.length : 0)

      if (this.ClusterConnected === false) {
        if (cluster.modules.length > 0 && cluster.modules.filter(ex => ex.serialnumber == this.masterSerial)) {
          this.resetOnboardingParameters();
          this.ClusterInitialized = true;
          this.ClusterConnected = true;
          this.reconnectActive = false;
          this.currentReconnectTry = 1;


        } else {
          this.ClusterConnected = false;
          this.reconnectActive = true;
          this.SendClusterInit();
          return;
        }
      } else if (this.ClusterConnected === true && this.reconnectActive === true) {
        if (!cluster.modules.filter(ex => ex.serialnumber == this.masterSerial)) {
          this.ClusterConnected = false;

          this.reconnectActive = true;
          this.SendClusterInit();
          return;
        }
      }

      if (cluster.cluster === this.clusterId) {
        for (const md of cluster.modules) {
          const custModule = this.OnboardingProject.Modules.find(ex => ex.customerModule &&
            ex.customerModule.SerialNumber === md.serialnumber);
          if (custModule) {
            custModule.setModuleNetworkState(md);

            // SET SWITCH STATE
            // SET CURRENT MODE
            // SET EMERGENCY REQUIRED

          } else if (this.OnboardingProject.SupportModules) {
            const custModulesup = this.OnboardingProject.SupportModules.find(ex => ex.customerModule &&
              ex.customerModule.SerialNumber === md.serialnumber);

            if (custModulesup) {
              custModulesup.setModuleNetworkState(md);

              // SET SWITCH STATE
              // SET CURRENT MODE
              // SET EMERGENCY REQUIRED

            }
          }
        }
      }
    }
  }

  private retryReconnect() {
    if (this.checkReconnectStillActive()) {
      this.currentReconnectTry += 1;
      if (this.currentReconnectTry <= this.reconnectTries) {
        this.reconnectActive = false;
      }
      this.automaticReconnect();

    }
  }

  private checkForAutomaticReconnect() {
    if (!this.waitForVisible) {
      if (this.visibilityServ.isVisible) {
        this.automaticReconnect();
      } else {
        this.waitForVisible = true;
      }
    }
  }

  private automaticReconnect() {
    if (this._ClusterInitialized) {
      if (this.currentReconnectTry <= this.reconnectTries) {
        if (!this.reconnectActive) {
          this.reconnectActive = true;
          this.wifiChangeRetries = 0;
          this.clusterInitRetries = 0;
          this.websocketInitRequested = false;
          this.wifiChangeRequested = false;
          this.waitForSSID = false;
          this.waitForSafety = false;
          this.lastLogMessage = '';
          this.waitForVisible = false;

          let timer = 11000;
          if (this.currentReconnectTry > 1) {
            timer = 10000;
          }

          // Wait a small amount of time
          setTimeout(() => {

            // WEBSOCKET ALREADY Connected?
            if (this.websocket.IsWebsocketConnected()) {
              // 3. Send Cluster config (if error, then retry this function
              this.SendClusterInit();
            } else {
              // 1. Check current Wifi (if different then stop auto reconnect)

              if (this.wifi.ServiceAvailable) {
                // GO
                if (this.wifi.getCurrentSSID() === this.wifiSSID) {
                  // CORRECT NETWORK
                  this.NewLogMessage.emit(new OnboardingLogItem(
                    this.translate.GetTranslation('LOGMESSAGES.CURRENTWIFIISCORRECT') + ' ' + this.wifiSSID,
                    OnboardingLogType.DEBUG));
                  // 2. Connect to Websocket ( if failed, then retry  this function)
                  this.initWebsocketConnection();
                } else {
                  // CHANGE WIFI
                  this.wifiChangeRequested = true;
                  this.checkIfClusterWifiAvailable();
                }
              } else {
                // 2. Connect to Websocket ( if failed, then retry  this function)
                this.initWebsocketConnection();
              }
            }
          }, timer);

          // 4. Timeout for cluster config -> retry this function
          // 5. Handle cluster config message (if failed -> stop outo connect)

        } else {
          console.log('reconnect still active');
        }
      } else {
        if (this.reconnectActive === true) {
          this.resetOnboardingParameters();
          this.reconnectActive = false;
          this.ClusterConnected = false;

          this.waitForSafety = false;
          if (this.moduleHandling.ActiveProject) {
            for (const mdls of this.moduleHandling.ActiveProject.Modules) {
              mdls.clusterConnectionLost();
            }
            if (this.moduleHandling.ActiveProject.SupportModules) {
              for (const mdls of this.moduleHandling.ActiveProject.SupportModules) {
                mdls.clusterConnectionLost();
              }
            }
          }

          this.ClusterOnboardingReinitRequested.emit();
        }
      }
    } else {
      console.log('do nothing');
    }
  }
}
