import {Component, Input, OnInit} from '@angular/core';
import {ModulePlan} from '../../../classes/module-plan';

@Component({
  selector: 'app-positioning-tab',
  templateUrl: '../../../../themes/basic/templates/elements/positioning-tab/positioning-tab.component.html',
  styleUrls: ['../../../../themes/basic/templates/elements/positioning-tab/positioning-tab.component.css']
})
export class PositioningTabComponent implements OnInit {

  @Input() SelectedModule: ModulePlan;

  constructor() { }

  ngOnInit(): void {
  }

  public SetPositioningDone() {
    if (this.SelectedModule) {
      this.SelectedModule.modul.PositioningRequired = false;
    }
  }

}
