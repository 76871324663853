import {EventEmitter, Injectable} from '@angular/core';
import {ConnectionService} from 'angular-connection-service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {QuestionBoxComponent} from '../components/dialogs/question-box/question-box.component';
import {BluectrlTranslateService} from './bluectrl-translate.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectivityService {

  public InternetConnectionState = new EventEmitter<boolean>();
  public OnlineLoginRequested = new EventEmitter();
  public inOfflineMode = false;
  private Online = true;
  public OfflineModePossible = true;
  private alreadyAsked = false;


  constructor(public connection: ConnectionService,
              private translate: BluectrlTranslateService,
              public dialog: MatDialog) {

    connection.monitor().subscribe(currentState => {
      //this.hasNetworkConnection = currentState.hasNetworkConnection;
      // this.hasInternetAccess = currentState.hasInternetAccess;

      this.Online = currentState.hasInternetAccess;
      if (currentState.hasInternetAccess) {
        this.internetConectionOn();
      } else {
        this.internetConnectionLost();
      }
    });

  }

  public isOnline(): boolean {
    return this.Online;
  }

  private internetConectionOn() {

    this.InternetConnectionState.emit(true);
    //   // MAYBE RECONNECT??
    if (this.inOfflineMode) {
      if (!this.alreadyAsked) {
        // ASK TO SWITCH
        this.alreadyAsked = true;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'loginDialogGray';
        dialogConfig.data = this.translate.GetTranslation('MESSAGEBOX.CONTENT.ONLINEAGAIN');

        const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(data => {
          if (data === true) {
            // this.moduleHandling.SetViewMode(ViewCode.design);
            this.inOfflineMode = false;
            this.OnlineLoginRequested.emit();
          }
        });
      }
    }

  }

  private internetConnectionLost() {
    this.alreadyAsked = false;
    //   this.IsConnected = false;
    //   this.waitForConnect = false;
    //   this.autoReconnectActive = false;
    //
    this.InternetConnectionState.emit(false);
  }
}
