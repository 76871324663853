<section fxFlexAlign="column" [ngClass]="{'darkTheme': router.url !== '/live', 'grayTheme': router.url === '/live'}">
  <div class="user" fxLayout="row">
    <img class="usersvg" src="/themes/basic/assets/general/user.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
    <div class="userinfo" fxLayout="column" *ngIf="currentUser">
      <p class="username">{{currentUser.Fullname}}</p>
      <p class="company">{{currentUser.Customer}}</p>
    </div>
  </div>

  <div *ngIf="currentUser" fxLayout="row" (click)="logout()" [ngClass]="{'logoff': router.url !== '/live', 'logoffDark': router.url === '/live'}">
    <img class="logoutSvg" src="/themes/basic/assets/general/logout.svg">
    <p class="logofftext">{{ 'COMPONENTS.OPTION.LOGOUT' | i18next }}</p>
  </div>

  <div *ngIf="currentUser && !connectivity.inOfflineMode">
    <div *ngIf="!currentUser.OfflineUser" fxLayout="row" (click)="setAsOfflineUser()" [ngClass]="{'setOff': router.url !== '/live', 'setOffDark': router.url === '/live'}">
      <p class="setofftext">{{ 'COMPONENTS.OPTION.SETOFFLINE' | i18next }}</p>
    </div>
    <div *ngIf="currentUser.OfflineUser" fxLayout="row" (click)="removeOfflineUser()" [ngClass]="{'setOff': router.url !== '/live', 'setOffDark': router.url === '/live'}">
      <p class="setofftext">{{ 'COMPONENTS.OPTION.REMOVEOFFLINE' | i18next }}</p>
    </div>
  </div>

  <div *ngIf="addPinActive">

  </div>

  <!--
  <div class="language" fxLayout="row">
    <img class="usersvg" src="/themes/basic/assets/general/language_de.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
    <div class="langInfo" fxLayout="column">
      <p class="langtxt">{{ 'COMPONENTS.OPTION.LANGUAGE' | i18next }}</p>
      <select #langSelect (change)="ChangeLanguage(langSelect.value)" *ngIf="showLanguageSelector" [ngClass]="{'languageSelector': router.url !== '/live', 'languageSelectorDark': router.url === '/live'}">
        <option *ngFor="let lang of languageList" [value]="lang.code" [selected]="lang.code === i18NextService.language">{{ lang.code }}</option>
      </select>
    </div>
  </div>
  !-->

  <mat-nav-list>
    <a mat-list-item  *ngIf="router.url === '/live' && moduleHandling.ClusterInitialized" (click)="GoToClusterLog()" class="activeColour">
      <img class="sideNavIcons" src="/themes/basic/assets/general/icon_log.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
      <p class="sidenavText" [ngClass]="{'sidenavTextWhite': router.url !== '/live', 'sidenavTextBlack': router.url === '/live'}">{{'COMPONENTS.MENU.CLUSTERLOG' | i18next }}</p>
    </a>
  </mat-nav-list>

  <div class="app" fxLayout="row">
    <img class="usersvg" src="/themes/basic/assets/general/info.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
    <div class="appInfo" fxLayout="column">
      <p class="apptxt">BLUECTRL App</p>
      <p class="versions">Version {{appVersion}}</p>
      <p class="versions">Build {{appBuild}}</p>
      <p class="apptxt">THEME Basic</p>
      <p class="versions">Version {{themeBasicVersion}}</p>
      <p class="versions">Build {{themeBasicBuild}}</p>
      <p class="apptxt">Dynamic Module Specification</p>
      <p class="versions">Version {{serverManagement.dmsVersion}}</p>
    </div>
  </div>



</section>
