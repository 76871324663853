<mat-nav-list>
  <a mat-list-item  routerLink="/home" *ngIf="router.url !== '/home'" (click)="onSidenavClose()" class="activeColour">
    <img class="sideNavIcons" src="/themes/basic/assets/general/home.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
    <p class="sidenavText" [ngClass]="{'sidenavTextWhite': router.url !== '/live', 'sidenavTextBlack': router.url === '/live'}">{{'COMPONENTS.MENU.HOME' | i18next }}</p>
  </a>

  <a mat-list-item  routerLink="/inventory" *ngIf="router.url !== '/live' && router.url !== '/design'" (click)="onSidenavClose()" class="activeColour">
    <img class="sideNavIcons" src="/themes/basic/assets/general/inventory.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
    <p class="sidenavText" [ngClass]="{'sidenavTextWhite': router.url !== '/live', 'sidenavTextBlack': router.url === '/live'}">{{'COMPONENTS.HOME.INVENTORY' | i18next }}</p>
  </a>

  <a mat-list-item  routerLink="/design" *ngIf="router.url !== '/live' && router.url !== '/design'" (click)="onSidenavClose()" class="activeColour">
    <img class="sideNavIcons" src="/themes/basic/assets/general/design.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
    <p class="sidenavText" [ngClass]="{'sidenavTextWhite': router.url !== '/live', 'sidenavTextBlack': router.url === '/live'}">{{'COMPONENTS.HOME.DESIGN' | i18next }}</p>
  </a>

  <a mat-list-item  routerLink="/live" *ngIf="router.url !== '/live' && router.url !== '/design'" (click)="onSidenavClose()" class="activeColour">
    <img class="sideNavIcons" src="/themes/basic/assets/general/live.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
    <p class="sidenavText" [ngClass]="{'sidenavTextWhite': router.url !== '/live', 'sidenavTextBlack': router.url === '/live'}">{{'COMPONENTS.HOME.LIVE' | i18next }}</p>
  </a>

  <a mat-list-item  routerLink="/admin" *ngIf="router.url !== '/live' && router.url !== '/design' && usermanagement.currentUser && usermanagement.currentUser.isAdmin() && !connectivity.inOfflineMode" (click)="onSidenavClose()" class="activeColour">
    <img class="sideNavIcons" src="/themes/basic/assets/general/admin.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
    <p class="sidenavText" [ngClass]="{'sidenavTextWhite': router.url !== '/live', 'sidenavTextBlack': router.url === '/live'}">{{'COMPONENTS.HOME.ADMIN' | i18next }}</p>
  </a>

  <a mat-list-item  *ngIf="router.url === '/design'" (click)="NewProject()" class="activeColour">
  <img class="sideNavIcons" src="/themes/basic/assets/general/new.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
  <p class="sidenavText" [ngClass]="{'sidenavTextWhite': router.url !== '/live', 'sidenavTextBlack': router.url === '/live'}">{{'COMPONENTS.MENU.NEW' | i18next }}</p>
  </a>

  <a mat-list-item  *ngIf="router.url === '/live' || router.url === '/design'" (click)="SaveProject()" class="activeColour">
  <img class="sideNavIcons" src="/themes/basic/assets/general/save.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
  <p class="sidenavText" [ngClass]="{'sidenavTextWhite': router.url !== '/live', 'sidenavTextBlack': router.url === '/live'}">{{'COMPONENTS.MENU.SAVE' | i18next }}</p>
  </a>

  <a mat-list-item  *ngIf="router.url === '/live' || router.url === '/design'" (click)="LoadProject()" class="activeColour">
  <img class="sideNavIcons" src="/themes/basic/assets/general/open.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
  <p class="sidenavText" [ngClass]="{'sidenavTextWhite': router.url !== '/live', 'sidenavTextBlack': router.url === '/live'}">{{'COMPONENTS.MENU.OPEN' | i18next }}</p>
  </a>

  <a mat-list-item  *ngIf="router.url === '/live' || router.url === '/design'" (click)="ShareProject()" class="activeColour">
   <img class="sideNavIcons" src="/themes/basic/assets/general/share.svg" [ngClass]="{'invertedWhite': router.url !== '/live', 'invertedBlack': router.url === '/live'}">
   <p class="sidenavText" [ngClass]="{'sidenavTextWhite': router.url !== '/live', 'sidenavTextBlack': router.url === '/live'}">{{'COMPONENTS.MENU.SHARE' | i18next }}</p>
  </a>



</mat-nav-list>
