import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModuleHandlingService} from '../../../../services/module-handling.service';
import {EndPointTypes} from '../../../../classes/enums/end-point-types.enum';
import {ModuleRotations} from '../../../../classes/enums/module-rotations.enum';
import {Conveyor} from '../../../../classes/conveyor';
import {ModuleConnections} from '../../../../classes/enums/module-connections.enum';
import {ViewCode} from '../../../../classes/enums/view-code.enum';
import {ConveyorLenghtType} from '../../../../classes/enums/conveyor-lenght-type.enum';

@Component({
  selector: 'app-design-conveyor270deg',
  templateUrl: '../../../../../themes/basic/templates/elements/conveyors/conveyor270degree/design-conveyor270deg.component.html',
  styleUrls: ['../../../../../themes/basic/templates/elements/conveyors/conveyor270degree/design-conveyor270deg.component.css']
})
export class DesignConveyor270degComponent implements OnInit {

  _widht = '225.25px';
  _height = '45px';
  _heightValue = 45;
  _widhtValue = 225.25;
  _top = '20px';
  _backgroundImage = 'url("/themes/basic/assets/p_foerderband_80.svg")';
  public position = 'absolute';
  public _conveyor: Conveyor;
  public transform = 'scale(1,1)';
  public viewport = '0 0 116.67 41.66';
  public texty = '0';
  public textx = '0';
  public rectHeight = '45';
  public rectWidht = '225.25';
  rotateQuest = 'rotate(0deg)';
  private rotateText = 'rotate(0deg)';
  sinkScale = 'scale(1,1)';
  sinkRight = '-40px';
  sinkTop = 'calc(50% - 40px)';
  magnetRight = 'calc(225.25 - 100px)';
  magnetTop = 'calc(50% - 22px)';

  @Output() changedConveyor: EventEmitter<Conveyor> = new EventEmitter<Conveyor>();
  @Output() changedSize: EventEmitter<Conveyor> = new EventEmitter<Conveyor>();
  @Output() changeConnection: EventEmitter<ModuleConnections> = new EventEmitter<ModuleConnections>();
  @Output() clickedOnConveyor = new EventEmitter();
  @Input() connectedToModule = '';
  @Input() set top(value: number) {
    this._top = '2px';

  }
  @Input() left = '100%';
  @Input() set conveyor(value: Conveyor) {
    this._conveyor = value;

    if (this._conveyor) {
      this._conveyor.OnlineStateChanged.subscribe(this.conveyorOnlineStateChanged.bind(this));
    }

    if (this._conveyor.Lenght === 60) {
      if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
        if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
          this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_design_mode_60.svg")';
        } else {
          this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_design_mode_60.svg")';
        }

      } else {

        if (this._conveyor.Conntected) {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_live_mode_active_60.svg")';
          } else {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_live_mode_active_60.svg")';
          }
        } else {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_live_mode_60.svg")';
          } else {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_live_mode_60.svg")';
          }
        }



      }
    } else if (this._conveyor.Lenght === 110) {
      if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
        if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
          this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_design_mode_110.svg")';
        } else {
          this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_design_mode_110.svg")';
        }

      } else {

        if (this._conveyor.Conntected) {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_live_mode_active_110.svg")';
          } else {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_live_mode_active_110.svg")';
          }
        } else {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_live_mode_110.svg")';
          } else {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_live_mode_110.svg")';
          }
        }



      }
    } else {
      if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
        if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
          this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_design_mode.svg")';
        } else {
          this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_design_mode.svg")';
        }
      } else {

        if (this._conveyor.Conntected) {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_live_mode_active.svg")';
          } else {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_live_mode_active.svg")';
          }
        } else {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_live_mode.svg")';
          } else {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_live_mode.svg")';
          }
        }
      }
    }

    this.UpdateView();
  }
  @Input() modulerotation: ModuleRotations = ModuleRotations.degree_0;
  get conveyor(): Conveyor {
    return this._conveyor;
  }
  @Input() set size(value: number) {

    let lengthFactor = 1.0;

    if (this.conveyor.LenghtType === ConveyorLenghtType.short) {
      lengthFactor = 2.0;
    }


    this._widht = ((4505 / value) / lengthFactor) + 'px';
    this._height = (((900) / value)) + 'px';
    this._heightValue = (900) / value;
    this._widhtValue = (4505 / value) / lengthFactor;

    // SCALE
    const scale = (4505 / value) / 225.25;

    this.sinkScale = 'scale(' + scale + ', ' + scale + ')';
    this.sinkRight = ( - 50) + 'px';
    this.sinkTop = 'calc(50% - ' + (40 * scale) + 'px)';
    this.magnetTop = 'calc(50% - ' + (22 * scale) + 'px)';
    this.magnetRight = 'calc(' + (this._widhtValue) + 'px - ' + (100 * scale) + 'px)';
  }
  @Input() isSelected = true;


  constructor(public moduleHandling: ModuleHandlingService) { }

  ngOnInit() {
  }

  private conveyorOnlineStateChanged() {
    if (this._conveyor.Lenght === 60) {
      if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
        if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
          this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_design_mode_60.svg")';
        } else {
          this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_design_mode_60.svg")';
        }

      } else {

        if (this._conveyor.Conntected) {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_live_mode_active_60.svg")';
          } else {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_live_mode_active_60.svg")';
          }
        } else {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_live_mode_60.svg")';
          } else {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_live_mode_60.svg")';
          }
        }



      }
    } else if (this._conveyor.Lenght === 110) {
      if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
        if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
          this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_design_mode_110.svg")';
        } else {
          this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_design_mode_110.svg")';
        }

      } else {

        if (this._conveyor.Conntected) {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_live_mode_active_110.svg")';
          } else {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_live_mode_active_110.svg")';
          }
        } else {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_live_mode_110.svg")';
          } else {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_live_mode_110.svg")';
          }
        }



      }
    } else {
      if (this.moduleHandling.CurrentViewMode === ViewCode.design) {
        if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
          this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_design_mode.svg")';
        } else {
          this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_design_mode.svg")';
        }
      } else {

        if (this._conveyor.Conntected) {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_live_mode_active.svg")';
          } else {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_live_mode_active.svg")';
          }
        } else {
          if (this._conveyor.LenghtType === ConveyorLenghtType.long || this._conveyor.LenghtType === ConveyorLenghtType.magnet) {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_lang_live_mode.svg")';
          } else {
            this._backgroundImage = 'url("/themes/basic/assets/foerderband_kurz_live_mode.svg")';
          }
        }
      }
    }

    this.UpdateView();
  }

  UpdateView() {
    // addition
    let v2 = this._heightValue / this.conveyor.Lenght;
    v2 = v2 / 0.416666666666667;

    // this.transform = 'scale(' + v2 + ',' + vv + ')';
    this.transform = 'scale(' + 1 + ',' + 1 + ')';
    this.viewport = '0 0 ' + (this._widhtValue) + ' ' + (this._heightValue);
    const textyn = 0.416666666666666666666667 * this._conveyor.Lenght - 7 * v2;
    this.texty = '' + textyn + '';
    this.rectHeight = '' + (this._heightValue - 5) + '';
    this.rectWidht = '' + (this._widhtValue - 5) + '';
    this.textx = '' + ((this._widhtValue - 5) / 2) + '';
    this.rotateQuest = 'rotate(-' + this.modulerotation + 'deg)';
    if (this.modulerotation === ModuleRotations.degree_0 || this.modulerotation === ModuleRotations.degree_90 ) {
      this.transform = 'scale(' + 1 + ',' + 1 + ')';
      this.rotateText = 'rotate(0deg)';
    } else {
      this.transform = 'scale(-' + 1 + ',-' + 1 + ') translate(-' + (this._widhtValue) + ',-' +
        + this._heightValue + ')';
      this.rotateText = 'rotate(180deg)';
    }
  }

  isNotConnected(): boolean {
    return this.conveyor.EndPointType === EndPointTypes.undefined && this.isSelected;
  }

  isSink(): boolean {
    return this.conveyor.EndPointType === EndPointTypes.sink;
  }

  OnSetConnection() {
    if (this.isSelected) {
      this.moduleHandling.ConveyorConnectionPointClicked(this.conveyor);
    }
  }

  isConnectionMissing(): boolean {
    return this.conveyor.EndPointType === EndPointTypes.undefined && !this.isSelected;
  }

  isConnected(): boolean {

    if (this.isSelected) {
      if (this.conveyor.EndPointType !== EndPointTypes.undefined) {
        return true;
      }
    }

    return false;

  }

  public SinkConnected(): boolean {
    return this.conveyor.EndPointType === EndPointTypes.sink;
  }

  public ChangeConveyorAllowedConnected(): boolean {

    if (this.isSelected) {
      if (this.conveyor.EndPointType !== EndPointTypes.module) {
        return true;
      }
    }

    return false;
  }

  public ChangeLength() {
    this.changedSize.emit(this.conveyor);
  }

  public changeMagnetDirection($event) {


    $event.stopPropagation();

    // GET IT
    const currentValue = this.getCurrentMagnetDirection();
    // SET IT
    if (currentValue === false) {
      this.moduleHandling.setConveyorAddOnValueForMagnet(this.conveyor, this.connectedToModule, true);
    } else {
      this.moduleHandling.setConveyorAddOnValueForMagnet(this.conveyor, this.connectedToModule, false);
    }


  }

  public getMagnetRotation(): String {
    return  'rotate(' + (this.getCurrentMagnetDirection() === false ? '-90deg' : '90deg')
      + ')';
  }

  public getCurrentMagnetDirection(): any {
    return this.moduleHandling.GetConveyorAddOnValueForMagnet(this.conveyor, this.connectedToModule);
  }


}
