import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-message-box',
  templateUrl: '../../../../themes/basic/templates/dialogs/confirm-message-box/confirm-message-box.component.html',
  styleUrls: ['../../../../themes/basic/templates/dialogs/confirm-message-box/confirm-message-box.component.css']
})
export class ConfirmMessageBoxComponent implements OnInit {

  constructor(public DialogRef: MatDialogRef<ConfirmMessageBoxComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  public Confirm() {
    this.DialogRef.close(true);
  }

}
